import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
  Typography, Container, Link, Slide, CardMedia,
  CircularProgress, Card, Box, Grid
} from "@material-ui/core";
import { pencil, tick, Error, builder } from "../assets";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "../styles/Service.web";
import Portal from "../../../../components/src/Portal";
import DeleteModal from "../../../utilities/src/components/DeleteModal.web";
import { PaginationType } from "../../../ordermanagement/src/types";
import Pagination from "../../../ss-cms-common-components/src/Pagination/Pagination";

const configJSON = require('../config.js');
// types.ts
export interface Category {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    count: number;
    image_url: string;
  };
}

interface IndividualCheckedState {
  [key: string]: boolean;
}

const Listcategoryform = ({
  categorylist,
  handleCreateCategory,
  deleteCategory,
  handleCheckboxClick,
  editCategory,
  isLoading,
  isError,
  dialogClose,
  msgError,
  deleteError,
  pagination,
  handlePageChange,
  isSuccessfulyDelete,
  categoryListError
}: {
  categorylist: Category[];
  handleCreateCategory: any;
  deleteCategory: any;
  handleCheckboxClick: any;
  editCategory: any;
  isLoading: boolean;
  isError: boolean;
  dialogClose: any;
  msgError: any;
  deleteError: any;
  pagination?: PaginationType;
  handlePageChange: (page: number) => void;
  isSuccessfulyDelete: boolean;
  categoryListError: { message: string };
}) => {


  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualChecked, setIndividualChecked] = useState<IndividualCheckedState>({});
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeletionApproved, setIsDeletionApproved] = useState(false);

  useEffect(() => {
    if (isDeletionApproved) {

      setIsDeletionApproved(false);
    }
  }, [isDeletionApproved]);

  useEffect(() => {
    if (selectAllChecked) {
      const newIndividualChecked = categorylist.reduce((acc, category) => {
        acc[category.id] = true;
        return acc;
      }, {} as IndividualCheckedState);
      setIndividualChecked(newIndividualChecked);
    } else {
      setIndividualChecked({});
    }
  }, [selectAllChecked, categorylist]);

  useEffect(() => {

    const updatedSelectedIds = Object.keys(individualChecked).filter(id => individualChecked[id]);
    setSelectedCategoryIds(updatedSelectedIds);
  }, [individualChecked]);

  const handleSelectAllCheckboxClick = () => {
    setSelectAllChecked(!selectAllChecked);
  };

  const handleIndividualCheckboxClick = (id: string) => {
    setIndividualChecked((prevChecked) => ({
      ...prevChecked,
      [id]: !prevChecked[id],
    }));
  };

  const tableSortLabelStyle = {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0em",
    color: "#676B7E",
    textAlign: "left" as const,
    paddingBottom: '9px',
  };

  const textHeadingStyle = {
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    textAlign: "left" as const
  };



  const textDeleteStyle: React.CSSProperties = {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#FF1744",
    cursor: "pointer",
    paddingBottom: "0px",
  };



  const values = {
    images: ['image1.jpg', 'image2.jpg', 'image3.jpg']
  };

  const classes = useStyles({ hasImages: values.images.length > 0 });

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const confirmDeletion = () => {
    deleteCategory(selectedCategoryIds);
    setIsDeletionApproved(true);
    closeModal();
  };

  return (
    <>
      <Slide key="success-slide" direction="down" in={isSuccessfulyDelete} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={builder}
              className={classes.logo}
            />
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {/* Left content */}
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: 'flex', alignItems: 'center', padding: '12px 19px' }}>
                  <CardMedia
                    component="img"
                    src={tick}
                    className={classes.logo}
                  />
                  <Typography variant="body1" className={classes.centeredText}>
                    Success!
                  </Typography>
                  <Typography variant="body1" className={classes.centeredsecText}>
                    Category deleted successfully.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Slide>
      <Slide key="success-slide" direction="down" in={isError} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={builder}
              className={classes.logo}
            />
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {/* Left content */}
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: 'flex', alignItems: 'center', padding: '12px 19px' }}>
                  <CardMedia
                    component="img"
                    src={Error}
                    className={classes.logo}
                  />
                  <Typography variant="body1" className={classes.centeredText}>
                    Error!
                  </Typography>
                  <Typography variant="body1" className={classes.centeredsecText}>
                    {msgError}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Slide>

      {categoryListError.message != "No categories found" ?
      <Container maxWidth="xl" style={webStyle.mainContainer} >
        {isModalOpened && (
          <Portal>
            <DeleteModal
              message="Are you sure you want to delete this category ?"
              closeModal={closeModal}
              confirmDeletion={confirmDeletion}
            />
          </Portal>
        )}
        {isLoading ? (
          <Card className={classes.noServicesCard}>
            <Box className={classes.loaderContainer}>
              <Typography className={classes.loadingResultsText}>
                Loading results
              </Typography>
              <span>
                <CircularProgress size={24} />
              </span>
            </Box>
          </Card>
        ) : (<>
          <div style={webStyle.sectionMain}>
            <Typography style={textHeadingStyle}>Categories</Typography>
            <div style={{ marginLeft: "20px", marginTop: "5px" }}>
              <Link
                // onClick={() => { deleteCategory(selectedCategoryIds); }}
                onClick={openModal}
                style={{
                  ...textDeleteStyle,
                  display: selectedCategoryIds.length > 0 ? 'block' : 'none',
                  textDecoration: 'underline',
                }}
                data-test-id="deletecategory"
              >
                {isLoading ? "Deleting..." : "Delete Categories"}
              </Link>
            </div>

            <div
              style={{ marginLeft: "auto", padding: "10px", textAlign: "center" }}
            >

              <Typography
                variant="body1"
                onClick={handleCreateCategory}
                data-test-id="handleCreateCategory"
                style={webStyle.NewCategoryBtn}
              >
                Create new category
              </Typography>

            </div>
          </div>
            <div style={webStyle.tableContainer}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow >
                      <TableCell padding="checkbox">
                        <Checkbox
                          inputProps={{ "aria-label": "select all categories" }}
                          checked={selectAllChecked}
                          onClick={handleSelectAllCheckboxClick}
                          classes={{
                            root: classes.customCheckbox,
                            checked: classes.checked,
                          }}
                        />

                      </TableCell>
                      <TableCell style={tableSortLabelStyle} >Image</TableCell>
                      <TableCell style={tableSortLabelStyle}>Category title</TableCell>
                      <TableCell style={tableSortLabelStyle}>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={webStyle.tableBody}>
                    { categorylist != undefined && categorylist.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={individualChecked[row.id] || false}
                            onClick={() => handleIndividualCheckboxClick(row.id)}
                            classes={{
                              root: classes.customCheckbox,
                              checked: classes.checked,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <img
                            src={row.attributes.image_url}
                            style={webStyle.imageStyle}
                            alt="Category"
                          />
                        </TableCell>
                        <TableCell style={{
                          fontFamily: 'Rubik',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0px',
                          textAlign: 'left',
                          color: '#3C3E49',
                          textTransform: 'capitalize'
                        }}>
                          {row.attributes.name}
                        </TableCell>
                        <TableCell>
                          <img
                            src={pencil}
                            style={webStyle.editpencil}
                            alt="Edit"
                            onClick={() => {
                              editCategory(
                                row.attributes.name,
                                row.attributes.id,
                                row.attributes.image_url
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!!pagination?.total_count && <Box marginBottom={4}><Pagination
                count={pagination?.total_count}
                onChange={handlePageChange}
                pageSize={pagination?.per_page}
                itemText={configJSON.paginationText}
                pageNo={pagination?.current_page || 1}
              /></Box>}
            </div> 
        </>)}



      </Container> :
      <Card className={classes.noServices}>
        <Typography className={classes.title1}>No categories</Typography>

        <Typography className={classes.paragraph}>
          You have not added any categories at the moment.
        </Typography>

        <Typography
          variant="body1"
          onClick={handleCreateCategory}
          data-test-id="handleCreateCategory"
          className={classes.addServiceButtonNoServices}
        >
          Add category
        </Typography>
      </Card>
    }
    </>
  );
};

// Customizable Area End
export default Listcategoryform;

// Customizable Area Start

const webStyle = {

  mainContainer: {
    marginTop: '50px',
    backgroundColor: '#FAFAFA'
  },
  mainWrapper: {
    maxWidth: "684px",
    margin: "20 auto"
  },
  colWrapper: {
    padding: "50px 10px 10px 10px",
    textAlign: "center"
  },
  editpencil: {
    width: "18px",
    height: "18px",
    borderRadius: "4px"
  },
  imageStyle: {
    width: "37px",
    height: "36px",
    borderRadius: "4px"
  },
  tableRow: {
    width: "18px",
    height: "18px"
  },
  tableBody: {
    width: "18px",
    height: "18px",
    backgroundColor: '#ffffff',
    border: '2px solid #ECEDF0'
  },
  tableContainer: {
    height: "auto",
    width: "100%",
    margin: '0px 0px 50px 0px',
  },
  sectionMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: '20px auto',
  },
  tableCellTd: {
    width: 100
  },
  Muichecked: {
    backgroundColor: '#6200EA',
  },
  NewCategoryBtn: {
    padding: '10px 32px',
    borderRadius: '3px',
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center' as 'center',
    color: '#3C3E49',
    backgroundColor: '#00D659',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
  },


};
// Customizable Area End
