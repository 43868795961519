// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  useMediaQuery,
  Box,
  Divider,
  InputAdornment,
  TextField,
  ListItem,
  List,
  IconButton
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  Close as CloseIcon
} from "@material-ui/icons";
import theme from "../../utilities/src/theme";
import { Link } from "react-router-dom";
import { Appheader as useStyles } from "./styles/Appheader.web";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";

const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

interface Props {
  logo: ServiceImage | File | null;
  storeName: string;
  confirmSearch: (searchQuery: string) => void;
  searchQuery: string;
}

const Appheader: React.FC<Props> = ({
  logo,
  storeName,
  confirmSearch,
  searchQuery
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearcBarOpen, setIsSearchBarOpen] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  interface DrawerItem {
    text: string;
    link: string;
    mixpanel: string;
  }

  const drawerItems: DrawerItem[] = [
    { text: "Services", link: "/Services", mixpanel: "webcustomer_navigation_services_button" },
    { text: "Gallery", link: "/Gallery", mixpanel: "webcustomer_navigation_gallery_button" }
  ];

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleSearchBar = () => {searchForCatalogue
    setIsSearchBarOpen(!isSearcBarOpen);
  };

  const searchForCatalogue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchRef.current) {
      mixpanel.track("webcustomer_navigation_search")
      setIsSearchBarOpen(false);
      const searchQuery = searchRef.current.value;
      searchRef.current.value = "";
      searchQuery.trim() !== "" && confirmSearch(searchQuery);
    }
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  }, [searchQuery]);

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        {isSmallScreen && isSearcBarOpen ? (
          <>
            <Typography component="div">
              <TextField
                variant="outlined"
                inputRef={searchRef}
                fullWidth
                placeholder="Search"
                onKeyDown={searchForCatalogue}
                inputProps={{ className: classes.searchBar }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      classes={{ positionStart: classes.inputAdornment }}
                    >
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  classes: { adornedStart: classes.searchBarIconAdornment }
                }}
              />
            </Typography>
            <CloseIcon
              className={classes.closeIcon}
              onClick={toggleSearchBar}
            />
          </>
        ) : (
          <>
            <Box className={classes.leftSideContainer}>
              {isSmallScreen && (
                <IconButton className={classes.menuIcon} onClick={toggleDrawer}>
                  <MenuIcon />
                </IconButton>
              )}

              <Link to="/" className={classes.homeLink}>
                <ImageCard
                  imageClassName={classes.logo}
                  image={logo && (logo as ServiceImage).url}
                />
                <Typography className={classes.title}>{storeName}</Typography>
              </Link>

              {!isSmallScreen && (
                <Box className={classes.navigationItems}>
                  {drawerItems.map((item, index) => (
                    <Link key={index} to={item.link} className={classes.link} onClick={()=>mixpanel.track(item.mixpanel)}>
                      <Typography className={classes.linkText}>
                        {item.text}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              )}
            </Box>

            <Box className={classes.RightSideContainer}>
              {isSmallScreen ? (
                <SearchIcon
                  className={classes.searchIcon}
                  onClick={toggleSearchBar}
                />
              ) : (
                <>
                  <Typography component="div">
                    <TextField
                      variant="outlined"
                      inputRef={searchRef}
                      fullWidth
                      placeholder="Search"
                      onKeyDown={searchForCatalogue}
                      inputProps={{ className: classes.searchBar }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            classes={{ positionStart: classes.inputAdornment }}
                          >
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                        classes: {
                          adornedStart: classes.searchBarIconAdornment
                        }
                      }}
                    />
                  </Typography>

                  <Link to="/Services" className={classes.link} onClick={()=>mixpanel.track("webcustomer_navigation_book_now")}>
                    <Typography className={classes.bookNowButton}>
                      Book now
                    </Typography>
                  </Link>
                </>
              )}
            </Box>
          </>
        )}
      </Toolbar>

      {isSmallScreen && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          PaperProps={{ className: classes.drawer }}
        >
          <CloseIcon
            className={classes.drawerCloseButton}
            onClick={toggleDrawer}
          />

          <List>
            {drawerItems.map((item, index) => (
              <Box key={index}>
                <ListItem onClick={toggleDrawer}>
                  <Link to={item.link} className={classes.link} onClick={()=>mixpanel.track(item.mixpanel)}>
                    <Typography className={classes.linkText}>
                      {item.text}
                    </Typography>
                  </Link>
                </ListItem>
                <Divider className={classes.divider} />
              </Box>
            ))}

            <ListItem>
              <Link
                to="/Services"
                onClick={()=>mixpanel.track("webcustomer_navigation_book_now")}
                className={classes.link}
              >
                <Typography onClick={toggleDrawer} className={classes.bookNowButton}>
                  Book now
                </Typography>
              </Link>
            </ListItem>
          </List>
        </Drawer>
      )}
    </AppBar>
  );
};

export default Appheader;
// Customizable Area End