import React from "react";
// Customizable Area Start
import { Box, Button, Card, withStyles, Grid, Typography, Divider, TextField, FormHelperText, TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Dialog, DialogContentText, DialogActions, DialogContent, CardContent, Collapse, CardActions, CircularProgress, DialogTitle } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import AppointmentsController, { AppointmentListData, configJSON } from "./AppointmentsController.web";
import Calendar from "react-calendar";
import AppointmentStiper from "./AppointmentStiper.web";
import Confirmation from "./Confirmation.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import "react-calendar/dist/Calendar.css";
import { ArrowBackIcon, ArrowForwardIcon, ArrowIcon } from "./assets";
import Currency from "../../utilities/src/components/Currency.web";
import StripePayments from "../../stripepayments/src/StripePayments.web";
import StripeSuccessModal from "../../stripepayments/src/StripeSuccessModal.web";
import CcavenueIntegration from "../../ccavenueintegration/src/CcavenueIntegration.web";
// Customizable Area End

// Customizable Area Start
export const webStyles = (theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "100vh",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "auto",
      width: "100%"
    },
  },
  card1: {
    display: "flex",
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  card2: {
    flexBasis: "40%",
  },
  customSelectedDay: {
    backgroundColor: "#3FC1CB",
    color: "white"
  },
  calender: { color: "black" },
  wrapper: {
    maxWidth: "718px",
    margin: "30px 33px 60px",
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 5px",
      padding: "0px"
    },
  },
  timeSlot: {
    border: "1px solid",
    padding: "10px",
    flexGrow: 1,
    fontSize: "12px",
    fontFamily: "Rubik",
    lineHeight: "20px",
    fontWeight: 500,
    borderRadius: "3px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 2px",
    },
  },
  dataAndTimeHeading: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    color: "#0000000",
    padding: "40px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  containerBox: {
    maxWidth: "1440px",
    margin: "40px auto",
  },
  selectedDate: {
    backgroundColor: "#3FC1CB !important",
    color: "#FFF !important",
    borderRadius: "50% !important",
    width: "3em !important",
    height: "3em !important",
  },
  proceedBtn: {
    color: "white",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px"
  },
  btnEnable: {
    backgroundColor: "#3FC1CB",
  },
  btnDisable: {
    backgroundColor: "#D0D2DA",
  },
  inputLabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
    fontFamily: "Rubik",
  },
  appointmentHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "10px",
    fontFamily: "Rubik",
    marginBottom: "25px",
  },
  paymentHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "10px",
    fontFamily: "Rubik",
    marginBottom: "20px",
  },
  inputError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
  },
  formContainer: {
    marginTop: "2rem",
    marginBottom: "5rem"
  },
  headingSection: {
    padding: "0px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 14px",
    },
  },
  cancelText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#3FC1CB",
    marginLeft: "5px",
    lineHeight: "24px",
    cursor: "pointer"
  },
  arrowIcon: {
    color: "#3FC1CB",
    width: "9px",
    height: "9px",
    cursor: "pointer"
  },
  headingText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    color: "#3C3E49",
    margin: "20px 0px",
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  appointmentText: {
    fontFamily: "Rubik,",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#000000",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  serviceText: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px"
  },
  appoinmentsubContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px"
  },
  divider: {
    borderColor: "#BFBFBF",
    marginBottom: "16px"
  },
  bottomSection: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  titleSection: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#000000"
  },
  appoinmentDate: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px"
  },
  comments: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#8C8C8C",
    fontStyle: "italic"
  },
  textLimit: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#000000",
  },
  dialogContent: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400 !important" as "bold",
    width: "65%",
    margin: "auto",
  },
  dialogButton: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400
  },
  dialogCancel: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px"
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapseCard: {
    padding: "8px 0px !important"
  },
  availableSlot: {
    width: "100%",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  calendarArrowIcon: {
    width: "13.49px !important",
    height: "12.59px !important",
    color: "#676B7E !important"
  }
});

const webStyle = {
  field: {
    fontFamily: "Rubik,",
    padding: "12px 16px",
    fontSize: "14px",
    borderRadius: "3px",
    lineHeight: "24px",
    height: "auto"
  },
}
// Customizable Area End

export class Appointments extends AppointmentsController {
  // Customizable Area Start
  renderSelectSlot = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.appointmentsList.map((slot: AppointmentListData, index: number) => {
          const slotID = slot.id === this.state.selectedSlot.id ? "#0097A7" : "#3C3E49";
          const availableSlots = slot.is_available ? "#3C3E49" : "#AFB2C0";
          const slotSelected = slot.id === this.state.selectedSlot.id ? "pointer" : "default";
          return (
            <Grid item xs={3} sm={3} md={3} key={slot.id}>
              <Box
                data-testid={`selectSlot${index + 1}`}
                className={classes.timeSlot}
                style={{
                  border: `1px solid ${slot.is_available ? slotID : "#AFB2C0"}`,
                  backgroundColor: slot.id === this.state.selectedSlot.id ? "#E0F7FA" : "transparent",
                  color: slot.id === this.state.selectedSlot.id ? "#0097A7" : availableSlots,
                  textAlign: "center",
                  cursor: slot.is_available ? slotSelected : "not-allowed",
                }}
                onClick={() => slot.is_available && this.handelAvaibilityTimeSlot(slot)}
              >
                {this.convertTimeFormat(typeof slot.slot_start_time === "string" ? slot.slot_start_time : "")}
              </Box>
            </Grid>
          )
        })}
      </>
    )
  }

  renderService = (classes: Record<string, string>) => {
    return (
      <>
        <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
          {configJSON.appointmentSummary}
        </Typography>

        <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
          {configJSON.service}
        </Typography>

        <Box className={classes.appoinmentsubContainer}>
          <Typography variant="body1" className={classes.titleSection}>{this.state.service.title}, {this.state.service.duration} {configJSON.mins}</Typography>
          <Typography variant="body1" className={classes.titleSection}>{this.state.isPriceLoading ? <CircularProgress size={20} /> : <Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} />}</Typography>
        </Box>
        <Divider className={classes.divider} />
      </>
    )
  }

  renderBookAppointment = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.activeStep === 0 && (
          <Box className={classes.containerBox}>
            <Box className={classes.headingSection}>
              <Box data-testid="cancelBackBtnId" style={{ display: "inline-flex", alignItems: "center" }} onClick={this.btnBackProps}>
                <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} />
                <Typography className={classes.cancelText} >{configJSON.cancelBackToAllServices}</Typography>
              </Box>
              <Typography className={classes.headingText}>{configJSON.bookAppointment}</Typography>
              <Box>
                <AppointmentStiper activeStep={this.state.activeStep} />
              </Box>
            </Box>
            <Grid container className={classes.cardContainer} spacing={2}>
              <Grid item xs={12} sm={8}>
                <Card className={classes.card1} style={{ flexDirection: "column" }}>
                  <Box className={classes.dataAndTimeHeading} >{configJSON.dateAndTime}</Box>
                  <Box style={{ margin: "auto" }}>
                    {this.state.selectedDate && <Calendar
                      data-testid="txtInputAvailableDate"
                      value={this.state.selectedDate}
                      onChange={(value) => this.handleDateChange(value)}
                      tileDisabled={this.dateIsDisabled}
                      next2Label={null}
                      prev2Label={null}
                      nextLabel={<img src={ArrowForwardIcon} alt="icon" className={classes.calendarArrowIcon} />}
                      prevLabel={<img src={ArrowBackIcon} alt="icon" className={classes.calendarArrowIcon} />}
                    />}
                  </Box>
                  <Box className={classes.wrapper}>
                    {
                      this.state.isLoading ? <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} ><CircularProgress /></Grid> :
                        <>
                          {this.state.appointmentsList.length > 0 && (
                            <>
                              <Grid container>
                                <Box className={classes.availableSlot} style={{ textAlign: "center" }}>
                                  <Typography data-testid="dateAndTimeId" style={{ fontFamily: "Rubik,", fontWeight: 600, fontSize: "14px", color: "#3C3E49" }}>{configJSON.availableTimes} ({configJSON.in} {this.state.timeZone})</Typography>
                                </Box>
                              </Grid>
                              <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                <Grid container spacing={2} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                                  {this.renderSelectSlot(classes)}
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {(this.state.isDataLenghtCheck) && (
                            <Box style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}>
                              <Typography style={{ fontFamily: "Rubik", fontWeight: 400, fontSize: "14px" }}>{configJSON.noSlotsAvailable}</Typography>
                            </Box>
                          )}
                        </>
                    }
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                {this.state.screenSize > 599 ? (
                  <Card className={classes.card2}>
                    <Box style={{ padding: "30px" }}>
                      {this.renderService(classes)}
                      <Box className={classes.appoinmentsubContainer}>
                        <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                        <Typography
                          variant="body1"
                          className={classes.bottomSection}>
                          {this.state.isPriceLoading ? <CircularProgress size={20} /> : <Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} />}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>) : (
                  <Card style={{ maxWidth: "1923px" }}>
                    <Box style={{ padding: "30px" }}>
                      <CardActions disableSpacing className={classes.collapseCard}>
                        <Typography variant="h6" className={classes.appointmentText}>
                          {configJSON.appointmentSummary}
                        </Typography>
                        <IconButton
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                          })}
                          data-testid="expandTestId"
                          onClick={this.handleExpandClick}
                          aria-expanded={this.state.expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </CardActions>
                      <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                        <CardContent className={classes.collapseCard}>
                          <Typography
                            variant="h6"
                            className={classes.serviceText}
                            style={{ textAlign: "left" }}
                          >
                            {configJSON.service}
                          </Typography>

                          <Box className={classes.appoinmentsubContainer}>
                            <Typography variant="body1" className={classes.titleSection}>
                              {this.state.service.title}, {this.state.service.duration} {configJSON.mins}
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.titleSection}
                            ><Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} /></Typography>
                          </Box>
                        </CardContent>
                      </Collapse>
                      <Box className={classes.appoinmentsubContainer}>
                        <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                        <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} /></Typography>
                      </Box>
                    </Box>
                  </Card>
                )}
                <Button
                  data-testid="step-0"
                  variant="contained"
                  disabled={this.state.isDisabled}
                  className={`${classes.proceedBtn} ${this.state.isDisabled ? classes.btnDisable : classes.btnEnable}`}
                  onClick={this.handelProceedFromDateTime}
                >
                  {configJSON.proceed}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  }

  renderCardAction = (classes: Record<string, string>) => {
    return (
      <CardActions disableSpacing className={classes.collapseCard}>
        <Typography data-testid="appointmentSummaryId" variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
          {configJSON.appointmentSummary}
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: this.state.expanded,
          })}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
    )
  }

  renderAppointmentBookingForm = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.activeStep === 1 && (
          <Box className={classes.containerBox}>
            {this.renderCancelBackFunction(classes)}
            <Grid container className={classes.cardContainer} spacing={2}>
              <Grid item xs={12} sm={8}>
                <Card
                  style={{
                    padding: "20px"
                  }}
                >
                  <Typography
                    data-testid="personalDetailsId"
                    variant="h4"
                    component="h1"
                    align="left"
                    gutterBottom
                    className={classes.appointmentHeading}
                  >
                    {configJSON.personalDetails}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Typography className={classes.inputLabel}>{configJSON.name}</Typography>
                        <TextField
                          data-testid="txtName"
                          name="name"
                          value={this.state.name}
                          placeholder="Name"
                          onChange={this.handleChange("name")}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: webStyle.field
                          }}
                          error={!!this.state.errors.name}
                        />
                        {this.state.errors.name && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.name}
                          </FormHelperText>
                        )}
                      </Box>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Typography className={classes.inputLabel}>{configJSON.email}</Typography>
                        <TextField
                          data-testid="txtEmail"
                          name="email"
                          type="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange("email")}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: webStyle.field
                          }}
                          error={!!this.state.errors.email}
                        />
                        {this.state.errors.email && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.email}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Typography className={classes.inputLabel}>
                          {configJSON.mobile}
                        </Typography>
                        <TextField
                          data-testid="txtPhoneNumber"
                          name="phoneNumber"
                          placeholder="Mobile"
                          value={this.state.phoneNumber}
                          onChange={this.handleChange("phoneNumber")}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: webStyle.field
                          }}
                          error={!!this.state.errors.phoneNumber}
                        />
                        {this.state.errors.phoneNumber && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.phoneNumber}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.comments}
                          </Typography>
                          <Typography className={classes.textLimit}>{this.state.characterCount}{configJSON.totalWordLimit}</Typography>
                        </Box>
                        <TextareaAutosize
                          data-testid="txtMessage"
                          name="comments"
                          placeholder="Comments"
                          value={this.state.comments}
                          onChange={this.handleChange("comments")}
                          minRows={6}
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            ...webStyle.field,
                            resize: "none",
                          }}
                        />
                        {this.state.errors.comments && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.comments}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                {this.state.screenSize > 599 ? (
                  <Card style={{ padding: "40px 30px" }} className={classes.card2} >
                    {this.renderService(classes)}
                    <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                      {configJSON.dateAndTime}
                    </Typography>

                    <Box sx={{ marginBottom: "16px" }}>
                      <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.selectedDate)}</Typography>
                      <Typography variant="body1" className={classes.titleSection} data-testid="timezone-0">
                        {this.convertTimeFormat(`${this.state.selectedSlot.slot_start_time} `) + " " + `(${this.state.timeZone})`}
                      </Typography>

                    </Box>
                    <Divider className={classes.divider} />
                    <Box className={classes.appoinmentsubContainer}>
                      <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                      <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} /></Typography>
                    </Box>
                  </Card>
                ) : (
                  <Card style={{ maxWidth: "1923px" }}>
                    <Box style={{ padding: "30px" }}>
                      {this.renderCardAction(classes)}
                      <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                        <CardContent className={classes.collapseCard}>
                          <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                            {configJSON.service}
                          </Typography>

                          <Box className={classes.appoinmentsubContainer}>
                            <Typography variant="body1" className={classes.titleSection}>{this.state.service.title}, {this.state.service.duration} {configJSON.mins}</Typography>
                            <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} /></Typography>
                          </Box>
                          <Divider className={classes.divider} />
                          <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                            {configJSON.dateAndTime}
                          </Typography>

                          <Box sx={{ marginBottom: "16px" }}>
                            <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.selectedDate)}</Typography>
                            <Typography variant="body1" className={classes.titleSection} >
                              {this.convertTimeFormat(`${this.state.selectedSlot.slot_start_time} `) + " " + `(${this.state.timeZone})`}
                            </Typography>

                          </Box>
                        </CardContent>
                      </Collapse>
                      <Box className={classes.appoinmentsubContainer}>
                        <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                        <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} /></Typography>
                      </Box>
                    </Box>
                  </Card>
                )}
                <Button data-testid="step-1" variant="contained" className={`${classes.proceedBtn} ${classes.btnEnable}`} onClick={this.handleProceedToPersonalDetails} >{configJSON.proceed}</Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  }

  renderCancelBackFunction = (classes: Record<string, string>) => {
    return (
      <Box className={classes.headingSection}>
        <Box data-testid="backbuttonId" style={{ display: "inline-flex", alignItems: "center" }} onClick={this.handleBack}>
          <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} />
          <Typography className={classes.cancelText}>{configJSON.cancelBackToAllServices}</Typography>
        </Box>
        <Typography data-testid="bookAppointment1Id" className={classes.headingText}>{configJSON.bookAppointment}</Typography>
        <Box>
          <AppointmentStiper activeStep={this.state.activeStep} />
        </Box>
      </Box>
    )
  }

  renderPresonalDetails = (classes: Record<string, string>) => {
    return (
      <>
        <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
          {configJSON.service}
        </Typography>

        <Box className={classes.appoinmentsubContainer}>
          <Typography variant="body1" className={classes.titleSection}>{this.state.personalDetailsData.service.title}, {this.state.personalDetailsData.service.duration} {configJSON.mins}</Typography>
          <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.personalDetailsData.service.discountedPrice?.toFixed(2)}`} /></Typography>
        </Box>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
          {configJSON.dateAndTime}
        </Typography>

        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.personalDetailsData.selectedDate)}</Typography>
          <Typography variant="body1" className={classes.titleSection}>
            {this.convertTimeFormat(`${this.state.personalDetailsData.selectedSlot.slot_start_time} `) + " " + `(${this.state.personalDetailsData.timeZone})`}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
          {configJSON.personalDetails}
        </Typography>
      </>
    )
  }

  renderAppointmentSummary = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.activeStep === 2 && (
          <Box>
            <Box className={classes.containerBox}>
              {this.renderCancelBackFunction(classes)}
              <Grid container className={classes.cardContainer} spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Card style={{
                    padding: "20px",
                    marginBottom: "20px"
                  }}>
                    <Typography
                      variant="h4"
                      component="h1"
                      align="left"
                      gutterBottom
                      className={classes.paymentHeading}
                    >
                      {configJSON.paymentLocation}
                    </Typography>

                    <FormControl component="fieldset" >
                      <RadioGroup
                        data-testid="paymentOptionId"
                        aria-label="paymentOption"
                        name="paymentOption"
                        value={this.state.paymentOption}
                        onChange={this.handlePaymentOptionChange}
                      >
                        <FormControlLabel
                          data-testid="payNowFormControlId"
                          value="pay_online"
                          control={
                            <Radio
                              data-testid="payNowId"
                              style={{
                                color: ["pay_online_or_in_person", "pay_online"].includes(this.state.paymentPreference) ? "#00D659" : "#BFBFBF"
                              }}
                              checked={this.state.paymentOption === "pay_online"}
                              disabled={["pay_in_person"].includes(this.state.paymentPreference)}
                              onClick={this.handlePayonlineRadioBtn}
                            />
                          }
                          label="Pay now"
                        />
                        <FormControlLabel
                          data-testid="payAtLocationFormControlId"
                          value="pay_in_person"
                          control={
                            <Radio
                              data-testid="payAtLocationId"
                              style={{
                                color: ["pay_online_or_in_person", "pay_in_person"].includes(this.state.paymentPreference) ? "#00D659" : "#BFBFBF"
                              }}
                              checked={this.state.paymentOption === "pay_in_person"}
                              disabled={["pay_online"].includes(this.state.paymentPreference)}
                              onClick={this.handlePayAtLocationRadioBtn}
                            />
                          }
                          label="Pay later at location"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Card>
                  <Card

                    style={{
                      padding: "20px"
                    }}
                  >
                    <Typography
                      data-testid="addressTestId"
                      variant="h4"
                      component="h1"
                      align="left"
                      gutterBottom
                      className={classes.appointmentHeading}
                    >
                      {configJSON.address}
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>{configJSON.country}</Typography>
                          <TextField
                            data-testid="txtCountry"
                            name="country"
                            placeholder="Country"
                            value={this.state.country}
                            onChange={this.handlePaymentChange("country")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.country}
                          />
                          {this.state.addressError.country && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.country}
                            </FormHelperText>
                          )}
                        </Box>

                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>{configJSON.flatHouseApartment}</Typography>
                          <TextField
                            data-testid="txtHouseNo"
                            name="houseNo"
                            placeholder="Flat / House / Apartment No"
                            value={this.state.houseNo}
                            onChange={this.handlePaymentChange("houseNo")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.houseNo}
                          />
                          {this.state.addressError.houseNo && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.houseNo}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.addressLine1}
                          </Typography>
                          <TextField
                            data-testid="txtAddr1"
                            name="addr1"
                            placeholder="Address line 1"
                            value={this.state.addr1}
                            onChange={this.handlePaymentChange("addr1")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.addr1}
                          />
                          {this.state.addressError.addr1 && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.addr1}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.addressLine2}
                          </Typography>
                          <TextField
                            data-testid="txtAddr2"
                            name="addr2"
                            placeholder="Address line 2"
                            value={this.state.addr2}
                            onChange={this.handlePaymentChange("addr2")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.addr2}
                          />
                          {this.state.addressError.addr2 && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.addr2}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.city}
                          </Typography>
                          <TextField
                            data-testid="txtCity"
                            name="city"
                            placeholder="City"
                            value={this.state.city}
                            onChange={this.handlePaymentChange("city")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.city}
                          />
                          {this.state.addressError.city && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.city}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.state}
                          </Typography>
                          <TextField
                            data-testid="txtStateName"
                            name="stateName"
                            placeholder="State"
                            value={this.state.stateName}
                            onChange={this.handlePaymentChange("stateName")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.stateName}
                          />
                          {this.state.addressError.stateName && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.stateName}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box mb={2}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.zipCode}
                          </Typography>
                          <TextField
                            data-testid="txtZip"
                            name="zip"
                            placeholder="Zip code"
                            value={this.state.zip_code}
                            onChange={this.handlePaymentChange("zip_code")}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: webStyle.field
                            }}
                            error={!!this.state.addressError.zip_code}
                          />
                          {this.state.addressError.zip_code && (
                            <FormHelperText className={classes.inputError}>
                              {this.state.addressError.zip_code}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {this.state.screenSize > 599 ? (
                    <Card style={{ padding: "40px 30px" }} className={classes.card2}>
                      <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
                        {configJSON.appointmentSummary}
                      </Typography>
                      {this.renderPresonalDetails(classes)}

                      <Box sx={{ marginBottom: "16px" }}>
                        <Typography variant="body1" className={classes.titleSection}>{this.state.name}</Typography>
                        <Typography variant="body1" className={classes.titleSection}>{this.state.email} | {this.state.phoneNumber}</Typography>
                        <Typography variant="body1" className={classes.comments} style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{this.state.comments}</Typography>
                      </Box>
                      <Divider className={classes.divider} />
                      <Box className={classes.appoinmentsubContainer}>
                        <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                        <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.personalDetailsData.service.discountedPrice?.toFixed(2)}`} /></Typography>
                      </Box>
                    </Card>
                  ) : (
                    <Card style={{ maxWidth: "1923px" }}>
                      <Box style={{ padding: "30px" }}>
                        {this.renderCardAction(classes)}
                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                          <CardContent className={classes.collapseCard}>
                            {this.renderPresonalDetails(classes)}

                            <Box sx={{ marginBottom: "16px" }}>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.name}</Typography>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.email} | {this.state.phoneNumber}</Typography>
                              <Typography variant="body1" className={classes.comments}>{this.state.comments}</Typography>
                            </Box>
                          </CardContent>
                        </Collapse>
                        <Box className={classes.appoinmentsubContainer}>
                          <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                          <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service.discountedPrice?.toFixed(2)}`} /></Typography>
                        </Box>
                      </Box>
                    </Card>
                  )}
                  <Button data-testid="step-2" variant="contained" className={`${classes.proceedBtn} ${classes.btnEnable}`} onClick={this.handleOpenDialog}>{configJSON.proceed}</Button>
                </Grid>
              </Grid>
              <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog}>
                <DialogContent style={{ padding: "40px", textAlign: "center" }}>
                  <DialogContentText data-testid="areYouSureId" className={classes.dialogContent}>
                    {configJSON.areYouSure}
                  </DialogContentText>
                </DialogContent>
                <Divider style={{ margin: "16px 0" }} />
                <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
                  <Button data-testid="paymentModalNoId" onClick={this.handleCloseDialog} className={classes.dialogCancel} style={{ textTransform: "none" }}>
                    {configJSON.no}
                  </Button>
                  <Divider orientation="vertical" flexItem style={{ margin: "0 8px" }} />
                  <Button data-testid="paymentModalYesId" onClick={this.handleProceedToPayment} className={classes.dialogButton} style={{ textTransform: "none" }}>
                    {configJSON.yes}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                data-testid="alertDialogBoxId"
                open={!!this.state.slotBookingErrorMessage}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
              >
                <DialogTitle data-testid="alertId" id="dialog-title">{configJSON.alert}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="dialog-description">
                    {this.state.slotBookingErrorMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button data-testid="alertModalClose" onClick={this.closeAppointmentDialog} color="primary">
                    {configJSON.close}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        )}
      </>
    )
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.bookingData.attributes.appointment_date === "" ? (
          <>
            {this.renderBookAppointment(classes)}
            {this.renderAppointmentBookingForm(classes)}
            {this.renderAppointmentSummary(classes)}
          </>
        ) : (
          <>
            <Confirmation service={this.state.service} paymentOption={this.state.paymentOption} bookingData={this.state.bookingData} />
          </>
        )}
        <StripePayments
          data-testid="stripeCompId"
          stripeModalOpen={this.state.isOpenStripeModal}
          handleSubmit={this.handleCardData}
          handleClose={this.onCloseModal}
        />
        <StripeSuccessModal
          data-testid="stripeAuthId"
          stripeSuccessData={this.state.stripeSuccessData}
          successModalOpen={this.state.stripeState}
        />
        <CcavenueIntegration
          data-testid="ccAvenueAuthId"
          onCloseCancel={this.closeThePopupOnCancel}
          close={this.closeThePopup}
          ccavenueModalShowRespons={this.state.ccavenueModalShowRespons}
          ccavenueModalOpen={this.state.ccavenueModalOpen}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(webStyles)(Appointments);
// Customizable Area End