// Customizable Area Start
import React from "react";
import CategoriessubcategoriesController, {
  Props as ControllerProps
} from "./CategoriessubcategoriesController.web";
import Addcategoryform from "./components/Addcategoryform.web";
import Listcategoryform from "./components/Listcategoryform.web";
import Editcategoryform from "./components/Editcategoryform.web";
import NoCategory from "./components/NoCategory.web";

interface State {
  showChild: string;
  categories_Data: any[]; 
  category_id: string;
  category_name: string;
  category_img: string;
}

export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: ControllerProps) {
    super(props);
  }

  renderCategoryContent = (
    showChild: any,
    categories_Data: any[], 
    category_id: any,
    category_name: any,
    category_img: any
  ) => {
    if (showChild === "addcategory") {
      return <Addcategoryform back={this.back} addimages={this.addimages}  isLoading={this.state.isLoading}   />;
    } else if (showChild === "imagelist") {
      return (
        <Listcategoryform
          categorylist={categories_Data}
          handleCreateCategory={this.handleCreateCategory}
          deleteCategory={this.deleteCategory}
          handleCheckboxClick={this.handleCheckboxClick}
          editCategory={this.editCategory}
          isLoading={this.state.isLoading}
          isError={this.state.isError}
          dialogClose= {this.dialogClose}
          msgError={this.state.msgError}
          deleteError={this.state.deleteError}
          isSuccessfulyDelete={this.state.isSuccessfulyDelete}
          handlePageChange={this.handlePageChange}
          pagination={this.state.pagination}
          categoryListError={this.state.categoriesErrorRes}
        />
      );
    } else if (showChild === "editcategory") {
      return (
        <Editcategoryform
          backeditlist={this.backeditlist}
          editimages={this.editimages}
          id={category_id}
          name={category_name}
          image={category_img}
          removeImage={this.imageRemove} 
          isLoading={this.state.isLoading}
          isLoadingTrue={this.isLoadingTrue}
        />
      );
    } else {
      return (
        <>
        <NoCategory
        handleAddCategory={this.handleAddCategory}
        />
        </>
      );
    }
  };

  render() {
    const {
      showChild,
      categories_Data,
      category_id,
      category_name,
      category_img
    } = this.state;

    return (
      <>
        {this.renderCategoryContent(
          showChild,
          categories_Data,
          category_id,
          category_name,
          category_img
        )}
      </>
    );
  }
}


// Customizable Area End