import React from "react";
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export interface PaymentInfo {
  orderId: number;
  orderNumber: string;
  encryptRequest: string;
  accessCode: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  ccavenueModalOpen: boolean;
  ccavenueModalShowRespons: {
    enc_resp: string;
    access_code: string;
  }
  onCloseCancel: () => void;
  close: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  setOpenModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CcavenueIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  iframeRef: React.RefObject<HTMLIFrameElement>;
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.iframeRef = React.createRef();
    // Customizable Area End    
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      setOpenModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener("message", this.onReceiveMessage);
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("message", this.onReceiveMessage);

  }

  onReceiveMessage = (event: MessageEvent<string>) => {
    if (event.data == "Success") {
      setTimeout(() => {
        this.props.close();
      }, 2000)
    }
    else if (event.data == "Aborted" || event.data == "Failure") {
      setTimeout(() => {
        this.props.onCloseCancel();
      }, 1000)
    }
  };

  handleOpenModal = () => {
    this.setState({ setOpenModal: true });
  };

  handleCloseModal = () => {
    this.setState({ setOpenModal: false });
  };
  // Customizable Area End
}
