import React from "react";

// Customizable Area Start
import {
  Container,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  withStyles,
  Grid,
  Typography,
  Card,
  Box,
} from "@material-ui/core";
import { deleteIcon, closeIcon, gridBtnShow, listBtnHide } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PhotoLibraryController from "./PhotoLibraryController";
import Loader from "../../../components/src/Loader.web";
import { Link } from "react-router-dom";
import PhotoUpload from "./PhotoUpload.web";
import GalleryView from "./GalleryView.web";
import Banner from "./Banner.web";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {},
    subtitle1: {
      margin: "20px 0px"
    }
  }
});


const webStyles = {
  mainContainer: {
    width: "100%",
    backgroundColor: "#F8F9FA",
    margin: "40px 0px"
  },
  headerButtonViewStyle: {
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#fff",
    height: '70%',
  },
  uploadButton: {
    backgroundColor: "#00D659",
    margin: "15px 0px",
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    width: "126px",
    height: "40px",
  },
  gridFilterBtn: {
    backgroundColor: "#fff",
    border: "1px solid #D0D2DA",
    color: "#6200EA",
    margin: "15px 0px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 600
  },
  listFilterBtn: {
    backgroundColor: "#fff",
    border: "1px solid #D0D2DA",
    color: "#83889E",
    margin: "15px 0px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 600,
  },
  noDataContainer: {
    background: '#FFFFFF',
    BoxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    maxHeight: '416px',
    height: '416px',
    display: 'flex',
  },
  message: {
    fontFamily: "Rubik sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  dialogContainer: {
    width: '684px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '24px'
  },
  dialogTitleText: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    color: "#3C3E49",
  },
  closeBtn: {
    margin: "0px",
    padding: "0px",
  },
  deleteButton: {
    padding: "20px 48px 20px 48px",
    borderRadius: "0px",
    background: '#FF1744',
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FFFFFF"
  },
  cancelButton: {
    padding: "20px 48px 20px 48px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3C3E49",
    textDecoration: "underline"
  },
  subContainerOne: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: "auto"
  },
  subContainerTwo: {
    display: 'flex',
    justifyContent: 'end'
  },
  galleryText: {
    margin: '15px 0px',
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    color: "#3C3E49"
  },
  cardContainer: {
    boxShadow: "0px 1.5px 1.5px 0px ##364F6B",
    borderRadius: "0px"
  },
  iconButton: {
    borderRadius: "5px",
    border: "2px solid #364F6B",
    width: "56px",
    height: "48px"
  },
  dialogButton: {
    borderTop: " 1px solid #E8E8E8",
    padding: "0px",
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end"
  },
  deleteIcon: {
    width: "21px",
    height: "21px"
  },
  cardTxtOne: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    color: "#676B7E"
  },
  cardTxtTwo: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    color: "#3C3E49"
  },
  cardImg: {
    width: "100%",
    height: "206px"
  },
  content: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    color: "#3C3E49",
    marginBottom: "24px"
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
  headerIcon: {
    width: "16px",
    height: "16px"
  },
};


export class GalleryGrid extends PhotoLibraryController {

  render() {

    const data = this.state.imageData
    const { loading, selectedRow, showSuccessMessage } = this.state

    if (data.length === 0 && !loading) {
      return <PhotoUpload navigation={undefined} identifier={""} />
    }

    return (
      <>
        {!this.state.showEditPage ? (
          <>
            <Banner navigation={undefined} identifier={""} success={showSuccessMessage} />
            <ThemeProvider theme={theme}>
              <Box style={webStyles.mainContainer}>
                <Container maxWidth={"xl"}>
                  <Box style={webStyles.subContainerOne}>
                    <Typography variant="h4" style={webStyles.galleryText}>
                      Gallery
                    </Typography>
                    <Link to="/Gallery/ImageUpload">
                      <Button
                        variant="contained"
                        style={{ ...webStyles.uploadButton, textTransform: "none" }}
                      >
                        Add image
                      </Button>
                    </Link>
                  </Box>
                  <Box style={webStyles.subContainerTwo}>
                    <Button style={{ ...webStyles.gridFilterBtn, textTransform: "none" }} variant="outlined" startIcon={<img style={webStyles.headerIcon} src={gridBtnShow} alt="gridView" />}>Grid view</Button>
                    <Button style={{ ...webStyles.listFilterBtn, textTransform: "none" }} variant="outlined" href="/admin/Gallery/List" startIcon={<img style={webStyles.headerIcon} src={listBtnHide} alt="listview" />}>List view</Button>
                  </Box>
                  {data.length > 0 ? (
                    <>
                      <Grid container spacing={3}>
                        {data.map((item, index) => (
                          <Grid item xl={2} lg={3} md={3} sm={6} xs={12} key={index}>
                            <Card style={webStyles.cardContainer}>
                              <CardMedia
                                data-test-id={`editImage-${index}`}
                                component="img"
                                image={item.file_url}
                                alt="demo img"
                                style={webStyles.cardImg}
                                onClick={() => this.showGalleryImage(item)}
                              />
                              <CardActions disableSpacing style={{ justifyContent: "space-between", margin: "12px" }}>
                                <CardContent style={{ padding: "0px" }}>
                                  <Typography style={webStyles.cardTxtOne}>
                                    Uploaded On
                                  </Typography>
                                  <Typography style={webStyles.cardTxtTwo}>
                                    {this.formatDate(item.created_at || '')}
                                  </Typography>
                                </CardContent>
                                <IconButton
                                  data-test-id={`deleteIcon-${index}`}
                                  aria-label="Delete"
                                  style={webStyles.iconButton}
                                  onClick={() => this.handleDeleteClick([item.id])}
                                >
                                  <img style={webStyles.deleteIcon} src={deleteIcon} alt="Delete" />
                                </IconButton>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                      <React.Fragment>
                        {selectedRow.length > 0 && (
                          <Box
                            style={{
                              ...webStyles.dialogContainer, position: 'fixed',
                              flexDirection: 'column'
                            }}
                          >
                            <Box style={{ margin: "40px" }}>
                              <Box
                                style={webStyles.dialogTitle}
                              >
                                <Typography
                                  variant="h6"
                                  style={webStyles.dialogTitleText}
                                >
                                  Are you sure?
                                </Typography>
                                <IconButton
                                  data-test-id="close-dialog"
                                  edge="end"
                                  color="inherit"
                                  onClick={this.handleCloseDialog}
                                  aria-label="close"
                                  style={webStyles.closeBtn}
                                >
                                  <img style={webStyles.closeIcon} src={closeIcon} alt="close" />
                                </IconButton>
                              </Box>
                              <Typography
                                style={webStyles.content}
                              >
                                Are you sure you want to delete this gallery image ?
                              </Typography>
                            </Box>
                            <Box style={webStyles.dialogButton} >
                              <Button
                                data-test-id="cancel-btn"
                                style={{ ...webStyles.cancelButton, textTransform: 'none' }}
                                onClick={this.handleCloseDialog}
                                color="primary"
                              >
                                Cancel
                              </Button>
                              <Button
                                data-test-id="delete-btn"
                                style={{ ...webStyles.deleteButton, textTransform: 'none' }}
                                onClick={this.handleConfirmDelete}
                              >
                                Yes, delete
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </React.Fragment>
                    </>
                  ) : (
                    <Box style={{ ...webStyles.noDataContainer, flexDirection: "column" }}>
                      <Box style={{ margin: 'auto', textAlign: 'center' }}>
                        {loading && <Loader loading={loading} />}
                      </Box>
                    </Box>
                  )}
                </Container>
              </Box>
            </ThemeProvider>
            {!!this.state.pagination?.total_count && <Box marginBottom={4}><Pagination
              count={this.state.pagination?.total_count}
              onChange={this.handlePageChange}
              pageSize={this.state.pagination?.per_page}
              itemText={configJSON.paginationText}
              pageNo={this.state.pagination?.current_page || 1}
            /></Box>}
          </>) : (
          <GalleryView
            identifier={""}
            navigation={undefined}
            selectedImage={this.state.selectedImage}
            selectedImageId={this.state.selectedImageId}
            onBackButtonClick={() => this.showGalleryImage(null)}
          />
        )}
      </>
    );

  }
}


export default withStyles(webStyles)(GalleryGrid);
// Customizable Area End
