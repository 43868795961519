// Customizable Area Start
import React from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Close as CloseIcon } from "@material-ui/icons";
import { FilterItems as useStyles } from "./styles/FilterItems.web";
import FilterOptions from "./FilterOptions.web";
import theme from "../../utilities/src/theme";
import { CatObj } from "./CataloguesController.web";

const configJSON = require("./config");

interface Props {
  options: CatObj[];
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  tempSelectedCategories: number[];
  selectCategory: (identifier: number, updateCategories: boolean) => void;
  resetCategoriesSelected: () => void;
  applyCategoriesSelected: () => void;
  discardCategoriesSelected: () => void;
}

const FilterItems: React.FC<Props> = ({
  options,
  isDrawerOpen,
  toggleDrawer,
  tempSelectedCategories,
  selectCategory,
  resetCategoriesSelected,
  applyCategoriesSelected,
  discardCategoriesSelected,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const applyFilters = () => {
    applyCategoriesSelected();
    toggleDrawer();
  };

  const discardChanges = () => {
    discardCategoriesSelected();
    toggleDrawer();
  };

  return (
    <>
      {isSmallScreen && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={discardChanges}
          PaperProps={{ className: classes.drawer }}
        >
          <AppBar position="static" className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.header}>{configJSON.textFilter}</Typography>
              <CloseIcon
                className={classes.drawerCloseButton}
                onClick={discardChanges}
              />
            </Toolbar>
          </AppBar>

          <Box className={classes.drawerContent}>
            <Box>
              <FilterOptions
                options={options}
                tempSelectedCategories={tempSelectedCategories}
                selectCategory={selectCategory}
              />
            </Box>

            <Box className={classes.filterActions}>
              <Button
                variant="contained"
                className={classes.resetButton}
                onClick={resetCategoriesSelected}
              >
                {configJSON.textReset}
              </Button>
              <Button
                variant="contained"
                className={classes.applyNowButton}
                onClick={applyFilters}
              >
                {configJSON.textApplyNow}
              </Button>
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default FilterItems;
// Customizable Area End
