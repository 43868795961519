import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  CardMedia,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import PhotoLibraryController, {
  Props
} from "./PhotoLibraryController";
import { createTheme } from "@material-ui/core/styles";

import { builderStudioStore } from './assets'
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  }
});

const webStyles = {
  discardChangesbutton: {
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    padding: theme.spacing(1.5, 3),
    color: "#FFFFFF",
  },
  saveChangesbutton: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: theme.spacing(1.5, 5),
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  containerEditWrapper: {
    padding: "40px"
  },
  link: {
    textDecoration: "none",
  },
  backButton: {
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#676B7E",
  },
  title: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    marginBottom: theme.spacing(5),
    color: "#3C3E49"
  },
  leftSideWrapper: {
    display: "flex",
    flex: "1 1 70%",
    gap: theme.spacing(3),
  },
  card: {
    width: "602px",
    height: "448px",
    padding: theme.spacing(5),
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF",
    margin: "auto"
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    color: "#3C3E49",
    marginBottom: "15px"
  },
  cardSubTitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    color: "#83889E",
    marginBottom: "10px"
  },
  inputsWrapper: {
    display: "flex",
    gap: theme.spacing(3),
  },
  inputlabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  descriptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionCounter: {
    fontSize: "12px",
    lineHeight: "20px",
  },
  imagesWrapper: {
    display: "flex",
    gap: theme.spacing(3),
  },
  previewImage: {
    width: "155px",
    height: "158px",
  },
  editButton: {
    fontFamily: "Rubik",
    fontWeight: 500,
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
    width: "100%",
    justifyContent: "left", 
    padding: "0px"
  },
  imageUpload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "157px",
    width: "157px",
    borderRadius: "10px",
    border: "2px dashed #C0C3CE",
    padding: theme.spacing(2.5),
    cursor: "pointer",
  },
  addIcon: {
    color: "#676B7E",
  },
  addImageText: {
    fontSize: "18px",
    lineHeight: "32px",
    color: "#676B7E",
  },
  fileSize: {
    fontSize: "10px",
    lineHeight: "24px",
    color: "#AFB2C0",
  },
  fieldWrapper: {
    display: "flex",
    gap: theme.spacing(3.5),
  },
  field: {
    flex: "0 1 40%",
  },
  switch: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  formActionsWrapper: {
    width: "100%",
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#3C3E49",
    padding: "20px 24px",
  },
  logo: {
    width: "auto",
  },
  successMsg: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "rubik",
    lineHeight: "20px",
    padding: "4px 0px",
    color: "#FFFFFF",
    margin: "0px 12px"
  },
  successContent: {
    display: "flex",
    margin: "auto",
  },
  imageValidationError: {
    color: "#f44336",
    margin: "0px",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    padding: "5px 0px",
  },
}

// Customizable Area End

export default class GalleryView extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {

    // Customizable Area Start
    const image = this.state.selectedImage?.uri ? this.state.selectedImage?.uri : this.props.selectedImage?.uri
    const { isImageUploading } = this.state;
    return (
      <>
        <Slide
          direction="down"
          in={this.state.selectedImage.uri !== ""}
          mountOnEnter
          unmountOnExit
          style={{left: 0, right: 0}}
        >
          <Box
            style={{
              ...webStyles.formActionsWrapper,
              zIndex: 1,
              position: "fixed",
              top: 0
            }}
          >
            <Box style={webStyles.formActions}>
              <CardMedia
                component="img"
                src={builderStudioStore}
                style={{ ...webStyles.logo, objectFit: "none" }}
              />

              <Box>
                <Button
                  variant="text"
                  style={{
                    ...webStyles.discardChangesbutton,
                    textTransform: "none",
                  }}
                  onClick={this.discardImage}
                >
                  Discard changes
                </Button>
                {this.state.selectedImage.uri && (
                  <Button
                    variant="contained"
                    data-test-id="upload-button"
                    style={{
                      ...webStyles.saveChangesbutton,
                      textTransform: "none",
                    }}
                    onClick={() => this.updateImage(this.props.selectedImageId || '')}
                    disabled={this.state.isSaving}
                  >
                    {this.state.isSaving && <CircularProgress size={15} style={{ marginRight: 10 }} />}
                    Save changes
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Slide>
        <Box style={webStyles.containerEditWrapper}>
          <Button
            data-test-id="backBtn"
            variant="text"
            startIcon={<ArrowBackIcon />}
            style={{ ...webStyles.backButton, textTransform: "none" }}
            onClick={this.props.onBackButtonClick}
          >
            <Typography>Back</Typography>
          </Button>

          <Typography style={webStyles.title}>Gallery image</Typography>

          <Box style={webStyles.card}>
            <Typography style={webStyles.cardTitle}>
              Image details
            </Typography>
            <Typography style={webStyles.cardSubTitle}>
              This image will be part of the Gallery
            </Typography>
            <Box style={webStyles.imagesWrapper}>
              {this.props.selectedImage && (
                <Box>
                  <CardMedia
                    component="img"
                    src={image}
                    style={webStyles.previewImage}
                  />
                  <input
                    id="image-upload-input"
                    style={webStyles.hidden}
                    type="file"
                    accept="image/*"
                    onChange={(event) => this.checkImage(event)}
                    multiple
                  />
                  <Box>
                    <Typography style={webStyles.imageValidationError}>
                      {this.state.errorMessage}
                    </Typography>
                  </Box>
                  <Button
                    data-test-id="edit-button"
                    variant="text"
                    style={{ ...webStyles.editButton, textTransform: "none" }}
                    onClick={() =>
                      document.getElementById("image-upload-input")?.click()
                    }
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}