// Customizable Area Start
import React from "react";
import EmailTemplatesCreateController, {
  Props,
  normalizeContentWithDefaultEmailSettings,
} from "./EmailTemplatesCreateController.web";
import { FormHelperText, TextField, IconButton } from "@material-ui/core";
import Editor, {
  Preview,
} from "../../ss-cms-common-components/src/Editor-v4/Editor";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import "../assets/styles.css";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { Scrollbars } from "react-custom-scrollbars";
import { PreviewIcon } from "./assets";
import { Prompt } from "react-router";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { DesktopIcon, TabletIcon, MobileIcon } from "./assets";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { twitter_logo } from "../../footer/src/assets";

const configJSON = require("./config.js");
// Customizable Area End
export class EmailTemplatesCreate extends EmailTemplatesCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedTab, content, defaultEmailSettings } = this.state;
    const normalizedContent = normalizeContentWithDefaultEmailSettings(
      content || "",
      defaultEmailSettings
    );

    return (
      <div className="email-template-create">
        {this.state.editorMaximized && (
          <Prompt
            message={(location, action) => {
              return true;
            }}
          />
        )}
        <div className="email-template-create-left">
          <Scrollbars>
            <div className="header">
              <div
                data-testid="back-button"
                className="icon-back-wrapper"
                onClick={this.handleBackButtonClick}
              >
                <i
                  className="icon-arrow-right"
                  onClick={this.handleBackButtonClick}
                />
                <span>{configJSON.textBack}</span>
              </div>

              <span className="header-title">
                {this.state.titleInit || "Create Email"}
              </span>
              <span className="hint">{configJSON.textQuickEmailPreview}</span>
              <div className="tabs-wrapper">
                <Tabs onTabChange={this.handleTabChange} value={selectedTab} />
                <div className="preview-email-hint">
                  <img src={PreviewIcon} alt="preview email" />
                  <span>{configJSON.textPreviewEmail}</span>
                </div>
              </div>
            </div>
            <div className="container">
              <div
                className={`preview-wrapper ${this.handleDeviceResolution(
                  selectedTab
                )}`}
              >
                <Preview value={normalizedContent} />
                <PreviewFooter social={this.state.social} commonSettings={this.state.commonSettings} />
              </div>
            </div>
          </Scrollbars>
        </div>
        <Scrollbars style={{ width: "30%" }}>
          <div className="email-template-create-right">
            <div className="email-template-create-right-container">
              <span className="header-title">{configJSON.textEdit}</span>
              <span className="header-title-2">{configJSON.textTitle} *</span>
              <TextField
                data-testid="email-title-input"
                size="small"
                variant="outlined"
                fullWidth
                className="title-input"
                disabled
                value={this.state.title}
                onChange={this.handleTitleChange}
                error={this.state.titleError}
                helperText={
                  this.state.titleError && configJSON.textThisFieldIsRequired
                }
              />
              <span className="header-title-2">
                {configJSON.textEmailSubject} *
              </span>
              <TextField
                data-testid="email-subject-input"
                size="small"
                variant="outlined"
                fullWidth
                className="title-input"
                value={this.state.emailSubject}
                onChange={this.handleEmailSubjectChange}
                error={this.state.emailSubjectError}
                helperText={
                  this.state.emailSubjectError &&
                  configJSON.textThisFieldIsRequired
                }
              />
              <span className="header-title-2">{configJSON.textContent} *</span>
              <Editor
                value={this.state.content}
                onChange={this.handleEditorChange}
                onMaximize={this.handleMaximize}
                height="clamp(200px, calc(100vh - 550px), calc(100vh - 550px))"
                error={!!this.state.contentError}
              />
              {this.state.contentError && (
                <FormHelperText error={true}>
                  {configJSON.textThisFieldIsRequired}
                </FormHelperText>
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PreviewFooter = ({ social, commonSettings }: any) => {
  return (
    <div className="preview-footer">
      <div className="images">
        {social.facebook?.selected && (
          <IconButton onClick={() => {
            window.open(social.facebook.url)
          }}>
            <FacebookIcon />
          </IconButton>
        )}
        {social.instagram?.selected && (
          <IconButton onClick={() => {
            window.open(social.instagram.url)
          }}>
            <InstagramIcon />
          </IconButton>
        )}
        {social.twitter?.selected && (
          <IconButton onClick={() => {
            window.open(social.twitter.url)
          }}>
            <img src={twitter_logo} alt="twitter" />
          </IconButton>
        )}
        {social.youtube?.selected && (
          <IconButton onClick={() => {
            window.open(social.youtube.url)
          }}>
            <YouTubeIcon />
          </IconButton>
        )}
      </div>
      <div className="location">{commonSettings?.attributes?.address_line1 + ","} {commonSettings?.attributes?.address_line2 + ","} {commonSettings?.attributes?.city + ","} {commonSettings?.attributes?.state + ","} {commonSettings?.attributes?.country + ","} {commonSettings?.attributes?.pin_code + ","}</div>
    </div>
  );
};

interface TabProps {
  onTabChange?: (event: React.ChangeEvent<{}>, value: number) => void;
  value: number;
}

export const Tabs = ({ onTabChange, value }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {[
        [configJSON.textDesktop, DesktopIcon],
        [configJSON.textTablet, TabletIcon],
        [configJSON.textMobile, MobileIcon],
      ].map(([label, Icon], itemIndex) => (
        <MuiTab
          data-testid={`preview-device-${label}`}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
            wrapper: "tab-icon-wrapper",
          }}
          label={label}
          icon={
            <Icon
              className="tab-icon"
              color={value === itemIndex ? "#6200EA" : "#9B9FB1"}
            />
          }
        />
      ))}
    </MuiTabs>
  );
};
// Customizable Area End

// Customizable Area Start
export default withLayout(withLoader(
  withSnackBar(withHeaderBar(withDialog(EmailTemplatesCreate)))
));
// Customizable Area End
