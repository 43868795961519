import React from "react";
// Customizable Area Start
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  TextField,
  Typography,
  TextareaAutosize,
  Container,
  DialogTitle,
  Button,
  Slide,
  CardMedia
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined, CloseOutlined } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactController, { ContactUsArray } from "./ContactController";
import Loader from "../../../components/src/Loader.web";
import Checkbox from "@material-ui/core/Checkbox";
import { closeIcon, tickIcon, builderStudioStore } from "./assets";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const ContactCustomTableRow = withStyles(() => ({
  root: {
    borderBottom: "4px solid #ECEDF0",
    "&:last-child": {
      borderBottom: "none",
    },
    margin: "10px 0",
  },
  hover: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
}))(TableRow);

const ContactCustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "transparent",
  },
}))(TableCell);

const ContactDialogBox = withStyles(() => ({
  paper: {
    width: "447px",
    height: "auto",
    padding: "40px",
  },
}))(Dialog);

const ContactCustomDialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(DialogContent);
// Customizable Area End

export default class AdminContact extends ContactController {
  // Customizable Area Start
  renderDeleteButton() {
    const { deletedRow } = this.state;

    if (deletedRow.length > 0) {
      return (
        <Typography
          variant="h4"
          data-test-id={`checkbox`}
          style={webStyle.deleteButton}
          onClick={() => this.handleDeleteClick(deletedRow)}
        >
          Delete Queries
        </Typography>
      );
    }

    return null;
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { contactUsList, openDialog, selectedRow, loading, deletedRow, showSuccessMessage, showDeleteDialog } = this.state;
    const checkedRow = deletedRow.length > 0 && this.state.deletedRow.length === this.state.contactUsList.length;
    return (
      //Merge Engine DefaultContainer
      <>
        <Loader loading={loading} />
        <Slide
          direction="down"
          in={showSuccessMessage}
          mountOnEnter
          unmountOnExit
          style={{left: 0, right: 0}}
        >
          <Box
            style={{
              ...webStyle.formActionsWrapper,
              zIndex: 9999,
              position: "fixed",
              top: 0
            }}
          >
            <Box style={webStyle.formActions}>
              <CardMedia
                component="img"
                src={builderStudioStore}
                style={{ ...webStyle.logo, objectFit: "none" }}
              />
              <Box
                style={webStyle.successContent}
              >
                <img style={webStyle.closeIcon} src={tickIcon} alt="tickIcon" />
                <Typography style={webStyle.successMsg}>Success! Query deleted successfully.</Typography>
              </Box>

            </Box>
          </Box>
        </Slide>
        <ThemeProvider theme={theme}>
          <Container style={{ width: "100%", maxWidth: "100%" }}>
            <Box py={4} style={{ margin: "auto" }}>
              <Box style={webStyle.subContainerOne}>
                <Box style={{
                  display: "flex", justifyContent: "space-between"
                }}>
                  <Typography
                    style={{
                      ...webStyle.tableHeading
                    }}
                  >
                    Inbound Queries
                  </Typography>
                  {this.renderDeleteButton()}
                </Box>
              </Box>
              <Box style={webStyle.subContainerTwo}>
                <TableContainer
                  component={Paper}
                  style={{ overflow: "hidden", boxShadow: "none", border: "none" }}
                >
                  <Table aria-label="contact table">
                    <TableHead style={webStyle.tableHeader}>
                      <TableRow>
                        <ContactCustomTableCell style={{ ...webStyle.cellHeading, width: "10%" }} >
                          <Checkbox
                            data-test-id="selected-all-queries"
                            style={{
                              ...webStyle.checkbox,
                              color: checkedRow ? "#6200EA" : "#D0D2DA",
                            }}
                            checked={checkedRow}
                            onChange={this.handleSelectAllFields}
                          />
                        </ContactCustomTableCell>
                        <ContactCustomTableCell style={webStyle.cellHeading}>
                          Name
                        </ContactCustomTableCell>
                        <ContactCustomTableCell style={webStyle.cellHeading}>
                          Email
                        </ContactCustomTableCell>
                        <ContactCustomTableCell style={webStyle.cellHeading}>
                          Phone
                        </ContactCustomTableCell>
                        <ContactCustomTableCell style={webStyle.cellHeading}>
                          Subject
                        </ContactCustomTableCell>
                        <ContactCustomTableCell style={webStyle.cellHeading}>
                          Comments
                        </ContactCustomTableCell>
                        <ContactCustomTableCell style={webStyle.cellHeading}>
                          Query Date
                        </ContactCustomTableCell>
                        <ContactCustomTableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody style={webStyle.tableBodyDesign}>
                      {contactUsList.length > 0 ? (
                        contactUsList.map((item: ContactUsArray, index: number) => (
                          <ContactCustomTableRow key={item.id} style={webStyle.tableRowDesign}>
                            <TableCell data-test-id={`check-box-${index}`} style={webStyle.cellContent}>
                              <Checkbox
                                data-test-id={`checkbox-${index}`}
                                style={{
                                  ...webStyle.checkbox,
                                  color: deletedRow.includes(item.id) ? "#6200EA" : "#D0D2DA",
                                }}
                                checked={deletedRow.includes(item.id)}
                                onClick={() => this.handleDeleteDialog(item.id)}
                              />
                            </TableCell>
                            <TableCell data-test-id={`name-${index}`} style={webStyle.cellContent}>{item.attributes.name}</TableCell>
                            <TableCell data-test-id={`email-${index}`} style={webStyle.cellContent}>
                              {item.attributes.email}
                            </TableCell>
                            <TableCell data-test-id={`phone-${index}`} style={{...webStyle.cellContent, width: "20%"}}>
                              {item.attributes.country_code + " " + item.attributes.phone_number}
                            </TableCell>
                            <TableCell data-test-id={`subject-${index}`} style={{ ...webStyle.cellContent, width: "20%" }}>
                              {item.attributes.subject}
                            </TableCell>
                            <TableCell data-test-id={`desc-${index}`} style={{ ...webStyle.cellContent, width: "25%"}}>
                              {item.attributes.description}
                            </TableCell>
                            <TableCell data-test-id={`date-${index}`} style={{ ...webStyle.cellContent, width: "20%" }}>
                              {this.formatDate(item.attributes.created_at || "")}
                            </TableCell>
                            <TableCell data-test-id={`eye-icon-${index}`}>
                              <IconButton
                                data-test-id={`visibilityIcon-${index}`}
                                onClick={() => this.handleVisibilityClick(index)}
                              >
                                {this.state.openDialog && this.state.selectedRow === index? (
                                  <VisibilityOffOutlined />
                                ) : (
                                  <VisibilityOutlined style={webStyle.visibilityIcon} />
                                )}
                              </IconButton>
                            </TableCell>
                          </ContactCustomTableRow>
                        ))
                      ) : (
                        <ContactCustomTableRow style={webStyle.tableRowDesign}>
                          {loading ? <Loader loading={loading} /> : (
                            <TableCell colSpan={8} style={{ ...webStyle.cellMessage, textAlign: "center" }}>
                              No data found
                            </TableCell>)}
                        </ContactCustomTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <>
                  {showDeleteDialog && (
                    <Box
                      style={{
                        ...webStyle.dialogDeleteContainer, position: "fixed",
                        flexDirection: "column"
                      }}
                    >
                      <Box style={{ margin: "40px" }}>
                        <Box
                          style={webStyle.dialogDeleteTitle}
                        >
                          <Typography
                            variant="h6"
                            style={webStyle.dialogDeleteTitleText}
                          >
                            Are you sure?
                          </Typography>
                          <IconButton
                            data-test-id="close-dialog"
                            edge="end"
                            color="inherit"
                            onClick={this.handleDeleteDialogClose}
                            aria-label="close"
                            style={webStyle.closeBtn}
                          >
                            <img style={webStyle.closeIcon} src={closeIcon} alt="close" />
                          </IconButton>
                        </Box>
                        <Typography
                          style={webStyle.content}
                        >
                          Are you sure you want to delete this query ?
                        </Typography>
                      </Box>
                      <Box style={webStyle.dialogButton} >
                        <Button
                          data-test-id="cancel-btn"
                          style={{ ...webStyle.cancelButton, textTransform: "none" }}
                          onClick={this.handleDeleteDialogClose}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          data-test-id="delete-btn"
                          style={{ ...webStyle.deleteImageButton, textTransform: "none" }}
                          onClick={this.handleConfirmDelete}
                        >
                          Yes, delete
                        </Button>
                      </Box>
                    </Box>
                  )}
                </>
                <ContactDialogBox
                  open={openDialog}
                  onClose={this.handleCloseDialog}
                  maxWidth="md"
                >
                  {openDialog && selectedRow !== null && (
                    <React.Fragment>
                      <DialogTitle style={webStyle.dialogTitle}>
                        <Box style={webStyle.dialogBox}>
                          <Typography style={webStyle.dialogTitleText}>
                            Query
                          </Typography>
                          <IconButton data-test-id="visibility-close" edge="end" color="inherit" onClick={this.handleCloseDialog} aria-label="close" style={webStyle.closeBtn}>
                            <CloseOutlined />
                          </IconButton>
                        </Box>
                      </DialogTitle>
                      <ContactCustomDialogContent>
                        <Box mb={2}>
                          <Typography style={webStyle.inputLabel}>Name</Typography>
                          <TextField
                            data-test-id="name"
                            name="name"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={contactUsList[selectedRow].attributes.name}
                            inputProps={{
                              style: webStyle.input,
                            }}
                          />
                        </Box>
                        <Box mb={2}>
                          <Typography style={webStyle.inputLabel}>Email</Typography>
                          <TextField
                            data-test-id="email"
                            name="email"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={contactUsList[selectedRow].attributes.email}
                            inputProps={{
                              style: webStyle.input,
                            }}
                          />
                        </Box>
                        <Box mb={2}>
                          <Typography style={webStyle.inputLabel}>
                            Phone Number
                          </Typography>
                          <TextField
                            data-test-id="phone_number"
                            name="phone_number"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={contactUsList[selectedRow].attributes.country_code + " " + contactUsList[selectedRow].attributes.phone_number}
                            inputProps={{
                              style: webStyle.input,
                            }}
                          />
                        </Box>
                        <Box mb={2}>
                          <Typography style={webStyle.inputLabel}>Subject</Typography>
                          <TextField
                            data-test-id="subject"
                            name="subject"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={contactUsList[selectedRow].attributes.subject}
                            inputProps={{
                              style: webStyle.input,
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography style={webStyle.inputLabel}>Message</Typography>
                          <TextareaAutosize
                            data-test-id="description"
                            name="description"
                            disabled
                            value={contactUsList[selectedRow].attributes.description}
                            minRows={6}
                            style={{
                              ...webStyle.input,
                              width: "100%",
                              border: "1px solid #D0D2DA",
                              borderRadius: "3px",
                              resize: "none",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </Box>
                      </ContactCustomDialogContent>
                    </React.Fragment>
                  )}
                </ContactDialogBox>
              </Box>
              {!!this.state.pagination?.total_count && <Box marginBottom={4}><Pagination
                count={this.state.pagination?.total_count}
                onChange={this.handlePageChange}
                pageSize={this.state.pagination?.per_page}
                itemText={configJSON.paginationText}
                pageNo={this.state.pagination?.current_page || 1}
              /></Box>}
            </Box>
          </Container>
        </ThemeProvider>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  subContainerOne: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto"
  },
  subContainerTwo: {
    display: "flex",
    justifyContent: "end"
  },
  cellHeading: {
    fontFamily: "Rubik, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
  },
  cellContent: {
    fontFamily: "Rubik, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#3C3E49",
  },
  visibilityIcon: {
    width: "18px",
    height: "18px",
  },
  inputLabel: {
    fontFamily: "Rubik, sans-serif",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
  },
  input: {
    fontFamily: "Rubik, sans-serif",
    padding: "12px 16px",
    fontSize: "14px",
    lineHeight: "24px",
    height: "auto",
    borderRadius: "3px",
  },
  deleteButton: {
    color: "#FF1744",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    textDecoration: "underline solid #FF1744",
    marginTop: "10px",
    cursor: "pointer",
    marginLeft: "30px",
    lineHeight: "20px"
  },
  pageNumber: {
    cursor: "pointer",
    margin: "0 5px",
    fontFamily: "Rubik, sans-serif",
  },
  currentPageNumber: {
    cursor: "pointer",
    margin: "0 5px",
    fontWeight: "bold",
    fontFamily: "Rubik, sans-serif",
  },
  pageNumbers: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
    fontFamily: "Rubik, sans-serif",
  },
  paginationCount: {
    margin: "5px",
    fontFamily: "Rubik, sans-serif",
  },
  progressBar: {
    height: "5px",
    borderRadius: "2px",
    backgroundColor: "#F5F5F5",
    width: "200px",
    marginTop: "10px",
  },
  paginationText: {
    fontFamily: "Rubik, sans-serif",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  paginationContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tableBodyDesign: {
    border: "4px solid #ECEDF0",
  },
  tableRowDesign: {
    border: "4px solid #ECEDF0",
  },
  tableHeading: {
    fontFamily: "Rubik, sans-serif",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    paddingBottom: "50px",
  },
  dialogBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dialogTitle: {
    padding: "0px",
    marginBottom: "30px",
  },
  tableHeader: {
    backgroundColor: "#F8F9FA",
    border: "4px solid #F8F9FA",
  },
  closeBtn: {
    margin: "0px",
    padding: "0px",
  },
  cellMessage: {
    fontFamily: "Rubik, sans-serif",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    color: "#3C3E49",
    textAlign: "center"
  },
  noDataContainer: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    maxHeight: "416px",
    height: "416px",
    display: "flex",
  },
  message: {
    fontFamily: "Rubik sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  checkbox: {
    width: "24px",
    height: "24px",
    borderRadius: "2px"
  },
  dialogDeleteContainer: {
    width: "684px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.16)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
  },
  dialogDeleteTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px"
  },
  dialogDeleteTitleText: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    color: "#3C3E49",
    lineHeight: "32px"
  },
  deleteImageButton: {
    padding: "20px 48px 20px 48px",
    borderRadius: "0px",
    background: "#FF1744",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FFFFFF",
    lineHeight: "24px"
  },
  cancelButton: {
    padding: "20px 48px 20px 48px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    color: "#3C3E49",
    textDecoration: "underline",
    lineHeight: "20px"
  },
  content: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    color: "#3C3E49",
    marginBottom: "24px",
    lineHeight: "24px"
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
  dialogButton: {
    borderTop: " 1px solid #E8E8E8",
    padding: "0px",
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end"
  },
  successMsg: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "rubik",
    lineHeight: "20px",
    padding: "4px 0px",
    color: "#FFFFFF",
    margin: "0px 12px"
  },
  successContent: {
    display: "flex",
    margin: "auto",
  },
  formActionsWrapper: {
    width: "100%",
  },
  formActions: {
    display: "flex",
    backgroundColor: "#3C3E49",
    padding: "20px 24px",
  },
  logo: {
    width: "auto",
  },
  dialogTitleText: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "rubik",
  },
};
// Customizable Area End
