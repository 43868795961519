Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.emailSettingsEndpoint = "/bx_block_email_template/email_settings";
exports.defaultEmailSettingsEndpoint =  "/bx_block_email_template/default_email_settings"
exports.routeEmailTemplates = "/website-and-emails/email-templates";
exports.routeEmailCreate = "/website-and-emails/email-templates/create";

exports.textActive = "Active";
exports.textInactive = "Inactive";
exports.textHeaderEmailTemplates = "Email Templates";
exports.textBack = "Back";
exports.textQuickEmailPreview = "Quick email preview";
exports.textPreviewEmail = "Preview Email";
exports.textEdit = "Edit";
exports.textTitle = "Title";
exports.textEmailSubject = "Email Subject";
exports.textContent = "Content";
exports.textBuilderAiAddress =
  "Builder AI, 3rd Floor, 5 Merchant Square, Paddington, London, W2 6LG";
exports.textUnsubscribe = "Unsubscribe";
exports.textEmailPreferences = "Email preferences"
exports.textDesktop = "Desktop";
exports.textMobile = "Mobile";
exports.textTablet = "Tablet";
exports.getBrandEndPoint = "catalogue/brands";
exports.commonSettingsAdminApi="bx_block_settings/common_settings";

exports.textThisFieldIsRequired = "This field is required.";
exports.textErrorOnPageInitialization = "Error on page initialization";
exports.textErrorOnGetBrand = "Error on get Brand";
exports.textErrorOnGetCommonSettings = "Error on get common settings"
exports.textErrorOnSaveChanges = "Error on save changes";
exports.textErrorOnLoadingDefaultSettings = "Error on loading default settings";


exports.httpGetMethod = "GET";
exports.httpPatchMethod = "PATCH";
exports.httpPostMethod = "POST";

exports.deviceTypeDesktop = "desktop";
exports.deviceTypeMobile = "mobile";
exports.deviceTypeTablet = "tablet";
exports.routeEmailLogin = "EmailAccountLogin";
// Customizable Area End
