import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Service } from "../../utilities/src/models/Service";
// Customizable Area Start
export const configJSONUrl = require("../../../framework/src/config");
import moment from "moment";
import { BookingDataInterface } from "./AppointmentsController.web";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    classes: Record<string, string>;
    bookingData: BookingDataInterface;
    service: Service;
    paymentOption: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class ConfirmationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    momentChangeForAppointmentDate = (appointment_date: string, time_zone_short: string) => {
        return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])")
    }

    momentChangeForOrderData = (order_date: string) => {
        return (moment.utc(order_date).format("LLL"))
    }
    // Customizable Area End
}
