// Customizable Area Start
import * as React from "react"
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { Scrollbars } from "react-custom-scrollbars"
import EmptyPage from "../../ss-cms-common-components/src/EmptyPage/EmptyPage"

import { createStyles, withStyles } from "@material-ui/core"

import TableComponent from "../../ss-cms-common-components/src/TableComponent/TableComponent"
import CustomizedButton from "../../ss-cms-common-components/src/Button/CustomButton"

import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"

import StaticPageTableController from "./StaticPageTableController.web"
import { withRouter } from "react-router-dom"
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"

const configJSON = require("./config")
const titles = configJSON.staticPageTableTitles




export class StaticPageTable extends StaticPageTableController {
  handleStaticPageTable = () => {
    const { spTable, checkedStaticPageList, showEmptyPage } = this.state
    const { classes } = this.props;
    if (spTable?.length > 0) {
      return (
        <div className={classes.tableContainer}>
          <div className={classes.header}>
            <div className={classes.pageTitle}>
              <span>
                {configJSON.staticPages}
              </span>
              {checkedStaticPageList?.length > 0 && (
                <p className={classes.deleteItem} data-testid="deleteItem" onClick={this.handleDeleteModal}>
                  {configJSON.deleteStaticPages}
                </p>
              )}
            </div>
            <div className={classes.rowItem}>

              <CustomizedButton
                label={configJSON.createNewStaticPage}
                data-testid="create-button"
                handleClick={this.handleAddSPClick}
              />
            </div>
          </div>
          <TableComponent
            titles={titles}
            data={spTable}
            edit={true}
            showIds={false}
            handleEditClick={this.handleUpdateSPClick}
            activatedCellStyleTrue={activatedCellStyleTrue}
            activatedCellStyleFalse={activatedCellStyleFalse}
            getCheckedItems={(item) => this.checkedItems(item)}
            checkbox
          />
        </div>
      )
    } else if (showEmptyPage) {
      return (
        <div className={classes.emptyPage} data-testid="emptypage">
          <EmptyPage
            title={configJSON.youHaveNoStaticPages}
            message={configJSON.emptyPageMessage}
            button_label={configJSON.createStaticPage}
            handleClick={this.createStaticPageHandler}
          />
        </div>
      )
    }
  }

  render() {
    return (
      <Scrollbars>
        <div className="mainContainer-spt">
          {this.handleStaticPageTable()}
        </div>
      </Scrollbars>
    )
  }
}

export const styles = () =>
  createStyles({
    buttonContainer: {
      alignItems: "flex-end",
    },
    rowItem: {
      display: "flex", alignItems: "center", gap: "16px", marginTop: "16px", flex: 1, justifyContent: "flex-end"
    },
    tableContainer: {
      maxHeight: "440px",
      paddingLeft: "24px",
      paddingRight: "24px",
      paddingBottom: "24px",
      background: "#f8f9fa"
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    pageTitle: {
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "32px",
      display: "flex",
      letterSpacing: "-0.02em",
      color: "#3c3e49",
      padding: "40px 0px 24px 0px",
      alignItems: "center",
    },
    deleteItem: {
        fontFamily: "\"Rubik\", sans-serif",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
        letterSpacing: "0",
      textDecorationLine: "underline",
      color: "#ff1744",
      cursor: "pointer",
      margin: "0 0 0 1em"
    },
    emptyPage: {
      flex: 1
    }
  })


const activatedCellStyleTrue = {
  width: "min-content",
  paddingRight: 20,
  paddingLeft: 20,
  backgroundColor: "#EBF5F0",
  borderColor: "#B3DAC7",
  color: "#008243",
}
const activatedCellStyleFalse = {
  width: "min-content",
  paddingRight: 20,
  paddingLeft: 20,
  backgroundColor: "#FFECEC",
  borderColor: "#FFD7D7",
  color: "#E00000",
}

export default withLayout(withStyles(styles)(
  withLoader(withHeaderBar(withRouter(withDialog(StaticPageTable))))
))
// Customizable Area End