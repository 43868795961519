import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
export const configJSONUrl = require("../../../framework/src/config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: Record<string, string>;
    activeStep: number
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class AppointmentStiperController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
