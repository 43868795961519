// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Appheader = makeStyles((theme: Theme) => ({
  menuIcon: {
    padding: theme.spacing(0),
    "& svg": {
      color: "#000000"
    }
  },
  drawer: {
    width: "260px"
  },
  drawerCloseButton: {
    margin: theme.spacing(1.25),
    fontSize: "32px",
    color: "#364F6B"
  },
  divider: {
    margin: theme.spacing(0, 2)
  },
  appbar: {
    zIndex: 1,
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0px 4px 4px #F5F5F5"
    }
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "58px",
    padding: theme.spacing(0, 10),
    backgroundColor: "#F9FAFB",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 3)
    }
  },
  leftSideContainer: {
    display: "flex",
    alignItems: "center"
  },
  homeLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center"
  },
  logo: {
    width: "26px !important",
    height: "26px",
    marginRight: theme.spacing(2),
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 1, 0, 1.5)
    }
  },
  title: {
    marginRight: theme.spacing(5),
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#000000"
  },
  navigationItems: {
    display: "flex",
    gap: theme.spacing(3.5)
  },
  link: {
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      flex: 1
    }
  },
  linkText: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      fontWeight: 500,
      letterSpacing: "0.14px",
      color: "#364F6B",
      padding: theme.spacing(1.5, 0)
    }
  },
  RightSideContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3)
  },
  inputAdornment: {
    marginRight: theme.spacing(1.5)
  },
  searchBarIconAdornment: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0)
    }
  },
  searchBar: {
    height: "38px",
    width: "222px",
    backgroundColor: "#F9FAFB",
    padding: theme.spacing(0, 1.75, 0, 0),
    fontSize: "14px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "262px",
      "& + fieldset": {
        border: "none"
      }
    }
  },
  searchIcon: {
    color: "#757575"
  },
  closeIcon: {
    color: "#262626",
    fontSize: "17px"
  },
  bookNowButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    backgroundColor: "#3FC1CB",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    padding: theme.spacing(0.75, 2),
    borderRadius: "3px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5)
    }
  }
}));
// Customizable Area End