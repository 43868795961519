Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.loginAlertMsg = "Please login first";
exports.listOfOrdersEndPoints = "order_management/orders";
exports.listOfOrdersMethod = "GET";
exports.createOrderEndPoint = "order_management/orders";
exports.createOrderItemInOrderEndPoint = "order_management/orders/:id/add_order_items";
exports.createOrderMethod = "POST";
exports.getOrderByIdEndPoint = "order_management/orders/:id";
exports.getOrderByIdMethod = "GET";
exports.deleteOrderItemEndPoint = "order_management/orders/:id/remove_order_items";
exports.deleteOrderItemMethod = "DELETE";
exports.appplyCouponCodeEndpoint = "order_management/orders/:id/apply_coupon";
exports.appplyCouponCodeMethod = "POST";
exports.getAddressListEndPoint = "order_management/addresses";
exports.getAddressListMethod = "GET";
exports.createAddressEntPoint = "order_management/addresses";
exports.createAddressMethod = "POST";
exports.addAddressToAddressEndPoint = "order_management/orders/:id/add_address_to_order";
exports.addAddressToAddressMethod = "PUT";
exports.deleteOrderEndPoint = "order_management/orders/:id";
exports.deleteOrderMethod = "DELETE";
exports.cencelOrderEndPoint = "order_management/orders/:id/cancel_order";
exports.cencelOrderMethod = "PUT";
exports.orderCreatedAlertMsg = "You order is created successfully!";
exports.orderNumberText = "Order Number";
exports.quantityText = "Quantity";
exports.orderedOnText = "Ordered On";
exports.cancelOrderText = "Cancel Order";
exports.deleteOrderText = "Delete Order";
exports.cancelledText = "Cancelled";
exports.descriptionText = "Description: ";
exports.categoryText = "Category: ";
exports.createNewOrderText = "Create New Order"
exports.createNewOrderItemText = "Create New Order Item"
exports.addNewOrderItem = "Add New Order Item";
exports.viewDetailBtn = "View Details";
exports.loadingText = "Loading...";
exports.cancelBtnLabel = "Cancel";
exports.createBtnLabel = "Create";
exports.deleteItemBtnLabel = "Delete this item";
exports.currencySymbole = "$";
exports.subTotalText = "Sub total";
exports.shippingChargeText = "Shipping Charges";
exports.totalTaxText = "Total Tax";
exports.discountText = "Discount";
exports.totalText = "Total";
exports.couponCodePlaceholder = "Please enter coupon code";
exports.applyBtnLabel = "Apply";
exports.addressLabel = "Address:";
exports.selectAddress = "Select Add";
exports.changeAddress = "Change Add";
exports.addressNameLabel = "Name";
exports.addressTypeLabel = "Address Type";
exports.flatNoLabel = "Flat no.";
exports.landmarkLabel = "Landmark";
exports.addressLabel = "Address";
exports.addressLine2Label = "Address Line 2";
exports.cityLabel = "City";
exports.stateLabel = "State";
exports.countryLabel = "Country";
exports.zipcodeLabel = "Zipcode";
exports.createNewAddressText = "Create New Address";
exports.addressTypePlaceholder = "Please enter address type*";
exports.namePlaceholder = "Please enter address name*";
exports.flatNoPlaceholder = "Please enter flat no.*";
exports.zipCodePlaceholder = "Please enter zipcode*";
exports.addressPlaceholder = "Please enter address*";
exports.addressLine2Placeholder = "Please enter address line 2";
exports.landmarkPlaceholder = "Please enter landmark";
exports.cityPlaceholder = "Please enter city";
exports.statePlaceholder = "Please enter state";
exports.countryPlaceholder = "Please enter country";
exports.phoneNoPlaceholder = "Please enter phone no*";
exports.selectThisAddressBtn = "Select this address";
exports.addressNavigationAlert = "Order ID not present";
exports.emptyOrderListMsg = "Order list is empty";
exports.catalogueIdPlaceholder = "Please enter catalogue id";
exports.catalogueVariantIdPlaceholder = "Please enter catalogue variant id";
exports.quantityPlaceholder = "Please enter quantity";
exports.createNewOrderTitle = "Create New Order";
exports.orderManagementLabel = "Order Management";

exports.orderlistfilterEndPoint = "bx_block_appointment_management/appointments/filter_order";
exports.orderlistEndPoint = "bx_block_appointment_management/appointments/order_list";
exports.categoryApiContentType = "application/json";
exports.ordercategoryApiEndPoint = "bx_block_appointment_management/appointments/search_order";
exports.orderdownloadApiEndPoint ="bx_block_appointment_management/appointments/download_csv.csv";
exports.orderstatusApiEndPoint ="bx_block_appointment_management/appointments/update_status";
exports.routeAppointmentSummary = "bx_block_appointment_management/appointments/appointment_summary";
exports.routeDownloadCSV = "bx_block_appointment_management/appointments/download_csv.csv";

exports.dateFormat = "LLL";
exports.backendTypeOptions = ['Confirmed', 'Completed', 'InProgress', 'Cancelled', 'Refunded', 'placed'];

exports.textInProgress = "In Progress";
exports.textCompleted = "Completed";
exports.textRefunded = "Refunded";
exports.textPlaced = "Placed";
exports.textCancelled = "Cancelled";
exports.textConfirmed = "Confirmed";

exports.textOrderNumber = "Order number";
exports.textOrderDate = "Order date";
exports.textAppointmentDate = "Appointment date";
exports.textCustomer = "Customer";
exports.textStatus = "Status";
exports.textTotal = "Total";
exports.textEdit = "Edit";
exports.textPencil = "Pencil";
exports.textNoRecordsFound = "No records found.";
exports.textNoOrderTitle = "No orders";
exports.textNoOrder = "There are no orders to show at the moment, but don t worry they ll come in soon!";
exports.textNoResultsFound = "No results found";
exports.textPleaseRefineSearch = "Please refine your search";
exports.textOrders = "Orders";
exports.textDownloadCSV = "Download CSV";
exports.textFilterOrders = "Filter orders";
exports.textSearchOrders = "Search orders";
exports.textOrderStatus = "Order status";
exports.textInProgress = 'In Progress';
exports.textPlaced = "Placed";
exports.textTotal = "Total";
exports.textFrom = "From";
exports.textTo = "To*";
exports.textResetAll = "Reset All"
exports.textApply = "Apply";
exports.textLoadingResults = "Loading results";

exports.textSelectDateValidation = "Please select from and to dates"
exports.textSelectAmountValidation = "Please select from and to values"

exports.textCSVFileName = "order_list.csv"
exports.paginationText = "orders"
// Customizable Area End
