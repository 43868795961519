import React from "react";
// Customizable Area Start
import CommonSettingsAdminController, {
  Props
} from "./CommonSettingsAdminController.web";
import CommonSettingsAdmin from "./CommonSettingsAdmin.web";
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export default class CommonSettingsAdminWrapper extends CommonSettingsAdminController {
  constructor(props: Props) {
    super(props);
    mixpanel.track("webadmin_settings_page_enter");
  }


  render() {
    return (
      //Merge Engine DefaultContainer
      <CommonSettingsAdmin
        data={this.state.data}
        isSaving={this.state.isSaving}
        upsertService={this.upsertService}
        resetValues={this.state.resetValues}
        setResetValues={this.setResetValues}
      />
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area End
