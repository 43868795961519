import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
// Customizable Area Start
import { CCAvenueUtils } from "../../ccavenueintegration/src/CCAvenueUtils";
import { IconButtonProps } from "@material-ui/core";
export const configJSONUrl = require("../../../framework/src/config");
import * as Yup from "yup";
import moment, { Moment } from "moment";
import { Value } from "react-calendar/dist/cjs/shared/types";
const navigation = require("react-navigation");
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    // Customizable Area Start
    classes: Record<string, string>;
    personalDetailsData?: PersonalDetailsResponse;
    activeStep?: number;
    bookingData: BookingDataInterface;
    service: Service;
    paymentOption?: string;
    // Customizable Area End
}

// Customizable Area Start
interface ValidResponse {
    data: object;
}

interface ValidResponseMessage {
    message: string;
}

export interface ErrorResponse {
    errors: [
        {
            token: string;
        }
    ]
}

export interface StripesuccessRes {
    authentication_url: string;
    status: string;
}
export interface SubmitStripeData {
    name: string;
    cardNumber: number | string;
    cardCVV: number | string;
    cardDate: number | string;
}

export interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

export interface AppointmentListData {
    id: number;
    slot_start_time: string;
    slot_end_time: string;
    is_available: boolean;
}

export interface BookingDataInterface {
    id: string;
    type: string;
    attributes: {
        order_id: string;
        catalogue_id: number;
        payment_mode: string;
        total: number;
        status: string;
        duration: number;
        currency: null;
        appointment_date: string;
        order_date: string;
        personal_detail: {
            id: number;
            full_phone_number: string;
            email: string;
            full_name: string;
            created_at: string;
            updated_at: string;
            appointment_id: number;
            comment: string;
        };
        billing_address: {
            id: number;
            country: string;
            city: string;
            zip_code: string;
            state: string;
            address_line_1: string;
            address_line_2: string;
            flat_number: string;
            created_at: string;
            updated_at: string;
            appointment_id: number;
        },
        time_slot: {
            slot_start_time: string;
            slot_end_time: string;
        };
        timezone: string;
        time_zone_short: string;
    }
}

export interface BookingDataInterface1 {
    data: BookingDataInterface
}

export interface AddAppointmentError {
    errors: [
        "Catalogue is currently inactive."
    ]
}
interface PersonalDetailsResponse {
    selectedDate: Date | string | null;
    selectedSlot: AppointmentListData;
    service: Service;
    timeZone: string;
}

interface StripeErrorResponse {
    stripe: string;
}

interface CatalgoueResponseData {
    data: {
        id: string;
        type: string;
        attributes: {
            title: string;
            description: string;
            duration: number;
            status: boolean;
            discount_option: boolean;
            discount: number;
            payment_preferences: string;
            category: {
                id: number;
                name: string;
            };
            images: [
                {
                    id: number;
                    url: string;
                }
            ];
            current_date: string;
            price: number;
            country: {
                id: number;
                name: string;
                code: string;
                phone_code: string;
            };
            currency: {
                id: number;
                name: string;
                symbol: string;
            };
            payment_method: string;
            time_zone: string;
            actual_price: number;
            availability: [
                {
                    working_day: string;
                    is_available: boolean;
                    working_hours: [
                        {
                            start_time: string;
                            end_time: string;
                        }
                    ]
                }
            ]
        }
    }
}

interface GetAppointmentListData {
    data: {
        id: string;
        type: string;
        attributes: {
            time_slots: [
                {
                    id: number;
                    slot_start_time: string;
                    slot_end_time: string;
                    is_available: false;
                }
            ]
        }
    },
    meta: {
        message: string;
        time_zone: string;
        time_zone_short: string;
    }
}

interface GetAppointmentListFailureData {
    message: string
}

interface CcAvenueEncryptedData {
    enc_resp: string;
    access_code: string;
}

interface CcAvenueError {
    errors: Array<ccAcenueErrorObj>
}

interface ccAcenueErrorObj {
    ccavenue_payment: string
}
interface StripeSuccessData {
    data: {
        id: string;
        type: string;
        attributes: {
            id: string;
            amount: number;
            currency: string;
            customer: string;
            client_secret: string;
            confirmation_method: string;
            created: number;
            payment_method: string;
            payment_method_types: [
                string
            ]
        }
    },
    meta: {
        authentication_url: string;
        status: string
    }
}

interface StripeErrorData {
    errors: [
        {
            stripe: string
        }
    ]
}

export interface Values {
    name: string;
    email: string;
    phoneNumber: string;
    comments: string;
}

export interface AppointmentValidationSuccessData {
    message: string;
}

export interface AppointmentValidationFailureData {
    errors: Array<string>;
}

export interface Address {
    country: string | boolean;
    addr1: string | boolean;
    addr2: string;
    houseNo: string;
    zip_code: string | boolean;
    city: string | boolean;
    stateName: string | boolean;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    available_date: string;
    start_time: Moment | Date;
    end_time: Moment | Date;
    id: number;
    token: string;
    appointmentsList: Array<AppointmentListData>;
    selectedDate: Date | null;
    activeStep: number;
    serviceId: number;
    service: Service;
    personalDetailsData: PersonalDetailsResponse;
    selectedSlot: AppointmentListData;
    name: string;
    email: string;
    phoneNumber: string;
    comments: string;
    errors: Partial<Values>;
    addressError: Partial<Address>;
    isDisabled: boolean;
    paymentOption: string;
    country: string;
    addr1: string;
    addr2: string;
    houseNo: string;
    zip_code: string;
    city: string;
    stateName: string;
    appointmentsAvaibilityId: string;
    bookingData: BookingDataInterface;
    openDialog: boolean;
    screenSize: number;
    expanded: boolean;
    characterCount: number;
    paymentPreference: string;
    slotBookingErrorMessage: string;
    timeZone: string;
    stripeModalOpen: boolean;
    cardNumber: number | string;
    cvv: number | string;
    expiry: string | number;
    cardName: string;
    isOpenStripeModal: boolean;
    appointmentData: BookingDataInterface;
    stripeSuccessData: StripesuccessRes;
    stripeState: boolean;
    ccavenueModalOpen: boolean;
    ccavenueModalShowRespons: CcAvenueEncryptedData
    timeZoneOffset: number;
    currency: string;
    price: number;
    appointmentValidationMsg: string;
    ccAvenueErrorMessage: string;
    isLoading: boolean;
    isDataLenghtCheck: boolean;
    isPriceLoading: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    addAppointmentApiCallId: string = "";
    getAppointmentsListApiCallId: string = "";
    deleteAllAppointmentsApiCallId: string = "";
    getCatalogueApiCallId: string = "";
    stripePaymentApiCallId: string = "";
    ccAvenueintegrationApiCallId: string = "";
    appointmentValidationApiCallId: string = "";
    popupWin: Window | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
        let endTime = new Date();
        endTime.setMinutes(endTime.getMinutes() + 30);
        this.state = {
            // Customizable Area Start
            timeZoneOffset: 0,
            id: 0,
            start_time: new Date(),
            end_time: endTime,
            available_date: moment(new Date()).format("DD/MM/YY"),
            appointmentsList: [],
            token: "",
            selectedDate: new Date(),
            activeStep: 0,
            serviceId: 0,
            service: {
                images: [
                    {
                        url: ""
                    }
                ]
            } as Service,
            personalDetailsData: {} as PersonalDetailsResponse,
            selectedSlot: {} as AppointmentListData,
            name: "",
            email: "",
            phoneNumber: "",
            comments: "",
            errors: {},
            isDisabled: true,
            paymentOption: "",
            country: "",
            addr1: "",
            addr2: "",
            houseNo: "",
            zip_code: "",
            city: "",
            stateName: "",
            addressError: {},
            appointmentsAvaibilityId: "",
            bookingData: {
                id: "",
                type: "",
                attributes: {
                    order_id: "",
                    catalogue_id: NaN,
                    payment_mode: "",
                    total: NaN,
                    status: "",
                    duration: NaN,
                    currency: null,
                    appointment_date: "",
                    order_date: "",
                    personal_detail: {
                        id: NaN,
                        full_phone_number: "",
                        email: "",
                        full_name: "",
                        created_at: "",
                        updated_at: "",
                        appointment_id: NaN,
                        comment: ""
                    },
                    billing_address: {
                        id: NaN,
                        country: "",
                        city: "",
                        zip_code: "",
                        state: "",
                        address_line_1: "",
                        address_line_2: "",
                        flat_number: "",
                        created_at: "",
                        updated_at: "",
                        appointment_id: NaN
                    },
                    time_slot: {
                        slot_start_time: "",
                        slot_end_time: ""
                    },
                    timezone: "",
                    time_zone_short: ""
                }
            },
            openDialog: false,
            screenSize: window.innerWidth,
            expanded: false,
            characterCount: 0,
            paymentPreference: "",
            slotBookingErrorMessage: "",
            timeZone: "",
            stripeModalOpen: false,
            cardNumber: "",
            cvv: "",
            expiry: "",
            cardName: "",
            isOpenStripeModal: false,
            appointmentData: {
                type: "",
                id: "",
                attributes: {
                    catalogue_id: NaN,
                    order_id: "",
                    total: NaN,
                    status: "",
                    personal_detail: {
                        appointment_id: NaN,
                        id: NaN,
                        email: "",
                        created_at: "",
                        comment: "",
                        full_name: "",
                        updated_at: "",
                        full_phone_number: ""
                    },
                    payment_mode: "",
                    appointment_date: "",
                    order_date: "",
                    currency: null,
                    duration: NaN,
                    time_slot: {
                        slot_end_time: "",
                        slot_start_time: ""
                    },
                    time_zone_short: "",
                    timezone: "",
                    billing_address: {
                        city: "",
                        address_line_1: "",
                        id: NaN,
                        zip_code: "",
                        state: "",
                        country: "",
                        appointment_id: NaN,
                        address_line_2: "",
                        updated_at: "",
                        created_at: "",
                        flat_number: ""
                    }
                }
            },
            stripeSuccessData: {} as StripesuccessRes,
            stripeState: false,
            ccavenueModalOpen: false,
            ccavenueModalShowRespons: {} as CcAvenueEncryptedData,
            currency: "",
            price: NaN,
            appointmentValidationMsg: "",
            ccAvenueErrorMessage: "",
            isLoading: true,
            isDataLenghtCheck: false,
            isPriceLoading: true
            // Customizable Area End
        };

        // Customizable Area Start
        this.handleResize = this.handleResize.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
        // Customizable Area Start
        this.getCatalgoue();
        mixpanel.track("webcustomer_appointment_page_enter");
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("message", this.onReceiveMessage);
        this.setState({
            selectedDate: new Date(),
            serviceId: this.props.navigation.getParam("id")
        }, () => this.getAppointmentList(this.state.token));
        // Customizable Area End
    }

    async componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        window.addEventListener("message", this.onReceiveMessage);
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    onReceiveMessage = (event: MessageEvent<string>) => {
        if (event.data == "Success") {
          mixpanel.track("webcustomer_appointment_success_cceavenue");
        }
        else if (event.data == "Aborted" || event.data == "Failure") {
          mixpanel.track("webcustomer_appointment_fail_cceavenue");
        }
      };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.ccAvenueintegrationApiCallId) {
                this.postCCavenueSuccessCallBack(responseJson);
            }
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
            if (this.isValidResponseMessage(responseJson)) {
                this.getAppointmentListMessageSuccessCallBack(responseJson);
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson, errorReponse);
            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            runEngine.debugLog("Message Recived", message);

            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token });
            this.getAppointmentList(token);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    isValidResponseMessage = (responseJson: ValidResponseMessage) => {
        return responseJson && responseJson.message;
    };

    isInValidResponse = (responseJson: ErrorResponse) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseMessage & BookingDataInterface1 & GetAppointmentListData & CatalgoueResponseData & StripeSuccessData & CcAvenueEncryptedData & AppointmentValidationSuccessData) => {
        if (apiRequestCallId === this.addAppointmentApiCallId) {
            this.addAppointmentSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAppointmentsListApiCallId) {
            this.getAppointmentListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCatalogueApiCallId) {
            this.getCatalogueSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.stripePaymentApiCallId) {
            this.postStripeSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.ccAvenueintegrationApiCallId) {
            this.postCCavenueSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.appointmentValidationApiCallId) {
            this.postAppointmentValidationSuccessCallBack(responseJson);
        }
    };

    apiFailureCallBacks = (apiRequestCallId: string, responseJson: ErrorResponse & AddAppointmentError & GetAppointmentListFailureData & CatalgoueResponseData & StripeErrorData & CcAvenueError & AppointmentValidationFailureData, errorReponse: StripeErrorData) => {
        if (apiRequestCallId === this.addAppointmentApiCallId) {
            this.addAppointmentFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAppointmentsListApiCallId) {
            this.getAppointmentListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCatalogueApiCallId) {
            this.getCatalogueFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.stripePaymentApiCallId) {
            this.postStripeFailureCallBack(responseJson, errorReponse);
        }
        if (apiRequestCallId === this.ccAvenueintegrationApiCallId) {
            this.postCCavenueFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.appointmentValidationApiCallId) {
            this.postAppointmentValidationFailureCallBack(responseJson);
        }
    };

    addAppointmentSuccessCallBack = (responseJson: BookingDataInterface1) => {
        let responseJsonData = responseJson.data;
        this.getAppointmentList(this.state.token);
        if (this.state.paymentOption == "pay_in_person") {
            mixpanel.track("webcustomer_appointment_success_pay_in_person");
            this.setState({ bookingData: responseJsonData })
        } else {
            this.setState({ appointmentData: responseJsonData, isOpenStripeModal: false })
        }
        this.setState({
            bookingData: responseJsonData
        }, () => this.popupWin?.close());
    }

    addAppointmentFailureCallBack = (errorResponseJson: AddAppointmentError) => {
        this.setState({ slotBookingErrorMessage: errorResponseJson.errors[0] })
    }

    getAppointmentListSuccessCallBack = (responseJson: GetAppointmentListData) => {
        this.setState({
            appointmentsAvaibilityId: responseJson.data.id,
            appointmentsList: responseJson.data.attributes.time_slots,
            timeZone: responseJson.meta.time_zone_short,
            timeZoneOffset: this.textToUtcOffsetMinutes(responseJson.meta.time_zone)
        }, () => {
            this.setState({
                isDataLenghtCheck: false,
                isLoading: false
            })
        });
    }

    getAppointmentListMessageSuccessCallBack = (responseJson: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [] }, () => {
            this.setState({
                isLoading: false,
                isDataLenghtCheck: this.state.appointmentsList.length == 0 ? true : false
            })
        })
    }

    getAppointmentListFailureCallBack = (errorResponse: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [], timeZone: "" }, () => this.setState({ isLoading: false }));
        this.parseApiCatchErrorResponse(errorResponse);
    }

    getCatalogueSuccessCallBack = (responseJson: CatalgoueResponseData) => {
        this.handleGetCatalgoueResponse(responseJson);
    }

    getCatalogueFailureCallBack = (errorReponse: CatalgoueResponseData) => {
        this.parseApiCatchErrorResponse(errorReponse);
    }

    postStripeSuccessCallBack = (responseJson: StripeSuccessData) => {
        mixpanel.track("webcustomer_appointment_success_stripe");
        this.handlePostStripeResponse(responseJson);
    }

    postStripeFailureCallBack = (responseJson: StripeErrorData, errorReponse: StripeErrorData) => {
        mixpanel.track("webcustomer_appointment_fail_stripe");
        this.parseApiCatchErrorResponse(errorReponse);
        this.handleErrorResponse(responseJson.errors);
    }

    postCCavenueSuccessCallBack = (responseJson: CcAvenueEncryptedData) => {
        this.handleCcrevenueIntegrationResponse(responseJson);
    }

    postCCavenueFailureCallBack = (errorReponse: CcAvenueError) => {
        mixpanel.track("webcustomer_appointment_fail_cceavenue");
        this.setState({ ccAvenueErrorMessage: errorReponse.errors[0].ccavenue_payment });
    }

    postAppointmentValidationSuccessCallBack = (responseJson: AppointmentValidationSuccessData) => {
        this.handleAppointmentValidationMessage(responseJson);
    }

    postAppointmentValidationFailureCallBack = (errorReponse: AppointmentValidationFailureData) => {
        this.handleAppointmentFaliureMessage(errorReponse);
    }

    handleAppointmentValidationMessage = (responseJson: AppointmentValidationSuccessData) => {
        this.setState({ appointmentValidationMsg: responseJson.message })
        const { personalDetailsData } = this.state;
        if (this.state.appointmentValidationMsg == "Appointment is valid.") {
            if (personalDetailsData) {
                this.setState({ serviceId: this.props.navigation.getParam("id"), openDialog: false })
                if (this.state.paymentOption == "pay_in_person") {
                    this.addAppointment();
                } else {
                    if (this.state.currency == "INR") {
                        this.postCcavenuePayment();
                    } else {
                        this.setState({ isOpenStripeModal: true });
                    }
                }
            } else {
                this.showAlert("Error", "Something went wrong");
            }
        }
    }

    handleAppointmentFaliureMessage = (errorReponse: AppointmentValidationFailureData) => {
        this.setState({ slotBookingErrorMessage: errorReponse.errors[0] });
    }

    handleErrorResponse = (errorResponse: Array<StripeErrorResponse>) => {
        alert(errorResponse[0].stripe)
    }

    textToUtcOffsetMinutes(text: string) {
        const offsetRegex = /UTC([+-]\d+(:\d+)?)/;
        const offsetMatch = (text || "").match(offsetRegex);
        if (!offsetMatch) {
            return 0;
        }
        const offsetStr = offsetMatch[1];

        const offsetParts = offsetStr.split(':');
        const hours = parseInt(offsetParts[0]);
        const minutes = parseInt(offsetParts[1] || "0");

        const totalMinutes = hours * 60 + minutes;
        return totalMinutes
    }

    addAppointment = () => {
        const {
            serviceId,
            personalDetailsData,
            country,
            city,
            addr1,
            selectedSlot,
            paymentOption,
            selectedDate
        } = this.state;
        if (personalDetailsData && serviceId && country && addr1 && city && selectedDate instanceof Date) {
            const month = selectedDate.getMonth() + 1;
            const date = selectedDate.getDate();
            const year = selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;
            let paymentMethod;

            if (paymentOption === "pay_in_person") {
                paymentMethod = "pay_later";
            } else if (paymentOption === "pay_online") {
                paymentMethod = "pay_now";
            } else {
                paymentMethod = "";
            }

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: this.state.token,
            };

            const attrs = {
                appointment: {
                    time_slot_id: selectedSlot.id,
                    catalogue_id: serviceId,
                    appointment_date: formattedDate,
                    payment_mode: paymentMethod,
                    personal_detail_attributes: {
                        full_name: this.state.name,
                        full_phone_number: this.state.phoneNumber,
                        email: this.state.email,
                        comment: this.state.comments
                    },
                    billing_address_attributes: {
                        country: this.state.country,
                        city: this.state.city,
                        state: this.state.stateName,
                        address_line_2: this.state.addr2,
                        address_line_1: this.state.addr1,
                        zip_code: this.state.zip_code,
                        flat_number: this.state.houseNo
                    }
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addAppointmentApiCallId = requestMessage.messageId;

            const httpBody = {
                ...attrs,
            };
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.addAppointmentAPiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addappointmentAPiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    appointmentValidationApi = () => {
        const { serviceId, personalDetailsData, country, city, addr1, selectedSlot, paymentOption, selectedDate } = this.state;
        if (personalDetailsData && serviceId && country && addr1 && city && selectedDate instanceof Date) {
            const month = selectedDate.getMonth() + 1;
            const date = selectedDate.getDate();
            const year = selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;
            let paymentMethod;

            const itemId = this.props.navigation.getParam("id")
            if (paymentOption === "pay_in_person") {
                paymentMethod = "pay_later";
                if(itemId){
                    mixpanel.track("webcustomer_appointment_create_pay_in_person", { itemId });
                }
            } else if (paymentOption === "pay_online") {
                paymentMethod = "pay_now";
                if(itemId){
                    mixpanel.track("webcustomer_appointment_create_pay_now", { itemId });
                }
            } else {
                paymentMethod = "";
            }

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: this.state.token,
            };

            const attrs = {
                appointment: {
                    time_slot_id: selectedSlot.id,
                    catalogue_id: serviceId,
                    appointment_date: formattedDate,
                    payment_mode: paymentMethod,
                    personal_detail_attributes: {
                        full_name: this.state.name,
                        full_phone_number: this.state.phoneNumber,
                        email: this.state.email,
                        comment: this.state.comments
                    },
                    billing_address_attributes: {
                        country: this.state.country,
                        city: this.state.city,
                        state: this.state.stateName,
                        address_line_2: this.state.addr2,
                        address_line_1: this.state.addr1,
                        zip_code: this.state.zip_code,
                        flat_number: this.state.houseNo
                    }
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.appointmentValidationApiCallId = requestMessage.messageId;

            const httpBody = {
                ...attrs,
            };
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.addAppointmentAPiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.appointmentValidationAPiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    postCcavenuePayment = () => {
        const header = {
            "Content-Type": configJSON.appointmentApiContentType,
            token: this.state.token,
        };

        const attrs = {
            data:
            {
                order_id: "order-23",
                amount: this.state.price,
                currency: "INR",
                redirect_url: `${configJSONUrl.baseURL}/bx_block_ccavenue_integration/encrypts_and_decrypts/payment_confirmation`,
                cancel_url: `${configJSONUrl.baseURL}/bx_block_ccavenue_integration/encrypts_and_decrypts/payment_confirmation`,
                language: "EN",
                billing_name: this.state.name,
                billing_address: this.state.addr1,
                billing_city: this.state.city,
                billing_state: this.state.stateName,
                billing_zip: this.state.zip_code,
                billing_country: this.state.country,
                billing_tel: this.state.phoneNumber,
                billing_email: this.state.email,
                customer_identifier: "",
                promo_code: "",
                integration_type: "iframe_normal"
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const itemId = this.props.navigation.getParam("id")
        if(itemId){
            mixpanel.track("webcustomer_appointment_create_ccavenue_payment", { itemId });
        }
        this.ccAvenueintegrationApiCallId = requestMessage.messageId;

        const httpBody = {
            ...attrs,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addAppointmentAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dataEncryptionEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    isStringNullOrBlank(strings: string | null | undefined) {
        if (strings === null || strings === undefined) {
            return true;
        }
        if (typeof strings !== "string") {
            return true;
        }
        return strings.trim() === "";
    }

    getAppointmentList = (token: string) => {
        const catalogueId = this.props.navigation.getParam("id");
        if (this.state.selectedDate && catalogueId) {
            const month = this.state.selectedDate.getMonth() + 1;
            const date = this.state.selectedDate.getDate();
            const year = this.state.selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getAppointmentsListApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.appointmentAPiEndPoint}?catalogue_id=${catalogueId}&availability_date=${formattedDate}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    postStripePaymentApi = () => {
        const header = {
            "Content-Type": configJSON.appointmentApiContentType,
            token: this.state.token,
        };

        const itemId = this.props.navigation.getParam("id")
        if(itemId){
            mixpanel.track("webcustomer_appointment_create_stripe_payment", { itemId });
        }

        const Month = this.state.expiry.toString().split("/");
        const catalogueId = this.props.navigation.getParam("id");
        const attrs = {
            payment: {
                catalogue_id: catalogueId,
                type: "card",
                card_details: {
                    number: this.state.cardNumber,
                    exp_month: Month[0],
                    exp_year: "20" + Month[1],
                    cvc: this.state.cvv
                },
                billing_details: {
                    name: this.state.name,
                    email: this.state.email,
                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.stripePaymentApiCallId = requestMessage.messageId;

        const httpBody = {
            ...attrs,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.stripeAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postStripePaymentApiEndPoints
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCatalgoue = () => {
        const catalogueId = this.props.navigation.getParam("id");
        if (catalogueId) {
            this.setState({ serviceId: catalogueId });
            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getCatalogueApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.catalogueAPIEndPoint}/${catalogueId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    handleGetCatalgoueResponse = (responseJson: CatalgoueResponseData) => {
        let responseJsonData = responseJson.data;
        if (responseJsonData.attributes.status == false){
            this.props.navigation.navigate("Services")
        }
        this.setState({
            service: parseCatalogue(responseJsonData),
            currency: responseJsonData.attributes.currency.name,
            price: responseJsonData.attributes.price,
            isPriceLoading: false
        });
    };

    handlePostStripeResponse = (responseJson: { meta: StripesuccessRes }) => {
        this.addAppointment();
        this.setState({
            stripeSuccessData: responseJson.meta,
            isOpenStripeModal: false
        }, () => {
            this.setState({ stripeState: true, }, () => {
                setTimeout(() => {
                    this.setState({ stripeState: false, bookingData: this.state.appointmentData })
                }, 5000)
            });
        })
    }
    handleCcrevenueIntegrationResponse = (responseJson: CcAvenueEncryptedData) => {
        this.setState({
            ccavenueModalShowRespons: responseJson,
        },()=>{
            if (this.state.ccavenueModalShowRespons.access_code !== "") {
                const popupFeatures = [
                    "height=700",
                    "width=500",
                    "resizable=no",
                    "scrollbars=yes",
                    "toolbar=no",
                    "menubar=no",
                    "location=no",
                    "directories=no",
                    "status=yes",
                    `top=${window.screen.height / 2 - (700 / 2 + 50)}`,
                    `left=${window.screen.width / 2 - (500 / 2 + 10)}`
                ];
                this.popupWin = window.open("", 'GooglePopup', popupFeatures.join(","));
                this.popupWin?.document.write(CCAvenueUtils(this.state.ccavenueModalShowRespons));
                this.focusToPopup();
            }
        })
    };

    closeThePopup = () => {
        this.addAppointment()
    };

    closeThePopupOnCancel = () => {
        this.popupWin?.close()
    }

    focusToPopup = () => {
        this.popupWin?.focus();
    };

    handelAvaibilityTimeSlot = (slot: AppointmentListData) => {
        this.setState({
            selectedSlot: slot,
            isDisabled: false,
        });
    }

    handelProceedFromDateTime = () => {

        const { selectedDate, service, selectedSlot, timeZone } = this.state;
        if (selectedSlot) {
            const itemId = this.props.navigation.getParam("id")
            if(itemId){
                mixpanel.track("webcustomer_appointment_proceed_from_date_time", { itemId });
            }
            this.setState((prevState) => ({
                activeStep: prevState.activeStep + 1,
                timeZone: timeZone,
                personalDetailsData: {
                    selectedDate,
                    selectedSlot,
                    service,
                    timeZone
                }
            }));
        } else {
            this.showAlert("Error", "No slot selected");
        }
    };

    handleProceedToPersonalDetails = () => {
        const { personalDetailsData, email, name, phoneNumber, characterCount } = this.state;
        try{
            this.AppointmentSchema.validateSync(this.state, {abortEarly: false});

            const itemId = this.props.navigation.getParam("id")
            if(itemId){
                mixpanel.track("webcustomer_appointment_proceed_to_personal_details", { itemId });
            }
            let paymentOption = "pay_in_person";
            if (this.state.service && ["pay_online_or_in_person", "pay_online"].includes(this.state.service.paymentPreference)) {
                paymentOption = "pay_online";
            }
            this.handleProceedToPersonalDetailsInnerFun(personalDetailsData , paymentOption);
        }catch(error){
            if (error instanceof Yup.ValidationError) {
                const errors: Record<string, string> = {};
                error.inner.forEach((error) => {
                    errors[error.path as string] = error.message;
                })
                this.setState({ errors })
            }
        }
    }

    handleProceedToPersonalDetailsInnerFun = (personalDetailsData: PersonalDetailsResponse , paymentOption: string) => {
        if (personalDetailsData) {
            this.setState({
                paymentPreference: this.state.service ? this.state.service.paymentPreference : "",
                paymentOption,
                activeStep: this.state.activeStep + 1,
                errors: {}
            });
        } else {
            this.showAlert("Error", "Something went wrong");
        }
    }

    handleProceedToPayment = () => {
        this.appointmentValidationApi();
    }

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    btnBackProps = () => {
        this.props.navigation.goBack();
    }

    handlePaymentOptionChange = (event: { target: { value: string; }; }) => {
        this.setState({
            paymentOption: event.target.value
        });
    };

    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
        });
    };

    closeAppointmentDialog = () => {
        this.setState({
            activeStep: 0,
            slotBookingErrorMessage: "",
            ccAvenueErrorMessage: ""
        })
    }

    handleCardData = (data: SubmitStripeData) => {
        this.setState({
            cardNumber: data?.cardNumber,
            cvv: data?.cardCVV,
            expiry: data?.cardDate,
            cardName: data?.name
        }, () => {
            this.state.isOpenStripeModal && this.postStripePaymentApi();
        })
    };

    onCloseModal = () => {
        this.setState({
            isOpenStripeModal: false,
        })
    }

    handleResize() {
        this.setState({ screenSize: window.innerWidth });
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    dateIsDisabled = (item: { date: Date, view: string }) => {
        let { date, view } = item;
        const currentDate: object = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
        return view === "month" && date < currentDate && !moment(date).isSame(currentDate, "day");
    };

    convertTimeFormat(timeString: string): string {
        return moment(timeString, "HH:mm").format("h:mm A");
    }

    formatDate = (dateStr: Date | string | null) => {
        const newDate = moment(dateStr, "DD-MM-YYYY").format("DD-MM-YYYY");
        const [date_day, month, year] = newDate.split("-");
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const date = new Date(Number(year), Number(month) - 1, Number(date_day));

        const daySuffix = (number: number) => {
            if (number >= 11 && number <= 13) {
                return "th";
            }
            switch (number % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };

        const formattedDate = `${date.toLocaleString("en-US", { weekday: "long" })}, ${date.getDate()}${daySuffix(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate;
    }

    handleDateChange = (date: Value | null) => {
        this.setState({
            selectedDate: new Date(String(date)),
            serviceId: this.props.navigation.getParam("id"),
            isLoading: true
        }, () => this.getAppointmentList(this.state.token));
    };

    handleChange = (field: keyof Values) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        const characterCount = field === "comments" ? value.length : this.state.characterCount;
        try {
            const fieldValues: Partial<Values> = {
                [field]: value,
            };
            this.AppointmentSchema.validateSyncAt(field, fieldValues as Values);
            this.setState((prevState) => ({
                ...prevState,
                [field]: value,
                characterCount: characterCount,
                personalDetailsData: this.state.personalDetailsData,
                errors: { ...prevState.errors, [field]: "" },
            }));
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const _error = error as Yup.ValidationError;
                this.setState((prevState) => ({
                    ...prevState,
                    [field]: value,
                    characterCount: characterCount,
                    errors: { ...prevState.errors, [field]: _error.message },
                }));
            }
        }
    };

    handlePaymentChange = (field: keyof Address) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        if (field === "country" || field === "city" || field === "addr1" || field === "zip_code") {
            try {
                const fieldValues: Partial<Address> = {
                    [field]: value,
                };
                this.AddressSchema.validateSyncAt(field, fieldValues as Address);
                this.setState((prevState) => ({
                    ...prevState,
                    [field]: value,
                    addressError: { ...prevState.addressError, [field]: "" },
                }));
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    this.setState((prevState) => ({
                        ...prevState,
                        [field]: value,
                        isDisabled: true,
                        addressError: { ...prevState.addressError, [field]: (error as {message: string}).message },
                    }));
                }
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [field]: value,
                personalDetailsData: { ...this.state.personalDetailsData, [field]: value },
                addressError: { ...prevState.addressError, [field]: "" },
            }));
        }
    };

    AppointmentSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required")
            .min(4, "Name must be minimum 4 characters")
            .max(50, "Name must be maximum 50 characters")
            .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
        email: Yup.string().required("Email is required.").matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Please enter valid email"),
        phoneNumber: Yup.string()
            .required("Mobile Number is required")
            .matches(new RegExp("^[0-9]{9,13}$"), "Mobile Number is invalid"),
        comments: Yup.string()
            .max(100, "Comments must be maximum 100 characters"),
    });

    AddressSchema = Yup.object().shape({
        country: Yup.string()
            .required("Country is required")
            .min(3, "Country must be minimum 4 characters")
            .max(50, "Country must be maximum 50 characters")
            .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
        city: Yup.string().required("City is required.").matches(/^[a-zA-Z\s]+$/, "City should contain only alphabets"),
        addr1: Yup.string().required("Address line 1 is required"),
        zip_code: Yup.string()
            .required("Zip code is required")
    });

    handleOpenDialog = () => {
        const { country, addr1, city, zip_code, stateName } = this.state;

        if (!country || !addr1 || !city || !zip_code || !stateName) {
            this.setState({
                addressError: {
                    country: !country && "Country is required",
                    addr1: !addr1 && "Address line 1 is required",
                    city: !city && "City is required",
                    zip_code: !zip_code && "Zip code is required",
                    stateName: !stateName && "State is required",
                }
            })
        } else {
            this.setState({
                openDialog: true, addressError: {}
            });
        }
    };

    handlePayonlineRadioBtn = () => { this.setState({ paymentOption: "pay_online" }) }

    handlePayAtLocationRadioBtn = () => { this.setState({ paymentOption: "pay_in_person" }) }
    // Customizable Area End
}
