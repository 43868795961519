import React, { useState, useEffect, useRef } from "react";
// Customizable Area Start
import {
    Typography, Button, Container, Grid, Box,
    TextField, IconButton, Card, CircularProgress,
    CardMedia, FormHelperText
} from "@material-ui/core";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { pencil, chevron, uparrow, cross, downarrow, NotFoundIcon } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import { Formik, Field } from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import CurrencyInput from "./CurrencyInput.web";
import Currency from "../../utilities/src/components/Currency.web";
import {FilterType,OrderType, PaginationType} from "./types";
import { Theme, makeStyles } from "@material-ui/core";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";

const configJSON = require('./config.js');


export interface Category {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        count: number;
        image_url: string;
    };
}

const OrderList = ({
    filters,
    orderList_Data,
    handleDialogOpen,
    handleDialogClose,
    searchQuery,
    isDialogOpen,
    handleSubmit,
    appointment_summary,
    getList,
    download,
    serach_orders_list,
    isLoading,
    isOrderBlank,
    dateError,
    totalError,
    resetFilter,
    isCsvDownloading,
    pagination,
    handlePageChange,
}: {
    filters?:FilterType;
    orderList_Data: OrderType[] | null;
    handleDialogOpen: () => void;
    handleDialogClose: () => void;
    searchQuery: string;
    isDialogOpen: boolean;
    handleSubmit: (values: FilterType) => void;
    appointment_summary: (itemID:string | null) => void;
    getList: (itemID: string) => void;
    download: () => void;
    serach_orders_list: (queryRef: React.RefObject<HTMLInputElement>) => void;
    isLoading: boolean;
    isOrderBlank: boolean;
    dateError: string | null;
    totalError: string | null;
    resetFilter: () => void;
    isCsvDownloading: boolean;
    pagination?: PaginationType;
    handlePageChange: (page: number) => void;
}) => {
    const classes = useStyles();
    const queryRef = useRef<HTMLInputElement>(null);

    const [isOpen, setIsOpen] = useState(true);
    const [isTotalOpen, setIsTotalOpen] = useState(true);
    const [isDateOpen, setIsDateOpen] = useState(true);

    const setView = () => {
        if (orderList_Data != null && orderList_Data.length > 0) {
            return (
                <>
                    <Container maxWidth="xl" style={webStyle.mainContainer}>


                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box >
                                    {orderList_Data.length > 0 ? (
                                        <>
                                        <TableContainer style={webStyle.tableContainer}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow style={webStyle.tablethStyle}>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textOrderNumber}</TableCell>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textOrderDate}</TableCell>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textAppointmentDate}</TableCell>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textCustomer}</TableCell>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textStatus}</TableCell>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textTotal}</TableCell>
                                                        <TableCell style={webStyle.tablecellstyle}>{configJSON.textEdit}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={webStyle.tableBorder}>
                                                    {orderList_Data.map((order, index: number) => {
                                                        let status = "";
                                                        let buttonStyle = {};
                                                        
                                                        if (order.attributes.status === "in_progress") {
                                                            status =  configJSON.textInProgress;
                                                            buttonStyle = webStyle.orderStatusInProgress;
                                                        } else if (order.attributes.status === "completed") {
                                                            status =  configJSON.textCompleted;
                                                            buttonStyle = webStyle.orderStatusCompleted;
                                                        } else if (order.attributes.status === "refunded") {
                                                            status =  configJSON.textRefunded;
                                                            buttonStyle = webStyle.orderStatusRefunded;
                                                        } else if (order.attributes.status === "placed") {
                                                            status =  configJSON.textPlaced;
                                                            buttonStyle = webStyle.orderStatusPlaced;
                                                        } else if (order.attributes.status === "cancelled") {
                                                            status =  configJSON.textCancelled;
                                                            buttonStyle = webStyle.orderStatusCancelled;
                                                        } else if (order.attributes.status === "confirmed") {
                                                            status = configJSON.textConfirmed;
                                                            buttonStyle = webStyle.orderStatusConfirmed;
                                                        } else {
                                                            status =  order.attributes.status;
                                                            buttonStyle = webStyle.tdButtonStyle;
                                                        }
                                                     
                                                        return (
                                                            <TableRow key={order.id} style={webStyle.tableRowStyle}>
                                                                <TableCell style={webStyle.tbCellTdStyle}>{order.attributes.order_number}</TableCell>
                                                                <TableCell style={webStyle.tbCellTdStyle}>{moment.utc(order?.attributes.order_date).format(configJSON.dateFormat)}</TableCell>
                                                                <TableCell style={webStyle.tbCellTdStyle}>{moment.utc(order.attributes.appointment_date).format(configJSON.dateFormat)}</TableCell>
                                                                <TableCell style={webStyle.tbCellTdCust}>{order.attributes.customer.full_name}</TableCell>
                                                                <TableCell>
                                                                    <button style={buttonStyle}>{status}</button>
                                                                </TableCell>
                                                                <TableCell style={webStyle.tbCellTdTotal}><Currency text={String(order.attributes.total)}/></TableCell>
                                                                <TableCell>
                                                                    <img
                                                                        src={pencil}
                                                                        alt={configJSON.textPencil}
                                                                        onClick={() => { getList(order.id) }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                            {!!pagination?.total_count && <Box marginBottom={4}><Pagination
                                                count={pagination?.total_count}
                                                onChange={handlePageChange}
                                                pageSize={pagination?.per_page}
                                                itemText={configJSON.paginationText}
                                                pageNo={pagination?.current_page || 1}
                                            /></Box>}
                                        </>
                                    ) : <p>{configJSON.textNoRecordsFound}</p>}

                                </Box>
                            </Grid>
                        </Grid>


                    </Container >
                </>
            )
        } else if (orderList_Data != null && orderList_Data.length == 0 && isOrderBlank) {
            return (
                <Container maxWidth="xl" style={webStyle.mainContainer}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '100vh',
                            backgroundColor: ' #FFFFFF'
                        }}
                    >
                        <Box
                            style={{
                                width: '684px',
                                height: '251px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="h3"
                                style={{
                                    fontFamily: 'Rubik',
                                    fontSize: '28px',
                                    fontWeight: 500,
                                    lineHeight: '32px',
                                    letterSpacing: '-0.02em',
                                    margin: '0',
                                    color: '#3C3E49'
                                }}
                            >
                                <Box textAlign="center">{configJSON.textNoOrderTitle}</Box>
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    fontFamily: 'Rubik',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: '#676B7E',
                                    marginTop: '16px',
                                    width: '100%',
                                    maxWidth: '395px',
                                }}
                            >
                                There are no orders to show at the moment, but don t worry they ll come in soon!
                            </Typography>
                        </Box>
                    </div>
                </Container>
            )

        } else if (orderList_Data != null && orderList_Data.length == 0 && !isOrderBlank) {
            return (
                <Card className={classes.noOrderCard}>
                    <div className={classes.noOrderCardContent}>
                        
                    <CardMedia
                        component="img"
                        image={NotFoundIcon}
                        className={classes.notFoundImage}                        
                        alt={configJSON.textNoResultsFound}
                    />
                    <Typography className={classes.noResultsFound}>
                        {configJSON.textNoResultsFound}
                    </Typography>
                    <Typography className={classes.pleaseRefineYourSearch}>
                        {configJSON.textPleaseRefineSearch}
                    </Typography>

                    </div>
                </Card>
            )
        }
        return <></>
    }

    return (
        <>
            <Container maxWidth="xl" style={webStyle.mainContainer}>
                <Grid container style={webStyle.MainContainers}>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.gridSection1}>
                        <Typography variant="h3" style={webStyle.titleFirstText}>
                            {configJSON.textOrders}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.gridSection2} >
                        {isCsvDownloading ? <Box width="50px"><CircularProgress size="24px" /></Box> : <Typography variant="body1" data-testid="download-csv" onClick={() => { download() }}
                            style={webStyle.titleCsvStyle}
                        >
                            {configJSON.textDownloadCSV}
                        </Typography>}
                    </Grid>
                </Grid>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ height: '41px', width: '12%', textAlign: 'center', border: '1px solid #D0D2DA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div data-test-id="filter_search" onClick={handleDialogOpen} style={{...webStyle.containerStyle, textAlign:"left"}}>
                            {configJSON.textFilterOrders}
                            <img src={chevron} alt="Image" style={webStyle.filtericon} />
                        </div>
                    </div>
                    <div style={{ width: '92%', height: '41px', border: '1px solid #D0D2DA', display: 'flex', alignItems: 'center', marginLeft: '0', paddingLeft: '10px' }}>
                        <IconButton style={webStyle.iconButtonStyle}>
                            <SearchIcon style={webStyle.searchIconStyle} />
                        </IconButton>
                        <TextField
                            style={{...webStyle.textFieldStyle, textAlign:"left"}}
                            placeholder={configJSON.textSearchOrders}
                            InputProps={{
                                disableUnderline: true,
                            }}

                            inputRef={queryRef}
                            onChange={() => serach_orders_list(queryRef)}
                        />

                    </div>
                </div>
            </Container>

            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '3px',
                        width: '309px',
                        border: '0px solid #D0D2DA',

                    },
                }}
            >
                <DialogTitle id="customized-dialog-title" >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span style={{
                                        flexGrow: 1,
                                        fontFamily: 'Rubik',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        letterSpacing: '-0.02em',
                                        textAlign: 'left',
                                        color: '#3C3E49',
                                    }}>
                                        Filter
                                    </span>

                        <IconButton aria-label="close" onClick={handleDialogClose}>
                            <img src={cross} />
                        </IconButton>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: '0.5rem',
                        marginTop: '0.5rem',
                        borderBottom: '1px solid #ECEDF0',
                        paddingBottom: '14px'
                    }}>
                                    <span style={webStyle.OrderTextStyle}>
                                    Appointment date
                                    </span>
                        {isDateOpen ? (
                            <img src={uparrow} alt="up Arrow" onClick={() => {
                                setIsDateOpen(!isDateOpen)
                            }} />
                        ) : (
                            <img src={downarrow} style={{ width: '24px', height: '25px' }} alt="pencil Icon" onClick={() => {
                                setIsDateOpen(!isDateOpen)
                            }} />
                        )}
                    </div>
                </DialogTitle>
                <DialogContent style={{ position: 'relative', paddingBottom: '50px' }}>
                    <Formik
                        initialValues={filters || {
                            from_date: '',
                            to_date: '',
                            checkboxes: {
                                'Confirmed': false, 'Completed': false, 'InProgress': false, 'Cancelled': false, 'Refunded': false, 'placed': false
                            },
                            integerField1: '',
                            integerField2: '',
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, handleChange, values, resetForm }) => (
                            <form onSubmit={handleSubmit} style={{ marginBottom: '1rem' }}>
                                {isDateOpen && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <div>
                                            <label style={{
                                                fontFamily: 'Rubik',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '20px',
                                                letterSpacing: '0px',
                                                textAlign: 'left',
                                                color: '#000000'
                                            }}>
                                                From
                                            </label>
                                            <Field
                                                type="date"
                                                name="from_date"
                                                onChange={handleChange}
                                                value={values.from_date}
                                                style={{
                                                    marginBottom: '0.5rem',
                                                    width: '119px',
                                                    height: '48px',
                                                    top: '24px',
                                                    borderRadius: '3px',
                                                    border: '1px solid #D0D2DA',
                                                    color: "#3C3E49",
                                                    fontSize: 14
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label style={{
                                                fontFamily: 'Rubik',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '20px',
                                                letterSpacing: '0px',
                                                textAlign: 'left',
                                                color: '#000000'
                                            }}>To</label>
                                            <Field type="date" name="to_date"
                                                   onChange={handleChange}
                                                   value={values.to_date}
                                                   style={{
                                                       marginBottom: '0.5rem',
                                                       width: '119px',
                                                       height: '48px',
                                                       top: '24px',
                                                       borderRadius: '3px',
                                                       border: '1px solid #D0D2DA',
                                                       color: "#3C3E49",
                                                       fontSize: 14

                                                   }} />
                                        </div>
                                    </div>
                                )}
                                {dateError && <FormHelperText error>{dateError}</FormHelperText>}
                                <div style={{ maxWidth: '261px', width: '100%' }}>
                                    <div style={{
                                        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                        paddingTop: '0.5rem', marginTop: '0.5rem', borderBottom: '1px solid #ECEDF0', paddingBottom: '10px'
                                    }}>
                                                    <span style={{
                                                        fontFamily: 'Rubik',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        lineHeight: '20px',
                                                        letterSpacing: '0px',
                                                        textAlign: 'left',
                                                        color: '#3C3E49',
                                                        width: '122px',
                                                        height: '23px',
                                                        top: '0.5px'
                                                    }}>
                                                        {configJSON.textOrderStatus}
                                                    </span>


                                        {isOpen ? (
                                            <img src={uparrow} alt="up Arrow" onClick={() => {
                                                setIsOpen(!isOpen);
                                            }} />
                                        ) : (
                                            <img src={downarrow} style={{ width: '24px', height: '25px' }} alt="pencil Icon" onClick={() => {
                                                setIsOpen(!isOpen);
                                            }} />
                                        )}
                                    </div>
                                    {isOpen && (
                                        <div>
                                            {configJSON.backendTypeOptions.map((option: string) => (
                                                <div key={option} style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                                    <Field
                                                        type="checkbox"
                                                        name={`checkboxes.${option}`}
                                                        onChange={handleChange}
                                                        style={webStyle.checkboxStyle}
                                                    />
                                                    <label style={webStyle.checkBoxStylelabel} htmlFor={`checkboxes.${option}`}>
                                                        {option === 'InProgress' ? configJSON.textInProgress : option === 'placed' ? configJSON.textPlaced : option}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div style={{
                                    display: 'flex', borderBottom: '1px solid #ECEDF0',
                                    justifyContent: 'space-between', paddingBottom: '10px', alignItems: 'center', paddingTop: '0.5rem', marginTop: '0.5rem'
                                }}>
                                                <span style={{
                                                    fontFamily: 'Rubik',
                                                    fontSize: '12px',
                                                    fontWeight: 400,
                                                    lineHeight: '20px',
                                                    letterSpacing: '0px',
                                                    textAlign: 'left',
                                                    color: '#3C3E49',
                                                    width: '122px',
                                                    height: '23px',
                                                    top: '0.5px'
                                                }}>
                                                    {configJSON.textTotal}
                                                </span>
                                    <div>
                                        {isTotalOpen ? (
                                            <img src={uparrow} alt="up Arrow" onClick={() => {
                                                setIsTotalOpen(!isTotalOpen);
                                            }} />
                                        ) : (
                                            <img src={downarrow} style={{ width: '24px', height: '25px' }} alt="pencil Icon" onClick={() => {
                                                setIsTotalOpen(!isTotalOpen);
                                            }} />
                                        )}
                                    </div>
                                </div>
                                {isTotalOpen && (
                                    <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <div>
                                            <label style={{
                                                fontFamily: 'Rubik',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '20px',
                                                letterSpacing: '0px',
                                                textAlign: 'left',
                                                color: '#000000'
                                            }}>
                                                {configJSON.textFrom}
                                            </label>
                                            <CurrencyInput
                                                type="text"
                                                name="integerField1"
                                                onChange={handleChange}
                                                value={values.integerField1}
                                                style={webStyle.fromLabelStyle}

                                            />
                                        </div>
                                        <div>
                                            <label style={webStyle.labeltoStyle}>{configJSON.textTo}</label>
                                            <CurrencyInput type="text" name="integerField2" onChange={handleChange}
                                                           value={values.integerField2}
                                                           style={webStyle.toValueStyle}
                                            />
                                        </div>
                                    </div>
                                )}
                                {totalError && <FormHelperText error>{totalError} </FormHelperText>}
                                <div style={webStyle.footerBtnSection} >
                                    <Button onClick={resetFilter} variant="text" color="default"
                                            style={webStyle.resetAllBtn}
                                    >
                                        {configJSON.textResetAll}
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" style={webStyle.applyBtn}
                                    >
                                        {configJSON.textApply}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            {isLoading ? (
                <Card className={classes.noServicesCard}>
                    <Box className={classes.loaderContainer}>
                        <Typography className={classes.loadingResultsText}>
                            {configJSON.textLoadingResults}
                        </Typography>
                        <span>
                            <CircularProgress size={24} />
                        </span>
                    </Box>
                </Card>
            ) : (

                setView()
            )}
        </>
    );
};

// Customizable Area End
export default OrderList;

// Customizable Area Start

const webStyle = {

    mainContainer: {
        marginTop: '0px',
        backgroundColor: '#F8F9FA',

    },

    tableContainer: {
        marginTop: '40px',
    },
    mainWrapper: {
        paddingBottom: 32,
        marginTop: '20px'
    },
    MainContainers: {
        justifyContent: "space-between",
        alignItems: "center",
        margin: "40px 0 24px 0"
    },
    gridSection1: {
        display: 'flex',
        alignItems: 'center'
    },
    gridSection2: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    loadingText: {
        textAlign: "center" as "center",
        marginTop: 20,
    },
    tableBorder: {
        border: '1px solid #ECEDF0'
    },
    tablecellstyle: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0em',
        textAlign: "left" as "left",
        color: '#676B7E'
    },
    tbCellTdStyle: {
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        color: '#3C3E49'
    },
    tbCellTdCust: {
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '32px',
        color: '#6200EA',
        textDecoration: 'underline',
        textTransform: 'capitalize' as 'capitalize',
    },
    tableRowStyle: {
        height: '56',
        backgroundColor: '#FFFFFF',
        width: '1156px',
        border: '2px solid #ECEDEF',
    },
    tablethStyle: {
        height: '56',
        paddingBottom: '10px',
    },
    orderStatusCompleted: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: "#008243",
        backgroundColor: "#EBF5F0",
        border: "1px solid #B3DAC7",
    },
    orderStatusRefunded: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#653508',
        backgroundColor: "#FFF2E7",
        border: "1px solid #FFCA99",
    },
    orderStatusCancelled: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#E00000',
        backgroundColor: "#FFECEC",
        border: "1px solid #FFD7D7",
    },
    orderStatusInProgress: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#4F31E4',
        backgroundColor: "#F7F5FF",
        border: "1px solid #BCB1F5",
    },
    orderStatusConfirmed: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#8E24AA',
        backgroundColor: "#F3E5F5",
        border: "1px solid #BA68C8",

    },
    orderStatusPlaced: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#FAE52B',
        backgroundColor: "#FFFBD7",
        border: "1px solid #F4E875",
    },
    tdButtonStyle: {
        borderRadius: '32px',
        padding: '1px 7px 1px 7px',
        width: '90px',
        height: '22px',
        Gap: '10px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#4F31E4',
        backgroundColor: '#BCB1F5',
        boxShadow: 'none',
    },
    tbCellTdTotal: {
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        color: '#3C3E49',
    },
    titleCsvStyle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: "center" as "center",
        color: '#3C3E49',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    titleFirstText: {
        fontFamily: 'Rubik',
        fontSize: '28px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '-0.02em',
        textAlign: "left" as "left",
        color: '#3C3E49',
    },
    searchContainer: {
        padding: '4px'
    },

    filterText: {
        border: '1px solid #ccc',
        paddingRight: '8px',
        marginRight: '8px',
        width: '128px',
        height: '41px',
    },

    searchIcon: {
        borderRight: '1px solid #ccc',
        paddingRight: '8px',
    },
    filtericon: {
        marginLeft: '8px',
        color: '#676B7E',
    },
    searchIconStyle: {
        color: '#AFB2C0',
    },
    iconButtonStyle: {
        padding: '6px'
    },
    checkboxStyle: {
        width: '20px',
        height: '20px',
        borderRadius: '5px',
        border: '1px solid #757575',
        backgroundColor: '#FFFFFF',
        borderColor: '#757575',
        marginRight: '0.5rem',
    },
    checkBoxStylelabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#3C3E49'
    },
    applyBtn: {
        width: '101px',
        height: '40px',
        padding: '10px 16px',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left' as 'left',
        background: '#3C3E49',
        backgroundColor: '#00D659',
        color: '#3C3E49',
    },
    resetAllBtn: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#3C3E49',
        textDecoration: 'underline',
        textTransform: "none" as "none"
    },
    toValueStyle: {
        marginBottom: '0.5rem',
        width: '119px',
        height: '48px',
        top: '24px',
        borderRadius: '3px',
        border: '1px solid #D0D2DA',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#3C3E49',
        paddingLeft: '10px',
    },
    labeltoStyle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#000000'
    },
    fromLabelStyle: {
        marginBottom: '0.5rem',
        width: '119px',
        height: '48px',
        top: '24px',
        borderRadius: '3px',
        border: '1px solid #D0D2DA',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        color: '#3C3E49',
        paddingLeft: '10px',
    },
    OrderTextStyle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#3C3E49',
        width: '122px',
        height: '23px',
        top: '0.5px'
    },
    footerBtnSection:{
        marginTop: '2rem',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
        position:'absolute' as 'absolute', 
        right: '0px' as '0px',
        bottom: '0px' as '0px',
    },
    textFieldStyle: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        color: '#AFB2C0',
        width: '100%',
    },
    containerStyle: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0em',
        color: '#676B7E',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',    
    }
};

export const useStyles = makeStyles((theme: Theme) => ({
  
    formActions: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#3C3E49",
      padding: theme.spacing(1.25, 3)
    },
    formActionsWrapper: {
      top: 0,
      zIndex: 1,
      width: "100%",
      position: "fixed",   
    },
    logo: {
      width: "auto",
      objectFit: "none",      
    },
    saveChangesbutton: {
      fontSize: "16px",
      backgroundColor: "#00D659",
      lineHeight: "24px",
      fontWeight: 500,
      padding: theme.spacing(1.5, 5),
      textTransform: "none",
      color: "#3C3E49",

    },
    discardChangesbutton: {
      fontSize: "14px",
      textTransform: "none",
      lineHeight: "20px",
      color: "#FFFFFF",
      padding: theme.spacing(1.5, 3),
      textDecoration: "underline",
      
    },
 
    loadingResultsText: {
     textAlign: "center",
      fontSize: "18px",
      fontWeight: 500,
      color: "#3C3E49",
      lineHeight: "24px",  
      whiteSpace: "pre",
    },
    noServicesCard: {
      alignItems: "center",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      position: "absolute",
      padding: theme.spacing(3),
      width: "280px",
      background: "#FFFFFF",
      flexDirection: "column",
      justifyContent: "center",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    loadingResults: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    pleaseRefineYourSearch: {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#83889E",
      marginTop: theme.spacing(1),
    },
    notFoundImage: {
      width: "103px",
    },
    noResultsFound: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#000000",
      marginTop: theme.spacing(3),
    },
    noOrderCard: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      borderRadius: "8px",
      background: "#FFFFFF",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      top: "45%",
      left: "50%",
      alignItems: "center",
      justifyContent: "center",
      padding: 24
    },
    noOrderCardContent: {
      display: "flex",
      flexDirection: "column",        
      width: "280px",
      height: "180px",
      alignItems: "center",
      justifyContent: "center",
    },
    loaderContainer: {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 24,
      gap: 10
    },
}));
// Customizable Area End

