// Customizable Area Start
import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import {generateRequestMessage} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { clearStorageData, isTokenExpired } from "../../ss-cms-common-components/src/Utilities/Utilities";
import * as Yup from "yup";

const configJSON = require("./config");
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
  };

interface S {
  primaryColor: string;
  brandSettingId: string;
  appName: string;
  androidAppLink: string;
  iosAppLink: string;
  errors?: {
    iosAppLink?: string;
    androidAppLink?: string;
  }
}

interface SS {
  id: number;
}

const Schema = Yup.object().shape({
  androidAppLink: Yup.string().nullable().url(configJSON.validationURL),
  iosAppLink: Yup.string().nullable().url(configJSON.validationURL),
})

export class AppSettingsFormController extends BlockComponent<Props, S, SS> {
  brandSettingsGetMessageId: string = "";
  brandSettingsPostMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      primaryColor: "#000",
      brandSettingId: "",
      appName: "",
      androidAppLink: "",
      iosAppLink: "",
      errors: undefined
    };

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getBrandSettings();
  }

  getBrandSettings = async () => {
    mixpanel.track("webadmin_mobile_app_settings_page_enter")
    this.props.displaySpinner();
    const getBrandSettingsData = await generateRequestMessage(configJSON.appSettingApiEndpoint, configJSON.getApiMethodType);
    this.brandSettingsGetMessageId = getBrandSettingsData.messageId;
    runEngine.sendMessage(getBrandSettingsData.id, getBrandSettingsData);
  }

  validate = async () => {
    await Schema.validate({
      ...this.state
    }, { abortEarly: false })
    this.setState({ errors: undefined })
  }

  catchError = (error: any) => {
    if (error instanceof Yup.ValidationError) {
      const errors: any = {};
      error.inner.forEach((error) => {
        errors[error.path as string] = error.message;
      })
      this.setState({ errors })
    }
  }

  postBrandSettings = async () => {
    try {
      await this.validate();
      this.props.displaySpinner();
      const brandData = {
        primary_app_color: this.state.primaryColor,
        deeplink_name: this.state.appName,
        android_app_url: this.state.androidAppLink,
        ios_app_url: this.state.iosAppLink,
      }
      const postBrandSettingsData = await generateRequestMessage(configJSON.appSettingApiEndpoint, configJSON.putApiMethodType);
      postBrandSettingsData.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(brandData)
      );
      this.brandSettingsPostMessageId = postBrandSettingsData.messageId;
      runEngine.sendMessage(postBrandSettingsData.id, postBrandSettingsData);
      mixpanel.track("webadmin_mobile_app_settings_save_changes");
    }catch(error){
      this.catchError(error);
    }
  }

  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.brandSettingsGetMessageId === apiRequestCallId) {
        this.props.hideLoader();
        this.setState({
          brandSettingId: responseJson?.data?.id,
          primaryColor: responseJson?.data?.attributes?.primary_app_color,
          appName: responseJson?.data?.attributes?.deeplink_name,
          androidAppLink: responseJson?.data?.attributes?.android_app_url,
          iosAppLink: responseJson?.data?.attributes?.ios_app_url,
        })
      }

      if (this.brandSettingsPostMessageId === apiRequestCallId) {
        this.props.hideLoader();
        this.props.showHeaderBar({ type: "success" });
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.postBrandSettings();

      } else if (type === "DISCARDCHANGES") {
        this.getBrandSettings();

      }
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  openToastOnChange = () => {
    this.props.showHeaderBar({ message: "" });
    return true;
  };

  handleCustomPrimaryChange = (color: string) => {
    this.setState({
      primaryColor: color
    });
    this.openToastOnChange();
  };

  handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variableName: string,
    limit: number = 51
  ) => {
    if (event.target.value.length < limit) {
      this.setState({ [variableName]: event.target.value } as Omit<Pick<S, keyof S>, "errors">, async () => {
        try{
          await this.validate();
        } catch(error){
          this.catchError(error);
        }
      });
      this.openToastOnChange();
    } else {
      return false;
    }
    
  };
}
// Customizable Area End