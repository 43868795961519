// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  notificationWrapper: {
    top: 0,
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },
  notification: {
    display: "flex",
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3),
  },
  logo: {
    position: "absolute",
    width: "159px",
    objectFit: "none",
    height: "calc(100% - 20px)",
  },
  notificationMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3C3E49",
    height: "48px",
    flex: 1,
  },
  notificationIcon: {
    color: "transparent",
  },
  notificationText: {
    padding: theme.spacing(0, 1.25),
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  container: {
    padding: theme.spacing(5, 3),
  },
  noServicesCard: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    borderRadius: "8px",
    background: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    top: "45%",
    left: "50%",
    alignItems: "center",
    justifyContent: "center",
    padding: 24
  },
  noServicesCardContent: {
    display: "flex",
    flexDirection: "column",        
    width: "280px",
    height: "180px",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingResults: {
    display: "flex",
    alignItems: "center",
  },
  loadingResultsText: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    whiteSpace: "pre",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftHeaderWrapper: {
    display: "flex",
    gap: theme.spacing(2.25),
    alignItems: "flex-end",
  },
  title: {
    height: "45px",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    color: "#3C3E49",
  },
  deleteButton: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    textDecoration: "underline",
    color: "#FF1744",
  },
  paragraph: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
    margin: "20px 0",
  },
  link: {
    textDecoration: "none",
  },
  addServiceButtonNoServices: {
    textTransform: "none",
    borderRadius: "3px",
    padding: theme.spacing(1.5, 5),
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  addServiceButton: {
    textTransform: "none",
    borderRadius: "3px",
    padding: theme.spacing(1.25, 4),
    fontSize: "12px",
    lineHeight: "20px",
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  searchBar: {
    backgroundColor: "#FFFFFF",
  },
  searchInput: {
    backgroundColor: "#F9FAFB",
    borderColor: "#D0D2DA",
    padding: theme.spacing(1.75, 1.75, 1.75, 0),
    fontSize: "14px",
    lineHeight: "24px",
  },
  icon: {
    color: "#AFB2C0",
  },
  notFoundImage: {
    width: "103px",
  },
  noResultsFound: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    marginTop: theme.spacing(5),
  },
  pleaseRefineYourSearch: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#83889E",
    marginTop: theme.spacing(1),
  },
}));
// Customizable Area End