// Customizable Area Start
import React, { useState } from "react";
import {
  Box,
  CardMedia,
  Card,
  List,
  ListItem,
  CardContent,
  Breadcrumbs,
  useMediaQuery,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import {
  NavigateNext as NavigateNextIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Catalogues as useStyles } from "./styles/Catalogues.web";
import { Link } from "react-router-dom";
import { Service } from "../../utilities/src/models/Service";
import FilterOptions from "./FilterOptions.web";
import theme from "../../utilities/src/theme";
import FilterItems from "./FilterItems.web";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import Currency from "../../utilities/src/components/Currency.web";
import { IconNotFound, IconFilter } from "./assets"
import { PaginationType } from "../../ordermanagement/src/types";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
const mixpanel = require('mixpanel-browser');
import { CatObj } from "./CataloguesController.web";
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");
const configJSON = require("./config");

interface Props {
  filterOptions: CatObj[];
  cataloguesData: Service[];
  tempSelectedCategories: number[];
  selectCategory: (identifier: number, updateCategories: boolean) => void;
  resetCategoriesSelected: () => void;
  applyCategoriesSelected: () => void;
  discardCategoriesSelected: () => void;
  isLoading: boolean;
  searchQuery: string;
  deleteChip: () => void;
  pagination?: PaginationType;
  handlePageChange: (page: number) => void;
}

const Catalogues: React.FC<Props> = ({
  filterOptions,
  cataloguesData,
  tempSelectedCategories,
  selectCategory,
  resetCategoriesSelected,
  applyCategoriesSelected,
  discardCategoriesSelected,
  isLoading,
  searchQuery,
  deleteChip,
  pagination,
  handlePageChange
}) => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };
  
  return (
    <>
      <Box className={classes.breadcrumbsWrapper}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link to="/" className={classes.breadcrumbLink}>
            <Typography>{configJSON.textHome}</Typography>
          </Link>
          <Typography className={classes.breadcrumbText}>{configJSON.textServices}</Typography>
        </Breadcrumbs>

        {isSmallScreen && (
          <Box className={classes.filterButton} onClick={toggleFilterDrawer}>
            <CardMedia
              component="img"
              image={IconFilter}
              className={classes.filterIcon}
            />
            <Typography className={classes.filterButtonText}>{configJSON.textFilter}</Typography>
          </Box>
        )}
      </Box>

      <FilterItems
        options={filterOptions}
        isDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleFilterDrawer}
        tempSelectedCategories={tempSelectedCategories}
        resetCategoriesSelected={resetCategoriesSelected}
        applyCategoriesSelected={applyCategoriesSelected}
        discardCategoriesSelected={discardCategoriesSelected}
        selectCategory={selectCategory}
      />

      <Box className={classes.container}>
        {!isSmallScreen && (
          <Box className={classes.filter}>
            <FilterOptions
              options={filterOptions}
              tempSelectedCategories={tempSelectedCategories}
              selectCategory={selectCategory}
            />
          </Box>
        )}

        <Box className={classes.resultsWrapper}>
          {searchQuery && (
            <Chip
              label={searchQuery}
              variant="outlined"
              className={classes.chip}
              onDelete={deleteChip}
              deleteIcon={<CloseIcon className={classes.deleteChipIcon} />}
            />
          )}

          {isLoading && (
            <Card className={classes.noServicesCard}>
              <Box className={classes.loadingResults}>
                <Typography className={classes.loadingResultsText}>
                  {configJSON.textLoading}
                </Typography>
                <span>
                  <CircularProgress />
                </span>
              </Box>
            </Card>
          )}

          {!isLoading && cataloguesData.length > 0 && (
            <Box className={classes.listWrapper}>
              <List className={classes.list}>
                {cataloguesData.map((catalogue, index) => (
                  <ListItem key={index} className={classes.item}>
                    <Link
                      className={classes.link}
                      to={`/Service/${catalogue.id}`}
                      onClick={() => mixpanel.track("webcustomer_services_select_item", {itemId: catalogue.id})}
                    >
                      <Card className={classes.card}>
                        <ImageCard
                          imageClassName={classes.image}
                          image={
                            catalogue.images &&
                            catalogue.images[0] &&
                            (catalogue.images[0] as ServiceImage).url
                          }
                        />
                        <CardContent className={classes.content}>
                          <Typography className={classes.name}>
                            {catalogue.title}
                          </Typography>
                          <Typography className={classes.brief}>
                            <Currency text={`${catalogue.discountedPrice.toFixed(2)}  |  ${
                              catalogue.duration
                            } ${configJSON.textMins}`}/>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {!!pagination?.total_count && <Box marginBottom={4}><Pagination
          count={pagination?.total_count}
          onChange={handlePageChange}
          pageSize={pagination?.per_page}
          itemText={configJSON.paginationText}
          pageNo={pagination?.current_page || 1}
        /></Box>}

          {!isLoading && cataloguesData.length === 0 && (
            <Card className={classes.noServicesCard}>
              <CardMedia
                component="img"
                image={IconNotFound}
                className={classes.notFoundImage}
              />
              <Typography className={classes.noResultsFound}>
                {configJSON.textNoResultsFound}
              </Typography>
              <Typography className={classes.pleaseRefineYourSearch}>
                {configJSON.textNoResultHint}
              </Typography>
            </Card>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Catalogues;
// Customizable Area End
