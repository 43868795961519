Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PhotoLibrary";
exports.labelBodyText = "PhotoLibrary Body";

exports.errorMessage = "Something went wrong!";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.updateApiMethod = "PATCH";

exports.photoLibraryApiEndpoint = "bx_block_gallery/galleries";
exports.photoDeleteApiEndpoint =  "/bx_block_gallery/galleries/destroy_gallery"
exports.photoLibraryUpdateApiEndpoint = "/bx_block_gallery/galleries/update_gallery"


exports.textDelete = "Delete";
exports.textDeleteSelected = "Delete Selected";
exports.textDeleteGallery = "Delete Gallery";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textSelectImage = "Select Image";
exports.textAddNewImage = "Add New Image";
exports.textShowImages = "Show Images";
exports.noImageErrorMsg = "Please select an image";
exports.textShare = "Share";
exports.textAccountId = "Account ID";
exports.errorMsgAccountId = "Account ID cannot be empty";
exports.paginationText = "Gallery"
// Customizable Area End