import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, { getName } from "../../ss-cms-common-components/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"

// Customizable Area Start
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withLoader.Web"
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { generateRequestMessage, isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities"
import { WithStyles } from "@material-ui/core";
import { styles } from "./AdminHome.web";
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");
// Customizable Area End

export const configJSON = require("./config")
// Customizable Area Start
export type Props = DialogProps &
  withHeadeActionBarProps &
  withLoaderProps & WithStyles<typeof styles> & {
    id: string
  }
// Customizable Area End

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string
  // Customizable Area End
}

export default class AccountDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]
    this.state = {
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getDummyRequestForToken();
    mixpanel.track("webadmin_home_page_enter");
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  getDummyRequestForToken = async () => {
    const requestMessage = await generateRequestMessage(
      configJSON.dummyRequestEndpoint,
      configJSON.httpGetMethod
    );
    this.send(requestMessage);
  }
  // Customizable Area End
}
