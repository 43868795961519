// Customizable Area Start
import React from "react";
import { Box, Button, Breadcrumbs } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Catalogue as useStyles } from "./styles/Catalogue.web";
import { Service } from "../../utilities/src/models/Service";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import Currency from "../../utilities/src/components/Currency.web";

const configJSON = require("./config")
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

interface Props {
  currentCatalogue: Service | null;
  selectedImageIndex: number;
  previewImage: (index: number) => void;
}

const Catalogue: React.FC<Props> = ({
  currentCatalogue,
  selectedImageIndex,
  previewImage,
}) => {
  const classes = useStyles();

  return (
    <>
      {currentCatalogue && (
        <>
          <Box className={classes.breadcrumbsWrapper}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link to="/" className={classes.breadcrumbLink}>
                <Typography>{configJSON.textHome}</Typography>
              </Link>
              <Link to="/Services" className={classes.breadcrumbLink}>
                <Typography>{configJSON.textServices}</Typography>
              </Link>
              <Typography className={classes.breadcrumbNonLink}>
                {currentCatalogue.title}
              </Typography>
            </Breadcrumbs>
          </Box>

          <Box className={classes.currentCatalogueWrapper}>
            <Box className={classes.imagesWrapper}>
              <Box className={classes.largeImageWrapper}>
                <ImageCard
                  imageClassName={classes.largeImage}
                  image={
                    currentCatalogue.images &&
                    currentCatalogue.images[selectedImageIndex] &&
                    (currentCatalogue.images[
                      selectedImageIndex
                    ] as ServiceImage).url
                  }
                />
              </Box>

              <Box className={classes.smallImagesWrapper}>
                {(currentCatalogue.images as ServiceImage[]).map(
                  (image, index) => (
                    <ImageCard
                      key={index}
                      imageClassName={classes.smallImage}
                      image={image.url}
                      onClick={() => previewImage(index)}
                    />
                  )
                )}
              </Box>
            </Box>

            <Box className={classes.details}>
              <Box>
                <Typography className={classes.infoTitle}>
                  {`${currentCatalogue.category.name}`}
                </Typography>
                <Typography
                  className={classes.catalogueName}
                >{`${currentCatalogue.title}`}</Typography>
              </Box>

              <Box>
                <Typography className={classes.infoTitle}>{configJSON.textDuration}</Typography>
                <Typography className={classes.info}>
                  {`${currentCatalogue.duration} ${configJSON.textMins}`}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.infoTitle}>{configJSON.textPrice}</Typography>
                <Typography className={classes.info}>
                <Currency text={`${currentCatalogue.discountedPrice.toFixed(2)}`}/>
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.infoTitle}>
                  {configJSON.textDescription}
                </Typography>
                <Typography className={classes.info}>
                  {currentCatalogue.description}
                </Typography>
              </Box>

              <Link
                to={`/Appointments/${currentCatalogue.id}`}
                onClick={()=>mixpanel.track("webcustomer_service_detail_book_now_button", {itemId: currentCatalogue.id })}
                className={classes.link}
              >
                <Typography className={classes.bookNowButton}>
                  {configJSON.textBookNow}
                </Typography>
              </Link>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Catalogue;
// Customizable Area End
