// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
const configJSON = require("./config");
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {generateRequestMessage, showError} from "../../ss-cms-common-components/src/Utilities/Utilities";
import {WithStyles} from "@material-ui/core";

const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export interface PaymentMethod {
  id: string,
  type: string,
  attributes: {
    id: number,
    payment_method: string,
    active: boolean
  }
}

export interface PaymentMethodError {
  errors: Array<{message: string}>
}

export type Props = DialogProps & WithStyles & {
  showHeader: (obj?: object) => void;
  showLoader:() => void;
  hideLoader: () => void;
  setDialogState: () => void;
  paymentMethods: PaymentMethod[];
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => void;
};

interface S {
  method: string;
}

interface SS {
  id: number;
}

class PaymentMethodsController extends BlockComponent<Props, S, SS> {
  getPaymentMethodsMessageId: string = "";
  updatePaymentMethodMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster)
    ];
    this.state = {
      method: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    mixpanel.track("webadmin_payment_page_enter");
  }

  

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleApiError(message);
      this.handleApiMessages(message);
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(getName(MessageEnum.ActionMessageFromToasterMessage));
      this.handleToasterResponse(type);
    }
  };

  handleToasterResponse = (type: string) => {
    if (type === "SAVECHANGES") {
      if (this.props.paymentMethods.every((method) => method.attributes.active === false)) {
        this.props.setDialogState();
      } else {
        this.props.paymentMethods.forEach((method) => {
          this.updatePaymentMethod(method.attributes.id);
        });
      }
    }
  };

  handleApiMessages = (message: Message) => {
    const [responseJson,apiRequestCallId] = getResponseVariables(message);
    if (this.updatePaymentMethodMessageId === apiRequestCallId) {
      this.props.showHeader({ type: 'success'})
      this.props.hideLoader();
    }
  };

  handleApiError = (message: Message) => {
    const [responseJson, apiRequestCallId] =  getResponseVariables(message);
    if (this.updatePaymentMethodMessageId === apiRequestCallId) {
      if (responseJson?.errors) {
        showError(configJSON.errorOnSave, responseJson, this.props.hideLoader(), this.props.setDialogState);
      }
    }
  }

  updatePaymentMethod = async (id: number) => {
    const requestMessage = await generateRequestMessage(`${configJSON.paymentsAdminURL}/${id}`, configJSON.putMethod);
    const methodData = this.props.paymentMethods.find((method) => method.attributes.id === id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({
      id: methodData?.id,
      active: methodData?.attributes.active,
    }));
    this.updatePaymentMethodMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  handlePaymentMethodChange = (paymentMethod: PaymentMethod) => {
    paymentMethod.attributes.active = !paymentMethod.attributes.active;
    this.props.setPaymentMethods([...this.props.paymentMethods]);
    this.props.showHeader();
  };
}

export default PaymentMethodsController;
// Customizable Area End
