// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
import { Navigation } from "../../utilities/src/models/Navigation";

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

interface S {
  service: Service | null;
  selectedImageIndex: number;
}

interface SS {
  identifier: string;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getCatalogueApiCallId: string | undefined;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      service: null,
      selectedImageIndex: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCatalgoue();
  }

  receive = async (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        responseJson.data.attributes.status == false && this.props.navigation.navigate("Services");
        this.handleGetCatalgoueResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  handleGetCatalgoueResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson.data;

    this.setState({
      service: parseCatalogue(response),
    });
  };

  getCatalgoue = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const catalogueId = this.props.navigation.getParam("id");


    this.getCatalogueApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.getCataloguesEndPoint}/${catalogueId}`,
      header,
    });
  };

  previewImage = (index: number) => {
    this.setState({
      selectedImageIndex: index,
    });
  };
}
// Customizable Area End
