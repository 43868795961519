import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { ChangeEvent } from "react";
// Customizable Area Start
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import moment from "moment";
import { PaginationType } from "../../ordermanagement/src/types";

export interface ContactUsArray {
  id: string;
  type: string;
  attributes: {
    name: string;
    email: string;
    subject: string;
    phone_number: string;
    description: string;
    created_at: string;
    country_code: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Navigation {
  navigate: (to: string, params?: Object) => void;
  getParam: (
    param: string,
    alternative?: string
  ) =>
    | string
    | number
    | Object
    | Object[]
    | null
    | undefined
    | Record<string, string | number | Object | Object[] | null | undefined>;
  goBack: () => void;
  addListener: (event: string, callback: () => void) => void;
}

export interface Props {
  navigation: Navigation;
  identifier: string;
  // Customizable Area Start
  classes: {
    select: string,
    phoneBox: string,
  };
  // Customizable Area End
}

export interface Values {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  subject: string;
  message: string;
  countryCode: string;
  // Customizable Area End
}

export interface CustomContact {
  // Customizable Area Start
  data: Array<ContactUsArray>,
  metadata: {
    meta: {
      pagination: {
        current_page: number;
        next_page: number;
        prev_page: null;
        total_pages: number;
        total_count: number;
        current_count: number;
        per_page: number;
      }
    }
  }
  // Customizable Area End
}

interface CustomError {
  // Customizable Area Start
  contact?: string[];
  // Customizable Area End
}


export interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  message: string;
  subject: string;
  enableField: boolean;
  token: string;
  contactUsList: Array<ContactUsArray>;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  errors: Partial<Values>;
  selectedRow: number | null;
  openDialog: boolean;
  showSuccessMessageDialog: boolean;
  loading: boolean;
  countryCode: string,
  deletedRow: string[];
  showDeleteDialog: boolean;
  showSuccessMessage: boolean;
  pagination?: PaginationType;
  // Customizable Area End
}

interface SS {
  identifier: string;
}

export default class ContactController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactApiCallId: string | undefined;
  deleteContactApiCallId: string | undefined;
  addContactApiCallId: string | undefined;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      message: "",
      subject: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      errors: {},
      selectedRow: null,
      openDialog: false,
      showSuccessMessageDialog: false,
      loading: true,
      countryCode: "+91",
      deletedRow: [],
      showDeleteDialog: false,
      showSuccessMessage: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }

    const messageID = message.id;
    const data = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    runEngine.debugLog("API Message Received", message);

    if (messageID === getName(MessageEnum.SessionResponseMessage)) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList();
    } else if (messageID === getName(MessageEnum.RestAPIResponceMessage)) {
      if (apiRequestCallId === this.contactApiCallId) {
        const contactData = data;
        this.handleContactAPIResponse(contactData);
      } else if (apiRequestCallId === this.addContactApiCallId) {
        this.handleAddContactAPIResponse()
      } else if (apiRequestCallId === this.deleteContactApiCallId) {
        this.setState({ loading: false });
        if (data?.message) { this.handleDeleteContactAPIResponse(); }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleContactAPIResponse(data: CustomContact) {
    this.setState({ contactUsList: data.data, loading: false, pagination: data.metadata?.meta?.pagination });
  }

  handleAddContactAPIResponse() {
    this.setState({
      showSuccessMessageDialog: true,
      name: "",
      email: "",
      phoneNumber: "",
      countryCode: "+91",
      subject: "",
      message: "",
      loading: false,
    });
  }

  handleDeleteContactAPIResponse() {
    this.setState({ showSuccessMessage: true, isVisible: false });
    this.getContactUsList();
    setTimeout(() => {
      this.setState({ showSuccessMessage: false });
    }, 4000);
  }

  handleVisibilityClick = (index: number) => {
    this.setState((prevState) => ({
      openDialog: !prevState.openDialog,
      selectedRow: index,
    }));
  };

  handleDeleteClick = (selectedIds: string[]) => {
    this.setState((prevState) => ({
      showDeleteDialog: !prevState.showDeleteDialog,
      deletedRow: selectedIds,
    }));
  };

  handleDeleteDialog = (queryId: string) => {
    this.setState((prevState) => ({
      deletedRow: prevState.deletedRow.includes(queryId)
        ? prevState.deletedRow.filter(item => item !== queryId)
        : [...prevState.deletedRow, queryId],
    }));
  };


  handleDeleteDialogClose = () => {
    this.setState({
      showDeleteDialog: false,
      deletedRow: [],
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      selectedRow: null,
    });
  };


  handleConfirmDelete = async () => {
    const idsToDelete = this.state.deletedRow;
    if (idsToDelete.length > 0) {
      const newContactUsList = this.state.contactUsList.filter(
        (item: { id: string }) => !idsToDelete.includes(item.id)
      );

      this.setState({
        deletedRow: [],
        contactUsList: newContactUsList,
        showDeleteDialog: false,
      });
      this.deleteMultipleContactQueries(idsToDelete);
    }
  };

  handleSelectAllFields = () => {
    this.setState((prevState) => {
      const allRowsSelected = prevState.deletedRow.length === prevState.contactUsList.length;
      const updatedDeletedRow = allRowsSelected ? [] : prevState.contactUsList.map((item: { id: string }) => item.id);

      return {
        deletedRow: prevState.deletedRow.length > 0 ? [] : updatedDeletedRow,
      };
    });
  };

  addQueryApi = () => {
    let data = {
      data: {
        name: this.state.name.trim(),
        email: this.state.email.trim(),
        phone_number: this.state.phoneNumber.trim(),
        country_code: this.state.countryCode.trim(),
        subject: this.state.subject.trim(),
        description: this.state.message.trim()
      },
    };
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteMultipleContactQueries = async (queryIds: string[]) => {
    const idsQueryParam = queryIds.map(queryId => `[ids][]=${queryId}`).join("&");
    const requestMessage = await generateRequestMessage(
      configJSON.getContactUsAPiEndPoint + `/destroy_contact?${idsQueryParam}`,
      configJSON.httpDeleteMethod
    )
    this.deleteContactApiCallId = requestMessage.messageId;
    this.setState({ loading: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = async (page = 1) => {
    const requestMessage = await generateRequestMessage(
      configJSON.getContactUsAPiEndPoint + "?page=" + page,
      configJSON.httpGetMethod
    )
    this.contactApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  hideDialog = () => this.setState({ showSuccessMessageDialog: false });

  formatDate = (inputDate: string): string => {
    return moment.utc(inputDate).format("LLL")
  }

  ContactSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(4, "Name must be minimum 4 characters")
      .max(50, "Name must be maximum 50 characters")
      .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
    email: Yup.string().required("Email is required.").email("Please enter valid email"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(7, "Please enter valid phone number")
      .max(10, "Please enter valid phone number"),
    countryCode: Yup.string(),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string()
      .required("Message is required")
      .min(8, "Message must be minimum 8 characters")
  });

  handleChange = (field: keyof Values) => (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    const { value } = event.target;

    try {
      const fieldValues: Partial<Values> = {
        [field]: value,
      };
      this.ContactSchema.validateSyncAt(field, fieldValues as Values);
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: { ...prevState.errors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessage = error?.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          errors: { ...prevState.errors, [field]: errorMessage },
        }));
      }
    }
  };

  handleSubmit = () => {
    const { ...prevState } = this.state;
    let formSubmit = true;

    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<Values> = {
          [field]: prevState[field as keyof Values],
        };
        this.ContactSchema.validateSyncAt(field, fieldValues as Values);
        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: "" },
        }));
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errorMessage = error.message;
          formSubmit = false;
          this.setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, [field]: errorMessage },
          }));
        }
      }
    });

    if (formSubmit) {
      this.addQueryApi();
    }
  };

  handlePageChange = (page: number) => {
    this.getContactUsList(page);
  }
  // Customizable Area End
}
