// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  TextField,
  IconButton,
  SvgIcon,
  Collapse,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles/Itemavailability.web";
import {
  Close as CloseIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@material-ui/icons";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { Availability } from "../../utilities/src/models/Availability";
import { ReactComponent as CheckboxIcon } from "../assets/Checkbox.svg";
import { ReactComponent as CheckboxIconChecked } from "../assets/Checkbox-checked.svg";
import { ReactComponent as ErrorIcon } from "../assets/Error-cross-circle.svg";
import moment from "moment"

const availabilitySchema = Yup.array().of(
  Yup.object().shape({
    day: Yup.string(),
    workingHours: Yup.array().of(
      Yup.object().shape({
        openingTime: Yup.string()
          .test("required", "Opening time is required", function () {
            const { from } = this;
            if (from && from.length) {
              if (from[1].value.selected) {
                return !!from[0].value.openingTime;
              } else {
                return (
                  !from[0].value.closingTime || !!from[0].value.openingTime
                );
              }
            }
          })
          .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^$/,
            "Opening time must be in 12-hour format"
          ),
        closingTime: Yup.string()
          .test("required", "Closing time is required", function () {
            const { from } = this;
            if (from && from.length) {
              if (from[1].value.selected) {
                return !!from[0].value.closingTime;
              } else {
                return (
                  !from[0].value.openingTime || !!from[0].value.closingTime
                );
              }
            }
          })
          .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^$/,
            "Closing time must be in 12-hour format"
          )
          .when(
            "openingTime",
            ([openingTime], schema: any) =>
              openingTime &&
              schema.test({
                test: (closingTime: string) => {
                  if (closingTime) {
                    const openingDateTime = moment(`${moment().format("DD/MM/yyyy")} ${openingTime}`, "DD/MM/yyyy h:mm a").unix()
                    const closingDateTime = moment(`${moment().format("DD/MM/yyyy")} ${closingTime}`, "DD/MM/yyyy h:mm a").unix()
                    return closingDateTime > openingDateTime;
                  }
                },
                message: "Closing time should be after Opening time",
              })
          ),
      })
    ),
    selected: Yup.bool(),
  })
);

interface Props {
  availability: Availability[];
  availabilityErrors: string[];
  checkAvailabilityForm: (confirmedAvailability: Availability[]) => void;
  closeModal: () => void;
}

const Itemavailability: React.FC<Props> = ({
  availability,
  availabilityErrors,
  checkAvailabilityForm,
  closeModal,
}) => {
  const classes = useStyles();

  const initialValues = availability;

  const formik = useFormik({
    initialValues,
    validationSchema: availabilitySchema,
    onSubmit: checkAvailabilityForm,
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    setFieldTouched,
    setValues,
    touched,
    values,
  } = formik;

  const removeWorkingHour = (dayIndex: number, workingHourIndex: number) => {
    setValues((currentAvailability) =>
      currentAvailability.map((availability, index) => {
        if (index === dayIndex) {
          const newWorkingHours = availability.workingHours.filter(
            (_, index) => index !== workingHourIndex
          );

          const fieldPath = `[${dayIndex}].workingHours[${workingHourIndex}]`;
          setFieldTouched(`${fieldPath}.openingTime`, false);
          setFieldTouched(`${fieldPath}.closingTime`, false);

          return {
            ...availability,
            workingHours: newWorkingHours,
          };
        }
        return availability;
      })
    );
  };

  const addWorkingHour = (dayIndex: number) => {
    setValues((currentAvailability) =>
      currentAvailability.map((availability, index) => {
        if (index === dayIndex) {
          const newWorkingHours = [
            ...availability.workingHours,
            {
              openingTime: "",
              closingTime: "",
            },
          ];

          const fieldPath = `[${dayIndex}].workingHours[${availability.workingHours.length}]`;
          setFieldTouched(`${fieldPath}.openingTime`, false);
          setFieldTouched(`${fieldPath}.closingTime`, false);

          return {
            ...availability,
            workingHours: newWorkingHours,
          };
        }
        return availability;
      })
    );
  };

  return (
    <Box className={classes.backdrop}>
      <form onSubmit={handleSubmit} className={classes.container}>
        <Collapse in={!!availabilityErrors.length} mountOnEnter unmountOnExit>
          <Box className={classes.errorWrapper}>
            <Box className={classes.error}>
              {availabilityErrors.length > 0 && (
                <>
                  <SvgIcon
                    component={ErrorIcon}
                    className={classes.errorIcon}
                  />
                  <Box className={classes.errorMessages}>
                    {availabilityErrors.map((error, index) => (
                      <Typography key={index} className={classes.errorText}>
                        {error}
                      </Typography>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Collapse>

        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography className={classes.title}>
              Service availability
            </Typography>

            <IconButton className={classes.iconWrapper} onClick={closeModal}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>

          <Box className={classes.content}>
            <Box className={classes.subheaders}>
              <Typography className={classes.subtitle}>Day</Typography>
              <Typography className={classes.subtitle}>
                Working hours
              </Typography>
            </Box>

            <List className={classes.list}>
              {values.map(({ day, workingHours, selected }, index) => (
                <ListItem
                  key={index}
                  data-testid={`${day}-availability`}
                  disableGutters
                  className={classes.listItem}
                >
                  <Box className={classes.dayWrapper}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={selected}
                      {...getFieldProps(`[${index}].selected`)}
                      icon={
                        <SvgIcon component={CheckboxIcon} viewBox="0 0 20 20" />
                      }
                      checkedIcon={
                        <SvgIcon
                          component={CheckboxIconChecked}
                          viewBox="0 0 20 20"
                        />
                      }
                    />
                    <Typography className={classes.day}>{day}</Typography>
                  </Box>
                  <List>
                    {workingHours.map((_, hourIndex) => (
                      <ListItem
                        key={`${index}-${hourIndex}`}
                        disableGutters
                        className={classes.workingRange}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="hh:mm AM/PM"
                          inputProps={{ className: classes.input }}
                          className={classes.field}
                          disabled={!selected}
                          {...getFieldProps(
                            `[${index}].workingHours[${hourIndex}].openingTime`
                          )}
                          error={Boolean(
                            touched &&
                              touched[index] &&
                              touched[index]!.workingHours &&
                              touched[index]!.workingHours![hourIndex] &&
                              (
                                touched[index]!.workingHours![
                                  hourIndex
                                ] as FormikErrors<
                                  (typeof workingHours)[typeof hourIndex]
                                >
                              ).openingTime &&
                              errors &&
                              errors[index] &&
                              errors[index]!.workingHours &&
                              errors[index]!.workingHours![hourIndex] &&
                              (
                                errors[index]!.workingHours![
                                  hourIndex
                                ] as FormikErrors<
                                  (typeof workingHours)[typeof hourIndex]
                                >
                              ).openingTime
                          )}
                          helperText={
                            touched &&
                            touched[index] &&
                            touched[index]!.workingHours &&
                            touched[index]!.workingHours![hourIndex] &&
                            (
                              touched[index]!.workingHours![
                                hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).openingTime &&
                            errors &&
                            errors[index] &&
                            errors[index]!.workingHours &&
                            errors[index]!.workingHours![hourIndex] &&
                            (
                              errors[index]!.workingHours![
                                hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).openingTime
                          }
                        />
                        <Typography className={classes.separator}>-</Typography>
                        <TextField
                          variant="outlined"
                          placeholder="hh:mm AM/PM"
                          inputProps={{ className: classes.input }}
                          className={classes.field}
                          disabled={!selected}
                          {...getFieldProps(
                            `[${index}].workingHours[${hourIndex}].closingTime`
                          )}
                          error={Boolean(
                            touched &&
                              touched[index] &&
                              touched[index]!.workingHours &&
                              touched[index]!.workingHours![hourIndex] &&
                              (
                                touched[index]!.workingHours![
                                  hourIndex
                                ] as FormikErrors<
                                  (typeof workingHours)[typeof hourIndex]
                                >
                              ).closingTime &&
                              errors &&
                              errors[index] &&
                              errors[index]!.workingHours &&
                              errors[index]!.workingHours![hourIndex] &&
                              (
                                errors[index]!.workingHours![
                                  hourIndex
                                ] as FormikErrors<
                                  (typeof workingHours)[typeof hourIndex]
                                >
                              ).closingTime
                          )}
                          helperText={
                            touched &&
                            touched[index] &&
                            touched[index]!.workingHours &&
                            touched[index]!.workingHours![hourIndex] &&
                            (
                              touched[index]!.workingHours![
                                hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).closingTime &&
                            errors &&
                            errors[index] &&
                            errors[index]!.workingHours &&
                            errors[index]!.workingHours![hourIndex] &&
                            (
                              errors[index]!.workingHours![
                                hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).closingTime
                          }
                        />
                        {hourIndex > 0 ? (
                          <IconButton
                            className={`${classes.iconWrapper} ${classes.availabilityIcon}`}
                            disabled={!selected}
                            onClick={() => removeWorkingHour(index, hourIndex)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            className={`${classes.iconWrapper} ${classes.availabilityIcon}`}
                            disabled={!selected}
                            onClick={() => addWorkingHour(index)}
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        <Box className={classes.actions}>
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            className={classes.saveButton}
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Itemavailability;
// Customizable Area End