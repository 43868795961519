// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
import { Navigation } from "../../utilities/src/models/Navigation";
import { PaginationType } from "../../ordermanagement/src/types";

export const configJSON = require("./config");
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

export interface PaginationInterface {
  data: [
    {
      id: string,
      type: string,
      attributes: {
        title: string,
        description: string,
        duration: number,
        status: boolean,
        discount_option: boolean,
        discount: null,
        payment_preferences: string,
        price: number,
        actual_price: number,
        category: {
          id: number,
          name: string
        },
        currency: {
          id: number,
          name: string,
          symbol: string
        },
        images: [
          {
            id: number,
            url: string
          }
        ]
      }
    }
  ],
  metadata: {
    meta: {
      pagination: {
        current_page: number,
        next_page: number,
        prev_page: null,
        total_pages: number,
        total_count: number,
        current_count: number,
        per_page: number
      }
    }
  }
}
export interface CatObj {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    count: number;
    image_url: string;
  }
}
export interface Catagoury {
  data: Array<CatObj>;
  metadata: {
    meta: {
      pagination: {
        current_page: number;
        next_page: number;
        prev_page: null;
        total_pages: number;
        total_count: number;
        current_count: number;
        per_page: number;
      }
    }
  }
}

interface S {
  services: Service[];
  categories: CatObj[];
  selectedCategories: number[];
  tempSelectedCategories: number[];
  loading: boolean;
  searchQuery: string;
  pagination?: PaginationType;
}

interface SS {
  identifier: string;
}

export default class CataloguesController extends BlockComponent<Props, S, SS> {
  getAllCataloguesApiCallId: string | undefined;
  getAllCategoriesApiCallId: string | undefined;
  searchCataloguesApiCallId: string | undefined;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      services: [],
      categories: [],
      selectedCategories: [],
      tempSelectedCategories: [],
      loading: true,
      searchQuery: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    const searchQuery = this.props.navigation.getParam("searchQuery");
    if (searchQuery) {
      this.searchServices(searchQuery as string);
    } else {
      this.getAllCatalogues();
    }
    this.getAllCategories();
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevProps.navigation.getParam("searchQuery") !==
      this.props.navigation.getParam("searchQuery")
    ) {
      const searchQuery = this.props.navigation.getParam("searchQuery");
      if (searchQuery) {
        this.searchServices(searchQuery as string);
      } else {
        this.getAllCatalogues();
      }
    }
  }

  receive = async (from: string, message: Message) => {
    if (
      (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getAllCataloguesApiCallId != null &&
        this.getAllCataloguesApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) ||
      (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.searchCataloguesApiCallId != null &&
        this.searchCataloguesApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetAllCatalogues(responseJson);
      } else {
        let responseMeta = responseJson.metadata?.meta?.pagination;
        this.setState({ services: [], loading: false, pagination: responseMeta });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCategoriesApiCallId != null &&
      this.getAllCategoriesApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetAllCategories(responseJson);
      } else {
        this.setState({ categories: [] });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  handleGetAllCatalogues = (
    responseJson: PaginationInterface
  ) => {
    const services: Service[] = (responseJson.data as Object[]).map(
      (response) => parseCatalogue(response)
    );

    this.setState({
      services,
      pagination: responseJson.metadata?.meta?.pagination
    });
    this.setState({ loading: false });
  };

  handleGetAllCategories = (
    responseJson: Catagoury
  ) => {

    this.setState({
      categories: responseJson.data
    });
  };

  getAllCatalogues = (page = 1) => {
    this.setState({ loading: true, searchQuery: "" });
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    let urlParams = this.state.selectedCategories.length
      ? `category_ids=${this.state.selectedCategories.join(",")}`
      : null;

    this.getAllCataloguesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${urlParams
        ? configJSON.getCataloguesEndPoint + "?" + urlParams + "&page=" + page + "&per_page=12"
        : configJSON.getCataloguesEndPoint + "?page=" + page + "&per_page=12"
        }`,
      header,
    });
  };

  getAllCategories = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getAllCategoriesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getCategoriesEndPoint + "?show_all=" + true,
      header,
    });
  };

  selectCategory = (identifier: number, updateCategories: boolean) => {
    mixpanel.track("webcustomer_select_category")
    this.setState((prevState) => ({
      tempSelectedCategories: prevState.tempSelectedCategories.includes(
        identifier
      )
        ? prevState.tempSelectedCategories.filter(
          (selectedCategory) => selectedCategory !== identifier
        )
        : [...prevState.tempSelectedCategories, identifier],
    }));

    updateCategories &&
      this.setState((prevState) => ({
        selectedCategories: prevState.selectedCategories.includes(identifier)
          ? prevState.selectedCategories.filter(
            (selectedCategory) => selectedCategory !== identifier
          )
          : [...prevState.selectedCategories, identifier],
      }), this.getAllCatalogues);
  };

  resetCategoriesSelected = () => {
    this.setState({ tempSelectedCategories: [] });
  };

  discardCategoriesSelected = () => {
    this.setState((prevState) => ({
      tempSelectedCategories: [...prevState.selectedCategories],
    }));
  };

  applyCategoriesSelected = () => {
    this.setState((prevState) => ({
      selectedCategories: [...prevState.tempSelectedCategories],
    }), () => {
      this.getAllCatalogues();
    });
  };

  searchServices = (searchQuery: string, page = 1) => {
    this.setState({ searchQuery, selectedCategories: [], tempSelectedCategories: [] });
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const attrs = {
      query: searchQuery,
      status: "active",
    };
    const queryParams = new URLSearchParams(attrs).toString();

    this.searchCataloguesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.searchCataloguesEndPoint}?${queryParams}&page=${page}&per_page=${12}`,
      header,
    });
  };

  deleteChip = () => {
    const searchQueryMessage = new Message(
      getName(MessageEnum.SearchQueryMessage)
    );

    searchQueryMessage.addData(getName(MessageEnum.SearchQueryMessage), " ");

    runEngine.sendMessage(searchQueryMessage.id, searchQueryMessage);

    this.props.navigation.navigate("Services");
  };

  handlePageChange = (page: number) => {
    const searchQuery = this.props.navigation.getParam("searchQuery");
    if (searchQuery) {
      this.searchServices(searchQuery as string, page);
    } else {
      this.getAllCatalogues(page);
    }
  }
}
// Customizable Area End
