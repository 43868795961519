import React from "react";

// Customizable Area Start
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  IconButton,
  Box,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import PhotoLibraryController from "./PhotoLibraryController";
import { gridBtnHide, listBtnShow, editIcon, closeIcon } from "./assets";
import PhotoUpload from "./PhotoUpload.web";
import GalleryView from "./GalleryView.web";
import Banner from "./Banner.web";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
export const configJSON = require("./config");


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomTableRow = withStyles(() => ({
  root: {
    borderBottom: "4px solid #ECEDF0",
    "&:last-child": {
      borderBottom: "none",
    },
    margin: "10px 0",
  },
  hover: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
}))(TableRow);

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "transparent",
  },
}))(TableCell);
// Customizable Area End



// Customizable Area Start
// Customizable Area End

export default class GalleryLists extends PhotoLibraryController {
  render() {
    // Customizable Area Start
    const data = this.state.imageData
    const { loading, selectedRow, showSuccessMessage } = this.state

    if (data.length === 0 && !loading) {
      return <PhotoUpload navigation={undefined} identifier={""} />
    }
    return (
      <>
        {!this.state.showEditPage ? (
          <>
            <Banner navigation={undefined} identifier={""} success={showSuccessMessage} />
            <ThemeProvider theme={theme}>
              <Box style={webStyles.mainContainer}>
                <Container maxWidth={"xl"}>
                  <Box style={webStyles.subContainerOne}>
                    <Box style={{
                      display: 'flex', justifyContent: "space-between"
                    }}>
                      <Typography variant="h4" style={webStyles.galleryText}>
                        Gallery
                      </Typography>
                      {selectedRow.length > 0 && (
                        <Typography variant="h4"
                          data-test-id={`checkbox`}
                          style={webStyles.deleteButton}
                          onClick={() => this.handleDeleteClick(selectedRow)}
                        >
                          Delete Image
                        </Typography>
                      )}
                    </Box>
                    <Button style={{ ...webStyles.uploadButton, textTransform: "none" }} href="/admin/Gallery/ImageUpload" >Add image</Button>
                  </Box>
                  <Box style={webStyles.subContainerTwo}>
                    <Button style={{ ...webStyles.gridFilterBtn, textTransform: "none" }} variant="outlined" href="/admin/Gallery/Grid" startIcon={<img style={webStyles.headerIcon} src={gridBtnHide} alt="gridBtn" />}>Grid View</Button>
                    <Button style={{ ...webStyles.listFilterBtn, textTransform: "none" }} variant="outlined" startIcon={<img style={webStyles.headerIcon} src={listBtnShow} alt="listBtn" />}>List View</Button>
                  </Box>
                  <TableContainer
                    component={Paper}
                    style={{ overflow: "hidden", boxShadow: "none", border: "none" }}
                  >
                    <Table aria-label="galleryList table">
                      <TableHead style={webStyles.tableHeader}>
                        <TableRow>
                          <CustomTableCell style={{ ...webStyles.cellHeading, width: "10%" }} >
                            <Checkbox
                              data-test-id="select-all-images"
                              style={{
                                ...webStyles.checkbox,
                                color: selectedRow.length > 0 && this.state.selectedRow.length === this.state.imageData.length ? "#6200EA" : "#D0D2DA",
                              }}
                              checked={selectedRow.length > 0 && this.state.selectedRow.length === this.state.imageData.length}
                              onChange={this.handleSelectAllFields}
                            />
                          </CustomTableCell>
                          <CustomTableCell style={{ ...webStyles.cellHeading, width: "18%" }} >
                            Images
                          </CustomTableCell>
                          <CustomTableCell style={webStyles.cellHeading}>
                            Uploaded On
                          </CustomTableCell>
                          <CustomTableCell style={webStyles.cellHeading} align="right">
                            Edit
                          </CustomTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={webStyles.tableBodyDesign}>
                        {data.length > 0 ? (
                          <>
                            {data.map((item: any, index: number) => (
                              <CustomTableRow key={item.id} style={webStyles.tableRowDesign}>
                                <TableCell style={webStyles.cellContent}>
                                  <Checkbox
                                    data-test-id={`checkbox-${index}`}
                                    style={{
                                      ...webStyles.checkbox,
                                      color: selectedRow.includes(item.id) ? "#6200EA" : "#D0D2DA"
                                    }}
                                    checked={selectedRow.includes(item.id)}
                                    onClick={() => this.showDeleteButton(item.id)} />
                                </TableCell>
                                <TableCell style={webStyles.cellContent}>
                                  <img src={item.file_url} alt={item.name} width="36" height="36" />
                                </TableCell>
                                <TableCell style={webStyles.cellContent}>
                                  {this.formatDate(item.created_at || '')}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    data-test-id={`editIcon-${index}`}
                                    onClick={() => this.showGalleryImage(item)}
                                  >
                                    <img style={webStyles.editIcon} src={editIcon} alt="listBtn" />
                                  </IconButton>
                                </TableCell>
                              </CustomTableRow>
                            ))}
                            {this.state.openDialog && (
                              <Box
                                style={{
                                  ...webStyles.dialogContainer, position: 'fixed',
                                  flexDirection: 'column'
                                }}
                              >
                                <Box style={{ margin: "40px" }}>
                                  <Box
                                    style={webStyles.dialogTitle}
                                  >
                                    <Typography
                                      variant="h6"
                                      style={webStyles.dialogTitleText}
                                    >
                                      Are you sure?
                                    </Typography>
                                    <IconButton
                                      data-test-id="close-dialog"
                                      edge="end"
                                      color="inherit"
                                      onClick={this.handleCloseDialog}
                                      aria-label="close"
                                      style={webStyles.closeBtn}
                                    >
                                      <img style={webStyles.closeIcon} src={closeIcon} alt="close" />
                                    </IconButton>
                                  </Box>
                                  <Typography
                                    style={webStyles.content}
                                  >
                                    Are you sure you want to delete this gallery image ?
                                  </Typography>
                                </Box>
                                <Box style={webStyles.dialogButton} >
                                  <Button
                                    data-test-id="cancel-btn"
                                    style={{ ...webStyles.cancelButton, textTransform: 'none' }}
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    data-test-id="delete-btn"
                                    style={{ ...webStyles.deleteImageButton, textTransform: 'none' }}
                                    onClick={this.handleConfirmDelete}
                                  >
                                    Yes, delete
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          </>
                        ) : (
                          <CustomTableRow>
                            {loading && <Loader loading={loading} />}
                          </CustomTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {!!this.state.pagination?.total_count && <Box marginBottom={4}><Pagination
                    count={this.state.pagination?.total_count}
                    onChange={this.handlePageChange}
                    pageSize={this.state.pagination?.per_page}
                    itemText={configJSON.paginationText}
                    pageNo={this.state.pagination?.current_page || 1}
                  /></Box>}
                </Container>
              </Box>
            </ThemeProvider>
          </>) : (
          <GalleryView
            identifier={""}
            navigation={undefined}
            selectedImage={this.state.selectedImage}
            selectedImageId={this.state.selectedImageId}
            onBackButtonClick={() => this.showGalleryImage(null)}
          />
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  mainContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F8F9FA",
    margin: "40px 0px"
  },
  subContainerOne: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: "auto"
  },
  subContainerTwo: {
    display: 'flex',
    justifyContent: 'end'
  },
  galleryText: {
    margin: '15px 0px',
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
  },
  uploadButton: {
    backgroundColor: "#00D659",
    margin: "15px 0px",
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    width: "126px",
    height: "40px",
  },
  gridFilterBtn: {
    backgroundColor: "#fff",
    border: "1px solid #D0D2DA",
    color: "#83889E",
    margin: "15px 0px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 600,
  },
  listFilterBtn: {
    backgroundColor: "#fff",
    border: "1px solid #D0D2DA",
    color: "#6200EA",
    margin: "15px 0px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 600,
  },
  tableBodyDesign: {
    border: "4px solid #ECEDF0",
  },
  tableRowDesign: {
    border: "4px solid #ECEDF0",
  },
  tableHeading: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    paddingBottom: "50px",
  },
  tableHeader: {
    backgroundColor: "#F8F9FA",
    border: "3px solid #F8F9FA",
  },
  cellHeading: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
  },
  cellContent: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#3C3E49",
  },
  cellMessage: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    color: "#3C3E49",
  },
  checkbox: {
    width: "24px",
    height: "24px",
    borderRadius: "2px"
  },
  deleteButton: {
    color: "#FF1744",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    textDecoration: "underline solid #FF1744",
    marginTop: "30px",
    cursor: 'pointer',
    marginLeft: "10px"
  },
  headerIcon: {
    width: "16px",
    height: "16px"
  },
  editIcon: {
    width: "18px",
    height: "18px"
  },
  dialogContainer: {
    width: '684px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '24px'
  },
  dialogTitleText: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    color: "#3C3E49",
  },
  closeBtn: {
    margin: "0px",
    padding: "0px",
  },
  deleteImageButton: {
    padding: "20px 48px 20px 48px",
    borderRadius: "0px",
    background: '#FF1744',
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FFFFFF"
  },
  cancelButton: {
    padding: "20px 48px 20px 48px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3C3E49",
    textDecoration: "underline"
  },
  content: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    color: "#3C3E49",
    marginBottom: "24px"
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
  dialogButton: {
    borderTop: " 1px solid #E8E8E8",
    padding: "0px",
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end"
  }
};
// Customizable Area End

