// Customizable Area Start
import React from "react";
import DashboardAdminController, {
  Props,
} from "./DashboardAdminController.web";
import DashboardAdmin from "./DashboardAdmin.web";
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export default class DashboardAdminWrapper extends DashboardAdminController {
  constructor(props: Props) {
    super(props);
    mixpanel.track("webadmin_branding_page_enter");
  }
  render() {
    return (
      <DashboardAdmin brand={this.state.brand} isSaved={this.state.isSaved} upsertBrand={this.upsertBrand} />
    );
  }
}
// Customizable Area End
