// Customizable Area Start
import React from "react";
import CatalogueController, { Props } from "./CatalogueController.web";
import Catalogue from "./Catalogue.web";
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export default class ServiceWrapper extends CatalogueController {
  constructor(props: Props) {
    super(props);
    mixpanel.track("webcustomer_service_show_details");
  }
  render() {
    return (
      <Catalogue
        currentCatalogue={this.state.service}
        selectedImageIndex={this.state.selectedImageIndex}
        previewImage={this.previewImage}
      />
    );
  }
}
// Customizable Area End
