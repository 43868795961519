// Customizable Area Start
import React, { useRef, useState } from "react";
import {
  Typography, TextField, Button, Container, Slide, Box, Card, CardMedia, Grid, CircularProgress
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useStyles } from "../styles/Service.web";
import { add_plus, builder, arrow } from "../assets";

interface FormValues {
  category_name: string;
  category_img: File | null;
}

const validationSchema = yup.object().shape({
  category_name: yup.string().required('Category name is required'),
  category_img: yup
    .mixed()
    .required('Image is reqiured')
    .test('fileValidation', 'Image size should not be grater than 20 MB', (value) => {
      if (value && value instanceof File) {
        const validFormats = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];
        const excludedFormats = ['image/x-icon']; 
        const isValidFormat = validFormats.includes(value.type);
        const isExcludedFormat = excludedFormats.includes(value.type);
    
        if (isExcludedFormat) {
          return false; 
        }

        const maxSizeBytes = 20 * 1024 * 1024;

        const isValidSize = value.size <= maxSizeBytes;

        return isValidFormat && isValidSize;
      }
      return false;
    }),
});

interface Props {
  back: () => void;
  addimages: (values: FormValues) => void;
  isLoading: any;
}


const initialValues = {
  category_name: "",
  category_img: null
};

const Addcategoryform: React.FC<Props> = ({ back, addimages, isLoading }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const resetButtonRef = useRef<HTMLButtonElement>(null);
  const [isError, setIsError] = useState("Image is required");
  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: values => {
      addimages(values);
    },
    validationSchema,
  });


  const inputRef = useRef<HTMLInputElement>(null);


  const handleImageChangis = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
  
      const maxSizeBytes = 20 * 1024 * 1024;
      const allowedFormats = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      
      if (file.size > maxSizeBytes) {
        formik.setFieldValue("category_img", file);
        formik.setFieldTouched("category_img", true);
        formik.setFieldError("category_img", "Image size should not be greater than 20MB");
        setIsError("Image size should not be greater than 20MB")
        setImagePreview(null);
      } else if (fileExtension === 'ico') {
       
        formik.setFieldValue("category_img", null);
        formik.setFieldTouched("category_img", true);
        formik.setFieldError("category_img", ".ico format images are not allowed");
        setIsError(".ico format images are not allowed")
        setImagePreview(null);
      } else if (allowedFormats.includes(file.type)) {
        setIsError("Image is required")
        formik.setFieldValue("category_img", file);
        formik.setFieldTouched("category_img", true);
        formik.setFieldError("category_img", undefined);
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        formik.setFieldValue("category_img", null);
        formik.setFieldTouched("category_img", true);
        formik.setFieldError("category_img", "Invalid image format");
        setIsError("Image should be a JPEG, PNG, GIF, or JPG type")
        setImagePreview(null);
      }
    } else {
      setIsError("Image is required")
      formik.setFieldValue("category_img", null);
      formik.setFieldTouched("category_img", true);
      formik.setFieldError("category_img", undefined);
      setImagePreview(null);
    }
  };
  
  




  const openImageInputs = () => {

    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const classes = useStyles({ hasImages: formik.values.category_img !== null });
  const isFormChanged =
    JSON.stringify(formik.values) !== JSON.stringify(initialValues);

  const saveChanges = () => {

    submitButtonRef.current && submitButtonRef.current.click();
  };

  const discardChanges = () => {
    resetButtonRef.current && resetButtonRef.current.click();
  };

  const { resetForm } = formik;

  const removeImage = () => {
    formik.setFieldValue("category_img", null);
    setImagePreview(null);
  };

  const setUI = () => {
    if (isLoading) {
      return (
        <Card className={classes.noServicesCard}>
        <Box className={classes.loaderContainer}>
          <Typography className={classes.loadingResultsText}>
            Loading results
          </Typography>
          <span>
          <CircularProgress size={24} />
          </span>
        </Box>
      </Card>
      )
    } else {
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={webStyle.Titlesection}>
                <img src={arrow} alt="arrow" style={webStyle.arrowStyleimg} />
                <Typography style={webStyle.backTitleText} onClick={back}>
                  Back
                </Typography>
              </div>
              <div className="col">
                <Typography className={classes.AddText}>
                  Add a category
                </Typography>
              </div>
            </Grid>
          </Grid>
          <div style={webStyle.formMainStyle}  >
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                <div style={{ padding: "40px" }}>
                  <Typography className={classes.formTitle}>
                    Category details
                  </Typography>
                  <div style={{ marginTop: "20px" }}>
                    <Typography className={classes.formFirstText}>
                      Category name *
                    </Typography>

                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 1 }}
                      placeholder="Category name"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      {...formik.getFieldProps("category_name")}
                      error={formik.touched.category_name && formik.errors.category_name ? true : false}
                      helperText={formik.touched.category_name && formik.errors.category_name && (
                        <span className={classes.formErrorText}>{formik.errors.category_name}</span>
                      )}
                    />
                  </div>
                  <div style={webStyle.imageParentStyle} >
                    <div>
                      <Typography className={classes.formSecondText}>
                        Image *
                      </Typography>
                      <div
                        id="outlined-full-width"
                        style={{
                          width: "100%",
                          height: "100%",
                          margin: "2px",
                          border: imagePreview ? "none" : "2px dotted #C0C3CE",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: imagePreview ? "flex-start" : "center",
                          justifyContent: "center",
                          borderRadius: imagePreview ? "none" : "10px",
                          position: "relative",
                          overflow: imagePreview ? "visible" : "hidden",
                        }}
                      >
                        {imagePreview ? (
                          <>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <img
                                src={imagePreview}
                                alt="Category Preview"
                                style={{
                                  width: "155px",
                                  height: "158px",
                                  borderRadius: "4px"
                                }}
                              />

                            </div>
                            <div style={webStyle.removeText}
                              onClick={removeImage}
                            >
                              Remove
                            </div>
                          </>
                        ) : (
                          <div style={webStyle.imgsectioninner} onClick={openImageInputs} >
                            <img
                              src={add_plus}
                              style={webStyle.plusimgStyle}
                              alt="Add Image"
                            />
                            <input
                              type="file"
                              name="category_img"
                              ref={inputRef}
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={handleImageChangis}
                            />

                            <Typography className={classes.AddImagetitle}>
                              Add Image
                            </Typography>
                            <Typography className={classes.mbText}>Max 20MB</Typography>
                          </div>
                        )}

                      </div>
 
                      {imagePreview == null &&formik.touched.category_img && formik.errors.category_img && (
                        <div className={classes.formErrorText}>
                          {isError}
                        </div>
                      )}
                    </div>
                  </div>
                  <Button
                    ref={resetButtonRef}
                    className={classes.hidden}
                    onClick={() => resetForm()}
                  />
                  <Button
                    ref={submitButtonRef}
                    type="submit"
                    data-test-id="submitClick"
                    className={classes.hidden}
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      )
    }


  }

  const sliding = () => {
    return (
      <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={builder}
              className={classes.logo}
            />

            <Box>
              <Button
                variant="text"
                className={classes.discardChangesbutton}
                onClick={discardChanges}
              >
                Discard changes
              </Button>
              <Button
                variant="contained"
                className={classes.saveChangesbutton}
                onClick={saveChanges}
              >
                Save changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    )
  }

  return (
    <>
      {sliding()}
      <Container maxWidth="lg" style={webStyle.containerMain} >
        {setUI()}

      </Container>
    </>
  );
};

export default Addcategoryform;


const webStyle = {

  containerMain: {
    backgroundColor: '#FAFAFA',
    marginBottom: '50px'
  },
  Titlesection: {
    width: '100%',
    display: "flex",
    alignItems: "center" as "center",
    cursor: "pointer",
    marginTop: '50px'
  },
  backTitleText: {
    marginLeft: "4px",
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left' as 'left',
    color: '#676B7E',
  },
  formMainStyle: {
    border: "2px solid #ECEDF0",
    margin: "50 auto",
    width: "684px",
    height: "477px",
    top: "209px",
    left: "496px",
    borderRadius: "3px",
    backgroundColor: '#FFFFFF'
  },
  arrowStyleimg: {
    width: '12px',
    height: '12px'
  },
  imageParentStyle: {
    marginTop: "20px",
    height: "177px",
    width: "604px"
  },
  imgsectioninner: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  plusimgStyle: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
  },
  removeText: {
    left: "10px",
    cursor: "pointer",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left" as "left",
    color: "#3C3E49",
    textDecoration: "underline",
  }



};
// Customizable Area End



