Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.validateAvailabilityEndPoint = 'bx_block_appointment_management/availabilities/validate_availabilities'

exports.upsertCatalogueEndPoint = 'bx_block_catalogue/catalogues'
exports.getCataloguesEndPoint = 'bx_block_catalogue/catalogues/all_catalogues'
exports.getCatalogueEndPoint = 'bx_block_catalogue/catalogues'
exports.getCategoriesEndPoint = 'bx_block_categories/categories'
exports.searchCataloguesEndPoint = 'bx_block_catalogue/catalogues/search'
exports.deleteCataloguesEndPoint = 'bx_block_catalogue/catalogues/destroy'

exports.postMethod = 'POST'
exports.patchMethod = 'PATCH'
exports.getMethod = 'GET'
exports.deleteMethod = 'DELETE'

exports.paginationText = "services"

// Customizable Area End
