// Customizable Area Start
import React from "react";
import OrderList from "./OrderList.web";

import OrderManagementController, {
    Props
} from "./OrderManagementDetailsController";
const configJSON = require('./config.js')



export default class OrderManagementList extends OrderManagementController {
    constructor(props: Props) {
        super(props);

    }


    render() {
        return (
            // Customizable Area Start
             <>
            
              <OrderList
                filters={this.state.filters}
               orderList_Data={this.state.orderList_Data}
               handleDialogOpen={this.handleDialogOpen}
               handleDialogClose={this.handleDialogClose}
               searchQuery={this.state.searchQuery}
               isDialogOpen={this.state.isDialogOpen}
               handleSubmit={this.handleSubmitList}
               appointment_summary={this.appointment_summary}
               getList={this.getList}
               download={this.download}
               serach_orders_list={this.serach_orders_list}
               isLoading={this.state.isLoading}
               isOrderBlank={this.state.isOrderBlank}
               dateError={this.state.dateError}
               totalError={this.state.totalError}
               resetFilter={this.resetFilter}
               isCsvDownloading={this.state.isCsvDownloading}
               pagination={this.state.pagination}
               handlePageChange={this.handlePageChange}
              />
             </>
       
            // Customizable Area End
        );
    }
}



// Customizable Area End

