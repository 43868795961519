import React from "react";
// Customizable Area Start
import CcavenueIntegrationController, { Props } from "./CcavenueIntegrationController";
import { Box, Fade, Modal } from "@material-ui/core";
const useStyles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#fff",
        width: "60%"
    }
};
// Customizable Area End

export default class CcavenueIntegration extends CcavenueIntegrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <Modal open={this.props.ccavenueModalOpen} onClose={this.handleCloseModal} style={useStyles?.modal}>
                <Fade in={this.props.ccavenueModalOpen}>
                    <Box style={useStyles?.paper}>
                    </Box>
                </Fade>
            </Modal>
        );
        // Customizable Area End
    }
}