// Customizable Area Start
import React from "react";
import CataloguesController, { Props } from "./CataloguesController.web";
import Catalogues from "./Catalogues.web";
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export default class ServicesWrapper extends CataloguesController {
  constructor(props: Props) {
    super(props);
    mixpanel.track("webcustomer_services_listing");
  }

  render() {
    return (
      <Catalogues
        filterOptions={this.state.categories}
        cataloguesData={this.state.services}
        tempSelectedCategories={this.state.tempSelectedCategories}
        selectCategory={this.selectCategory}
        resetCategoriesSelected={this.resetCategoriesSelected}
        applyCategoriesSelected={this.applyCategoriesSelected}
        discardCategoriesSelected={this.discardCategoriesSelected}
        isLoading={this.state.loading}
        searchQuery={this.state.searchQuery}
        deleteChip={this.deleteChip} 
        handlePageChange={this.handlePageChange}  
        pagination={this.state.pagination}
      />
    );
  }
}
// Customizable Area End
