import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  CardMedia,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import PhotoLibraryController, {
  Props
} from "./PhotoLibraryController";
import { createTheme } from "@material-ui/core/styles";
import { builderStudioStore, addIcon, arrowIconBtn } from './assets'
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  }
});

const webStyles = {
  formActionsWrapper: {
    width: "100%",
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3),
  },
  logo: {
    width: "auto",
  },
  discardChangesbutton: {
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    padding: theme.spacing(1.5, 3),
    color: "#FFFFFF",
  },
  saveChangesbutton: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: theme.spacing(1.5, 5),
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  container: {
    padding: "40px"
  },
  link: {
    textDecoration: "none",
  },
  backButton: {
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#676B7E",
    padding: "6px 0px",
  },
  title: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "0.56px",
    marginBottom: theme.spacing(5),
  },
  leftSideWrapper: {
    display: "flex",
    flex: "1 1 70%",
    gap: theme.spacing(3),
  },
  card: {
    width: "600px",
    height: " 552px",
    padding: theme.spacing(5),
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF",
    margin: "auto"
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "0.4px",
    color: "#3C3E49",
    fontFamily: "Rubik"
  },
  cardSubTitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.4px",
    color: "#83889E",
    marginBottom: "10px",
    fontFamily: "Rubik"
  },
  inputsWrapper: {
    display: "flex",
    gap: theme.spacing(3),
  },
  inputlabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  descriptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionCounter: {
    fontSize: "12px",
    lineHeight: "20px",
  },
  imagesWrapper: {
    display: "flex",
    gap: theme.spacing(3),
  },
  previewImage: {
    width: "155px",
    height: "158px",
  },
  removeButton: {
    fontFamily: "Rubik",
    fontWeight: 400,
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
    textTransform: "none" as "none"
  },
  imageUpload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "163px",
    width: "163px",
    borderRadius: "10px",
    border: "2px dashed #C0C3CE",
    cursor: "pointer",
  },
  addIcon: {
    color: "#676B7E",
    width: "16px",
    height: "15px"
  },
  addImageText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
    color: "#676B7E",
  },
  fileSize: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#AFB2C0",
  },
  fieldWrapper: {
    display: "flex",
    gap: theme.spacing(3.5),
  },
  field: {
    flex: "0 1 40%",
  },
  switch: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  arrowIconBtn: {
    width: "12px",
    height: "12px"
  },
  imageValidationError: {
    color: "#f44336",
    margin: "0px",
    fontSize: "0.75rem",
    lineHeight: "1.66",
  },
}
// Customizable Area End

export default class ImageUpload extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isImageUploading } = this.state;
    const { selectedImage } = this.state;
    return (
      <>

        <Slide
          direction="down"
          in={selectedImage.uri !== ""}
          mountOnEnter
          unmountOnExit
          style={{left: 0, right: 0}}
        >
          <Box
            style={{
              ...webStyles.formActionsWrapper,
              zIndex: 1,
              position: "fixed",
              top: 0
            }}
          >
            <Box style={webStyles.formActions}>
              <CardMedia
                component="img"
                src={builderStudioStore}
                style={{ ...webStyles.logo, objectFit: "none" }}
              />

              <Box>
                <Button
                  variant="text"
                  data-test-id="discard"
                  style={{
                    ...webStyles.discardChangesbutton,
                    textTransform: "none",
                  }}
                  onClick={this.discardImage}
                >
                  Discard changes
                </Button>
                {selectedImage.uri && (
                  <Button
                    variant="contained"
                    data-testid="upload-button"
                    style={{
                      ...webStyles.saveChangesbutton,
                      textTransform: "none",
                    }}
                    onClick={this.uploadImage}
                    disabled={this.state.isSaving}
                  >
                    {this.state.isSaving && <CircularProgress size={15} style={{ marginRight: 10 }} />}
                    Save changes
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Slide>
        <Box style={webStyles.container}>
          <Button
            data-test-id="back-btn"
            variant="text"
            startIcon={<img style={webStyles.arrowIconBtn} src={arrowIconBtn} alt="arrowIconBtn" />}
            style={{ ...webStyles.backButton, textTransform: "none" }}
            onClick={this.btnBackProps}
          >
            <Typography>Back</Typography>
          </Button>
          <Typography style={webStyles.title}>Gallery image</Typography>
          <Box style={webStyles.card}>
            <Typography style={webStyles.cardTitle}>
              Image details
            </Typography>
            <Typography style={webStyles.cardSubTitle}>
              This image will be part of the Gallery
            </Typography>
            <Box style={webStyles.imagesWrapper}>
              {selectedImage?.uri ? (
                <Box>
                  <CardMedia
                    component="img"
                    src={selectedImage.uri}
                    style={webStyles.previewImage}
                  />
                  <Button
                    data-test-id="remove"
                    variant="text"
                    style={webStyles.removeButton}
                    onClick={this.discardImage}
                  >
                    Remove
                  </Button>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  <Box
                    data-test-id="add-image-box"
                    style={{
                      ...webStyles.imageUpload,
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      document.getElementById("image-upload-input")?.click()
                    }
                  >
                    <input
                      id="image-upload-input"
                      style={webStyles.hidden}
                      type="file"
                      accept="image/*"
                      onChange={(event) => this.checkImage(event)}
                      multiple
                    />
                    <img style={webStyles.addIcon} src={addIcon} alt="addIcon" />
                    <Typography style={webStyles.addImageText}>Add image</Typography>
                    <Typography style={webStyles.fileSize}>Max 20MB</Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyles.imageValidationError}>
                      {this.state.errorMessage}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}