// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { RefObject } from "react";
export const configJSON = require("./config");
import {FilterType, OrderType, PaginationType} from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");


export interface Props {
  navigation: {
    navigate: (to: string, params?: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  // Customizable Area Start
  // Customizable Area End
}


interface OrderSummary {
  id: string;
  type: string;
  attributes: {
    order_number: string;
    appointment_date: string;
    order_date: string;
    status: string;
    total: number;
    service: {
      title: string;
      price: number;
      duration: number;
    };
    service_images: {
      id: number;
      url: string;
    };
    payment_mode:string;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      appointment_id: number;
      comment: string | null;
    };
    billing_address: {
      id: number;
      country: string;
      city: string;
      zip_code: string;
      state: string;
      address_line_1: string;
      address_line_2: string;
      flat_number: string | null;
      created_at: string;
      updated_at: string;
      appointment_id: number;
    };
    time_zone_short: string;
  };
}

interface S {
  // Customizable Area Start
  token: string;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  orderList_Data: OrderType[] | null;
  isDialogOpen: boolean;
  searchQuery: string;
  adminSearch?: string;
  selectedValue: string | null;
  orderSummary: OrderSummary | null;
  isLoading:boolean;
  isOrderBlank:boolean;
  isFormChanged:boolean;
  dateError: string | null;
  totalError: string | null;
  filters?: FilterType;
  isCsvDownloading: boolean;
  pagination?: PaginationType;
  // Customizable Area End
}

interface SS {}

// Customizable Area End
export default class OrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  orderCategoryAddApiCallId: string = "";
  orderCategoryGetApiCallId: string = "";
  searchCategoryGetApiCallId: string = "";
  orderStatusGetApiCallId: string = "";
  appointmentGetApiCallId: string = "";
  debounceTimer: NodeJS.Timeout | null = null;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.submitButtonRef = React.createRef();
    this.resetButtonRef = React.createRef();

    this.state = {
      token: "",
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      orderList_Data: null,
      isDialogOpen: false,
      searchQuery: '',
      orderSummary: null,
      selectedValue: null,
      isLoading:false,
      isOrderBlank:false,
      isFormChanged:false,
      totalError: null,
      dateError: null,
      isCsvDownloading: false,
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
// Customizable Area Start  
  downloadCSVMessageId: string = "";
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    if(window.location.pathname.includes("OrderManagementDetails")){
      this.callAPI();
    }else{
      this.getListOrder();
    }
    
   

  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId === this.orderStatusGetApiCallId) {
        this.handleBack();
      }
      
      this.handleDownloadCSV(message);
      if (responseJson.data) {
        if (apiRequestCallId === this.orderCategoryGetApiCallId) {
          this.handleApiResponse(message, true);
        } else if (apiRequestCallId === this.orderCategoryAddApiCallId) {
          this.handleApiResponse(message, false);
        } else if (apiRequestCallId === this.searchCategoryGetApiCallId) {
          this.handleApiResponse(message, false);
        } else if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.updateState(message);
          this.handleBack();
        } else if (apiRequestCallId === this.appointmentGetApiCallId) {
          this.updateState(message);
        }
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ isLoading: false });
      }

    }
  }
  
  handleApiResponse(message: Message, isOrderBlank: boolean) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.setState({ isOrderBlank });
    this.setState({ orderList_Data: responseJson.data, pagination: responseJson.metadata?.meta?.pagination });
    this.setState({ isLoading: false });
  }
  




  filterOrder = (values: FilterType, page = 1) => {
    mixpanel.track("webadmin_order_list_filter");
   this.setState({ isLoading: true, filters: values, adminSearch: "" })
    const header = {};

    let complete = ""
    let cancelled = ""
    let inProgress = ""
    let confirmed = ""
    let refunded = ""
    let placed = ""


    let apiEndPoint = configJSON.orderlistfilterEndPoint + "?page="+page;
    
    const checkboxValue = values.checkboxes

    if (values.from_date != null && values.from_date != undefined) {
      apiEndPoint += "&[start_date]=" + values.from_date;
    }

    if (values.to_date != null && values.to_date != undefined) {
      apiEndPoint += "&[end_date]=" + values.to_date;
    }


    if (checkboxValue.Confirmed) {
      confirmed = "confirmed"

      apiEndPoint += "&[status][]=" + confirmed;
    }

    if (checkboxValue.Completed) {
      complete = "completed"
      apiEndPoint += "&[status][]=" + complete;
    }


    if (checkboxValue.InProgress) {
      inProgress = "in_progress"
      apiEndPoint += "&[status][]=" + inProgress;
    }
    
    if (checkboxValue.Cancelled) {
      cancelled = "cancelled"
      apiEndPoint += "&[status][]=" + cancelled;
    }

    if (checkboxValue.Refunded) {
      refunded = "refunded"
      apiEndPoint += "&[status][]=" + refunded;
    }

    if (checkboxValue.placed) {
      placed = "placed"
      apiEndPoint += "&[status][]=" + placed;
    }

    if (values.integerField1 != null && values.integerField1 != undefined) {
      apiEndPoint += "&[total][from]=" + values.integerField1;
    }

    if (values.integerField2 != null && values.integerField2 != undefined) {
      apiEndPoint += "&[total][to]=" + values.integerField2;
    }

    this.orderCategoryAddApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });
  };


  serach_orders = (searchtxt: string) => {
    const header = {};
    const apiEndPoint = configJSON.ordercategoryApiEndPoint + `?search_query=${searchtxt}`;
    this.searchCategoryGetApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });

  }


  serach_orders_list = (queryRef: RefObject<HTMLInputElement>) => {
   
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      const query = (queryRef.current && queryRef.current.value) || "";
  
      this.adminSearch(query);
    }, 800);


  }

  adminSearch = (search: string, page = 1) => {
    this.setState({isLoading:true, filters: undefined, adminSearch: search})
    if(search.toString().length>0)
    {
      mixpanel.track("webadmin_order_list_search");
      const header = {};
      const apiEndPoint = configJSON.ordercategoryApiEndPoint + `?search_query=${search}&page=${page}`;
      this.searchCategoryGetApiCallId = BlockHelpers.callApi({
        method: configJSON.listOfOrdersMethod,
        endPoint: apiEndPoint,
        header,
        body: null
      });
    }else if(search.toString().length==0)
    {
      this.getListOrder()
    }
      clearTimeout(this.debounceTimer!);
  }

  getListOrder = (page = 1) => {
    mixpanel.track("webadmin_order_list_enter");
    this.setState({isLoading:true, filters: undefined, adminSearch: ""})
    const header = {};
    this.orderCategoryGetApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.orderlistEndPoint + "?page=" + page,
      header
    });
  };

   getList=async (itemId: string)=>{
    await setStorageData("orderID", itemId.toString());
    this.props.navigation.navigate("OrderMangementList/OrderManagementDetails")
  
}


  orderStatus = (itemId: string | null,status: {selectedValue: string | null }) => {
 this.setState({isLoading:true})
    const header = {};
    const body = new FormData();
    body.append("[appointment][status]", status.selectedValue+"");
    mixpanel.track("webadmin_order_detail_update");
    
    this.orderStatusGetApiCallId = BlockHelpers.callApi({
      method: configJSON.createOrderMethod,
      endPoint: configJSON.orderstatusApiEndPoint + `?id=${itemId}`,
      header,
      body
    });
  };

 


  appointment_summary = (itemId:string | null) => {
    mixpanel.track("webadmin_order_detail_enter", { itemId });
    const header = {};
    const apiEndPoint = configJSON.routeAppointmentSummary + `?id=${itemId}`;
    this.appointmentGetApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });

  }


  handleBack = () => {
    this.props.navigation.navigate("OrderMangementList")
  };

  handleDownloadCSV = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.downloadCSVMessageId === apiRequestCallId){
      this.setState({ isCsvDownloading: false });
      if(responseJson.csv_data){
        const csvRows = responseJson.csv_data;
        let csvContent = "data:text/csv;charset=utf-8,";

        csvRows.forEach(function (rowArray: string[]) {
          let rowData = rowArray.join(",");
          csvContent += rowData + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", configJSON.textCSVFileName);
        document.body.appendChild(link);
        link.click();
      }
    }
  }

  download = () =>{
    const header = {};
    const apiEndPoint = configJSON.routeDownloadCSV;
    this.downloadCSVMessageId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });
    this.setState({ isCsvDownloading: true });
  }


  handleStatusChange = (newValue:string | null) =>{
    this.setState({ selectedValue: newValue });
  }

  updateState = (message: Message) =>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const response = responseJson.data;
    this.setState({orderSummary:response});
    this.setState({ selectedValue: response.attributes.status });
    this.setState({ isLoading: false });
  } 

  resetFilter = () => {
    this.getListOrder();
    this.setState({ isDialogOpen: false, dateError: null, totalError: null, filters: undefined, adminSearch: "" });
  }


  submitButtonRef: RefObject<HTMLButtonElement>;
  resetButtonRef: RefObject<HTMLButtonElement>;

  callAPI = async () => {
    const itemId = await getStorageData("orderID")
        this.appointment_summary(itemId)
    }

    formatDate = (inputDate: string): string => {
        const parsedDaties = new Date(inputDate);
    
        if (isNaN(parsedDaties.getTime())) {
            return "Invalid Date";
        }
    
        const optiones: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
        };
    
        const formatter = new Intl.DateTimeFormat('en-US', optiones);
        let formattedDate = formatter.format(parsedDaties);
    
        formattedDate = formattedDate.replace(/, (\d{4})/, ' $1,');
        formattedDate = formattedDate.replace(" at", "");
        formattedDate = formattedDate.replace(/(\d{1,2}) /, '$1 ');
    
        return formattedDate;
    };
    


    handleChange = (event: string, newValue: string | null) => {
        this.setState({ selectedValue: newValue });
    };

    handleSubmitDetail = async (values: {selectedValue: string | null}) => {
      const itemId = await getStorageData("orderID");
        this.orderStatus(itemId, values)
    };

    saveChanges = () => {
        if (this.submitButtonRef.current) {
            this.submitButtonRef.current.click();
            this.setState({ isFormChanged: false })

        }
    };

    discardChanges = () => {
        if (this.resetButtonRef.current) {
            this.resetButtonRef.current.click();
        }
    };


    handleDialogOpen = () => {
      this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
      this.setState({ isDialogOpen: false, dateError: null, totalError: null });
  };


  handleSubmitList = (values: FilterType) => {
      let isError = false;
      const {from_date, to_date, integerField1, integerField2} = values;
      if((from_date && !to_date) || (to_date && !from_date)){
          isError = true;
          this.setState({ dateError: configJSON.textSelectDateValidation });
      }else{
          this.setState({ dateError: null })
      }
      if((integerField1 && !integerField2) || (integerField2 && !integerField1)){
          isError = true;
          this.setState({ totalError: configJSON.textSelectAmountValidation })
      }else{
          this.setState({ totalError: null })
      }
      if(isError){
          return;
      }
      this.filterOrder(values);
      this.handleDialogClose();
      
  };


  fetchSearchResults = () => {
      const { searchQuery } = this.state;

      if (searchQuery !== null && searchQuery !== '') {
          if (searchQuery.length > 0) {
              this.serach_orders(searchQuery)
          }

      }
  };

  handlePageChange = (page: number) => {
    if(this.state.filters){
      this.filterOrder(this.state.filters, page);
    }else if(this.state.adminSearch) {
      this.adminSearch(this.state.adminSearch, page)
    }else{
      this.getListOrder(page);
    }
  }

  handleOrderDate = (order_date: string) =>{
    return moment.utc(order_date).format("LLL");
  };

  handleAppointmentDate = (appointment_date: string, time_zone_short: string) => {
    return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A (["+time_zone_short+"])");
  };

  handleOrderStatus = (newValue: string | string[] | null) =>  {
    this.setState({ isFormChanged: true });
    if (newValue === null) {
        this.handleChange("selectedValue","");
    } else if (Array.isArray(newValue)) {
        this.handleChange("selectedValue",newValue[0]);
    } else {
        this.handleChange('selectedValue', newValue);
    }
  };
  // Customizable Area End
}
