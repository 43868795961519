// Customizable Area Start
import * as React from "react";
import AdminHomeController from "./AdminHomeController.web";
import {
  withStyles,
  createStyles,
} from "@material-ui/core";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Scrollbars } from "react-custom-scrollbars";
import ProgressTracker, { ProgressStep } from "../../ss-cms-common-components/src/ProgressTracker";
import RoundIconBadge from "../../ss-cms-common-components/src/Badge/RoundIconBadge";
import { Redirect } from "react-router-dom";

export const configJSON = require("./config");

export class AdminHome extends AdminHomeController {
  render() {
    const { classes } = this.props;

    if(!window.localStorage.getItem("admintoken")){
      return <Redirect to="EmailAccountLogin" />
    }
    
    return (
      <Scrollbars>
        <div className={classes.page}>

          <div className={classes.header}>
            <div className={classes.welcome}>{configJSON.textWelcome} Admin User</div>
            <div className={classes.hint}>{configJSON.textHint}</div>
          </div>

          <ProgressTracker>
            <ProgressStep status="done" className={classes.steps}>
              <div className={classes.stepTitle}>
                {configJSON.textSettingUpStore}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textAddStoreDetails}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textUpdateCommonSettings}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textCustomBranding}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textAddGallery}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textConfigureEmailTemplates}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textPopulateContent}
              </div>
            </ProgressStep>

            <ProgressStep status="done" className={classes.steps}>
              <div className={classes.stepTitle}>
                {configJSON.textSettingUpServices}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textAddCategories}
              </div>
            </ProgressStep>

            <ProgressStep status="done" className={classes.steps}>
              <div className={classes.stepTitle}>
                {configJSON.textCompleteBusinessSettings}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textAddPaymentIntegration}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textMobileAppSettings}
              </div>

              <div className={classes.done}>
                <div className={classes.doneTitle}>{configJSON.textDone}</div>
                <div className={classes.doneText}>
                  {configJSON.textDoneHint}
                </div>
              </div>
            </ProgressStep>

            <ProgressStep status="done" className={classes.steps}>
              <div className={classes.stepTitle}>
                {configJSON.textSetTo}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textStartBookings}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textSeeCustomers}
              </div>
              <div className={classes.step}>
                <Icon />
                {configJSON.textCustomerFeedback}
              </div>
            </ProgressStep>
          </ProgressTracker>

        </div>
      </Scrollbars>
    );
  }
}

const Icon = () => (
  <RoundIconBadge
    iconName={"tick"}
    color={"green"}
    className="me-3"
    size="md"
  />
)
export const styles = () =>
  createStyles({
    page: {
      padding: "80px 260px 140px 140px",
      minHeight: "80vh",
      display: "flex",
      flexDirection: "column",
      gap: 64
    },
    header: {

    },
    welcome: {
      fontFamily: "Rubik",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3C3E49"
    },
    hint: {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#83889E"
    },

    steps: {
      display: "flex",
      flexDirection: "column",
      gap: 16
    },
    stepTitle: {
      fontFamily: "Rubik",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3C3E49",
      marginTop: -8
    },
    step: {
      padding: "15px 20px",
      display: "flex",
      flexDirection: "row",
      gap: 16,
      fontWeight: 500,
      border: "0.5px solid #83889E",
      alignItems: "center",
      color: "#3C3E49"
    },

    done: {
      padding: "20px 0 0 0"
    },
    doneTitle: {
      fontFamily: "Rubik",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3C3E49"
    },
    doneText: {}
  });

export default withLayout(withStyles(styles)(
  AdminHome
));
// Customizable Area End