// Customizable Area Start
import { FormikProps } from "formik";
import { RefObject, createRef } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { showError, generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config.js");
import { StripPayment as  StripPaymentType, StripInitial} from "./Types";

const mixpanel = require('mixpanel-browser');
mixpanel.init("6f7f089d0eac5c598ff14c4d2f5031d2");

export type Props =  {
  open: boolean;
  setOpen: () => void;
  defaultValues?: StripPaymentType;
  showLoader: () => void;
  hideLoader: () => void;
  setDialogState: () => void;
  showHeader: (obj?: object) => void;
};

interface S {
  payment?: StripPaymentType;
}

interface SS {
}

export default class StripPaymentActivateDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateMessageId: string = "";
  formikRef: RefObject<FormikProps<StripInitial>>;

  constructor(props: Props) {
    super(props);
    this.formikRef = createRef<FormikProps<StripInitial>>();
    this.receive = this.receive.bind(this);
    this.state = {
      payment: this.props.defaultValues
    }
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  handleSubmitDialog = () => {
    this.formikRef.current?.handleSubmit();
    return false;
  };

  handleSubmit = (stripData: StripInitial) => {
    this.updatePayment(stripData);
  };

  receive = (from: string, message: Message) => {
    this.messageUpdate(message);
  }

  messageUpdate = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.updateMessageId === apiRequestCallId) {
      if (responseJson.errors) {
        return this.handleError(
          configJSON.errorCardUpdate,
          responseJson
        );
      }
      this.props.hideLoader();
      this.props.setOpen();
      this.props.showHeader({ type: 'success'});
      this.setState({payment: responseJson.data})
    }
  };

  handleError = (title: string, responseJson: object) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  updatePayment = async (data: object) => {
    mixpanel.track("webadmin_payment_stripe_save_button");
    const requestMessage = await generateRequestMessage(
      `${configJSON.stripAccountStatusPaymentsAdminURL}/${this.props.defaultValues?.id || ''}`,
      this.props.defaultValues?.id ? configJSON.putMethod : configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.updateMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };
  
}
// Customizable Area End