Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";

exports.getBrandEndPoint = "catalogue/brands";
exports.upsertBrandEndPoint = "bx_block_catalogue/brands";
exports.updateBrandEndPoint = "bx_block_catalogue/brands";

exports.postMethod = 'POST'
exports.putMethod = 'PUT'
exports.getMethod = 'GET'

exports.textBannerResizeDialogHeader = "Select banner image sizing"
// Customizable Area End