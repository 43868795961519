Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'bx_block_appointment_management/availabilities'

exports.addappointmentAPiEndPoint = 'bx_block_appointment_management/appointments'
exports.appointmentValidationAPiEndPoint = 'bx_block_appointment_management/appointments/validate_appointment'

exports.catalogueAPIEndPoint = 'bx_block_catalogue/catalogues'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.stripeAPiMethod = 'POST'
exports.postStripePaymentApiEndPoints = 'bx_block_stripe_integration/payments'
exports.dataEncryptionEndPoint = 'bx_block_ccavenue_integration/encrypts_and_decrypts/check_out_encrypt_data'
exports.bookAppointment = "Book appointment"
exports.dateAndTime = "Date & time"
exports.availableTimes = "Available times"
exports.in = "in"
exports.noSlotsAvailable = "No slots available"
exports.appointmentSummary = "Appointment summary"
exports.service = "Service"
exports.mins = "mins"
exports.total = "Total"
exports.proceed = "Proceed"
exports.cancelBackToAllServices = "Cancel, back to all services"
exports.personalDetails = "Personal details"
exports.name = "Name *"
exports.email = "Email *"
exports.mobile = "Mobile *"
exports.country = "Country *"
exports.address = "Address"
exports.addressLine1 = "Address line 1 *"
exports.addressLine2 = "Address line 2"
exports.city = "City *"
exports.state = "State *"
exports.zipCode = "Zip code *"
exports.comments = "Comments"
exports.totalWordLimit = "/100"
exports.paymentLocation = "Payment location"
exports.flatHouseApartment = "Flat / House / Apartment No."
exports.areYouSure = "Are you sure you want to place the order?"
exports.no = "No"
exports.yes = "Yes"
exports.alert = "Alert"
exports.close = "Close"
exports.thankyou = "THANK YOU,"
exports.yourBookingIsConfirmed = "Your booking is confirmed. We have sent a confirmation email to "
exports.orderId = "Order ID: "
exports.orderDate = "Order Date : "
exports.appointmentDateAndTime = "Appointment Date & Time"
exports.modeOfPayment = "Mode of Payment"
// Customizable Area End
